@charset "UTF-8";
/*
 * style.css
*/
/*
    File Name   : var.scss
    Description : 変数用
*/
/*------------------------------------------------------*/
/* base */
/*
    File Name   : base.scss
    Description : 各ブロックのレイアウト
*/

[class*="btn"][disabled] {
  background: #b4b4b4 !important;
  color: white !important;
  border: none !important;
  font-weight: normal !important;
  cursor: not-allowed !important;
}

/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/* modal error */
#pd_error-modal .pd_modal-header {
  min-height: 50px;
}

#pd_error-modal .pd_modal-content {
  max-width: 640px;
}

#pd_error-modal .pd_modal-body {
  padding: 0 30px 30px;
}

/* balloon */
.balloon {
  position: absolute;
  top: -3.5em;
  width: 14em;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  padding: 5px 10px;
  background: white;
  left: -6em;
  display: none;
}

.balloon:before {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #fff;
  z-index: 2;
}

.balloon:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #ccc;
  z-index: 1;
}

@media screen and (max-width: 640px) {
  .balloon:before {
    content: "";
    position: absolute;
    bottom: -23px;
    left: 0;
    margin-left: 5px;
    border: 12px solid transparent;
    border-top: 12px solid #fff;
    z-index: 2;
  }

  .balloon:after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 0;
    margin-left: 5px;
    border: 12px solid transparent;
    border-top: 12px solid #ccc;
    z-index: 1;
  }
}

/* Body setting
------------------------------------------------------------------------------- */
html {
  font-size: 62.5%;
  /*ベース:10px*/
}

/*
font-size(指定：例)
1.0rem = 10px; / 1.2rem = 12px; / 1.4rem = 14px; / 1.6rem = 16px; / 1.8rem = 18px; / 2.0rem = 20px;
*/
body {
  background: #434343;
}

#page {
  max-width: inherit;
  background: #fff;
}

main {
 /* font-family: noto-sans-cjk-jp, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif; */ 
  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  overflow-y: auto;
  word-break: break-all;
}

main * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media all and (-ms-high-contrast: none) {

  *::-ms-backdrop,
  main {
  /*  font-family: "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif; */ 
  }

  /* IE11 ONLY */
}

/* SP  */
@media screen and (max-width: 640px) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
  }

  #page {
    min-width: inherit;
  }

  main {
    min-width: inherit;
  }

  /*-------------*/
}

/* common style
------------------------------------------------------------------------------- */
.content {
  background: #fff;
  overflow: hidden;
}

.pd_content__inner {
  position: relative;
  max-width: 980px;
  padding: 0 2px 0 2px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .pd_content__inner {
    padding: 0 10px;
  }
}

.pd_content__description {
  margin-bottom: 20px;
  font-size: 1.6rem;
}

.pd_content__description span {
  font-size: 1.4rem;
}

/* bread-crumb
------------------------------------------------------------------------------- */
.pd_bread-crumb {
  font-size: 1.2rem;
  margin: 20px 0 35px;
}

.pd_bread-crumb ul {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 2px 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #000000;
  line-height: 1.1;
}

.pd_bread-crumb ul li::before {
  content: ">";
  margin: 0 8px;
}

.pd_bread-crumb ul li:first-child::before {
  content: "";
  margin: 0;
}

.pd_bread-crumb ul a {
  color: #000000;
  text-decoration: underline;
}

/* SP  */
@media screen and (max-width: 640px) {
  .pd_bread-crumb {
    display: none;
    margin: 50px 10px 0;
  }

  .pd_bread-crumb li {
    margin-bottom: 10px;
  }
}

/* container / main
------------------------------------------------------------------------------- */
/* main-footer
------------------------------------------------------------------------------- */
#pd_main-footer {
  margin-top: 90px;
  background: #434343;
}

#pd_main-footer #pd_main-footer__inner {
  margin: 0 auto;
  padding: 40px 2px 30px 2px;
  max-width: 980px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_main-footer__navi {
  width: 325px;
  padding-right: 5px;
}

.pd_main-footer__navi ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -10px;
}

.pd_main-footer__navi ul li {
  width: 50%;
  margin-bottom: 10px;
}

.pd_main-footer__navi ul li a {
  color: #fff;
}

.pd_main-footer__navi ul li a.pd_icon-otherlink {
  background: url(/store/images/common/icon_otherlink.svg) 100% 50% no-repeat;
  background-size: 10px 10px;
  padding-right: 16px;
}

.pd_main-footer__contact {
  width: 323px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 3px;
  text-align: center;
  padding: 10px 15px 5px;
  color: #fff;
}

.pd_main-footer__contact dl dt {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 7px;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-btn {
  margin-bottom: 13px;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-btn a {
  display: block;
  background: #fff url(/store/images/common/icon_pc.svg) 15px 50% no-repeat;
  background-size: 13.7px 10px;
  color: #000;
  text-align: center;
  padding: 7px 0 0 17px;
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 32px;
  border-radius: 16px;
  width: 240px;
  margin: 0 auto;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel a {
  width: 136px;
  display: inline-block;
  pointer-events: none;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel span {
  font-size: 1.2rem;
  padding-left: 7px;
}

.pd_main-footer__other {
  width: 290px;
  margin-left: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_main-footer__other .pd_main-footer__other__sns {
  width: 44px;
  margin-right: 5px;
}

.pd_main-footer__other .pd_main-footer__other__sns li {
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__sns li a {
  display: block;
}

.pd_main-footer__other .pd_main-footer__other__security {
  width: 77px;
  margin-right: 5px;
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__eftc {
  width: 77px;
  margin-right: 5px;
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__text {
  width: 100%;
  margin-top: 10px;
  font-size: 1.2rem;
}

.pd_main-footer__other .pd_main-footer__other__text a {
  color: #fff;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-footer {
    margin-top: 30px;
  }

  #pd_main-footer #pd_main-footer__inner {
    padding: 25px 15px 20px 15px;
    display: block;
  }

  .pd_main-footer__navi {
    width: auto;
    padding-right: 0px;
  }

  .pd_main-footer__navi ul {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .pd_main-footer__navi ul li {
    width: 50%;
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  .pd_main-footer__contact {
    width: auto;
    padding: 10px 5px 10px;
    margin-bottom: 30px;
  }

  .pd_main-footer__contact dl dt {
    margin-bottom: 10px;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-btn {
    margin-bottom: 10px;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-tel a {
    pointer-events: auto;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-tel span {
    font-size: 1.2rem;
    padding-left: 5px;
  }

  .pd_main-footer__other {
    width: auto;
    margin-left: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pd_main-footer__other .pd_main-footer__other__text {
    margin-top: 10px;
    text-align: center;
  }

  /*-------------*/
}

/* 共通parts */
/*
    File Name   : parts.scss
    Description : パーツ用のスタイル
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/* style
----------------------------------------------------------- */
/* link
----------------------------------------------------------- */
.pd_link-defo {
  color: #333 !important;
}

.pd_link-underline {
  text-decoration: underline !important;
}

/* btn
----------------------------------------------------------- */
.pd_btn {
  display: inline-block;
  min-width: 200px;
  padding: 12px 25px;
  border-radius: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
}

.pd_btn-col01 {
  background: #fff;
  border: 1px solid #707070;
  color: #000000;
}

.pd_btn-col02 {
  background: #222222;
  border: 1px solid #222222;
  color: #fff;
}

.pd_btn-col03 {
  background: #003da5;
  border: 1px solid #003da5;
  color: #fff;
}

.pd_btn-col04 {
  background: #1876e9;
  border: 1px solid #1876e9;
  color: #fff;
}

.pd_btn-col05 {
  background: #b4b4b4;
  border: 1px solid #b4b4b4;
  color: #fff;
}

.pd_btn-center {
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .pd_btn {
    padding: 12px 12px;
  }
}

.pd_btn-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pd_btn-horizontal .pd_btn {
  margin: 0 10px;
}

.pd_btn-horizontal .pd_btn+.pd_btn {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

@media screen and (max-width: 640px) {
  .pd_btn-horizontal {
    display: block;
    text-align: center;
  }

  .pd_btn-horizontal .pd_btn {
    margin: 0 auto;
  }

  .pd_btn-horizontal .pd_btn+.pd_btn {
    margin: 20px auto 0;
  }
}

.pd_btn-vertical {
  text-align: center;
}

.pd_btn-vertical .pd_btn,
.pd_btn-vertical .pd_btn-wrap {
  margin: 0 auto;
}

.pd_btn-vertical .pd_btn+.pd_btn,
.pd_btn-vertical .pd_btn+.pd_btn-wrap,
.pd_btn-vertical .pd_btn-wrap+.pd_btn,
.pd_btn-vertical .pd_btn-wrap+.pd_btn-wrap {
  margin: 20px auto 0;
}

.pd_icon-plus {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/ion_plus_white.svg) left 50% no-repeat;
  background-size: 10px;
}

.pd_icon-cart {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/icon_cart_white.svg) left 50% no-repeat;
  background-size: 14px;
}

/* title
----------------------------------------------------------- */
.pd_content__title {
  margin-bottom: 30px;
  font-size: 2.6rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_content__title {
    margin-top: 40px;
    line-height: 1.4;
  }
}

.pd_content_message {
  font-size: 1.6rem;
}

.pd_box {
  padding: 20px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_box {
    padding: 15px 8px;
  }
}

.pd_box-col01 {
  background: #edf4ff;
}

.pd_box-col02 {
  background: #feeeee;
}

.pd_box-col03 {
  background: #f7f7f7;
}

/* paging ページング
----------------------------------------------------------- */
.pd_pager-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px 0 55px;
}

.pd_pager-btn a {
  position: relative;
  display: block;
  background: #f2f2f2;
  min-width: 31px;
  height: 31px;
  border-radius: 4px;
  color: #000;
  text-align: center;
  border-radius: 50%;
}

.pd_pager-btn a span {
  display: none;
}

.pd_prev-btn a::before,
.pd_next-btn a::after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  margin: auto;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: "";
}

.pd_prev-btn a::before {
  left: 14px;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.pd_next-btn a::after {
  right: 15px;
}

.pd_pager-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

.pd_pager-list li {
  margin: 0 3px;
  min-width: 31px;
  text-align: center;
  line-height: 1;
  color: #666666;
}

.pd_pager-list li a {
  display: block;
  text-align: center;
  padding: 9px 3px 0;
  min-width: 31px;
  height: 31px;
  border-radius: 4px;
  color: #000;
  background: #f2f2f2;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pd_pager-list li.pd_current a {
  color: #fff;
  background-color: #1876e9;
}

.pd_pager-count {
  font-size: 1.4rem;
  text-align: center;
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_pager-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0px 0 55px;
  }

  .pd_pager-btn {
    width: 140px;
    max-width: 40%;
  }

  .pd_pager-btn a {
    height: 44px;
    font-size: 1.4rem;
    padding-top: 12px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
  }

  .pd_pager-btn a span {
    display: inline;
  }

  .pd_prev-btn a::before {
    left: 14px;
  }

  .pd_next-btn a::after {
    right: 15px;
  }

  .pd_pager-list {
    display: none;
  }

  .pd_pager-count {
    text-align: center;
    display: block;
  }

  /*-------------*/
}

/* List
----------------------------------------------------------- */
ul.pd_basic {
  list-style: disc;
  padding: 0 0 0 1em;
  margin-left: 5px;
}

ul.pd_basic li {
  margin-bottom: 1.5em;
}

/* box
----------------------------------------------------------- */
/* box-list-type01 */
@media screen and (min-width: 641px) {
  .pd_box-list-type01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_box-list-type01 li {
  width: 100%;
  max-width: 476px;
  margin-top: 20px;
  border: 1px solid #e5e5e5;
}

@media screen and (min-width: 641px) {
  .pd_box-list-type01 li:nth-child(-n + 2) {
    margin-top: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_box-list-type01 li:first-child {
    margin-top: 0;
  }
}

.pd_box-list-type01__head-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
}

.pd_box-list-type01__body {
  padding: 15px;
}

.pd_box-list-type01__body th,
.pd_box-list-type01__body td,
.pd_box-list-type01__body>div {
  padding-bottom: 5px;
  text-align: left;
}

.pd_box-list-type01__edit .pd_btn {
  min-width: 105px;
  padding: 5px 15px;
  font-size: 1.2rem;
}

.pd_box-list-type01__delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.pd_box-list-type01__delete .pd_icon-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
}

.pd_box-list-type01__delete a {
  color: #666666;
  font-size: 1rem;
  text-decoration: underline;
}

/* END box-list-type01 */

/* Table
----------------------------------------------------------- */
table {
  width: 100%;
  table-layout: fixed;
  text-align: left;
}

/* form 関係
----------------------------------------------------------- */
/* list-style01 */
.pd_list-style01 {
  margin-bottom: 50px;
  border: 1px solid #e5e5e5;
}

.pd_list-style01 li {
  padding: 20px 30px;
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_list-style01 li {
    padding: 15px 20px;
  }
}

.pd_list-style01 li:last-child {
  border-bottom: 0;
}

.pd_list-style01 li.pd_list-style01__name .pd_list-style01__first {
  min-width: 28px;
}

.pd_list-style01 li.pd_list-style01__name .pd_list-style01__second {
  min-width: 28px;
}

.pd_list-style01__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
  .pd_list-style01__inner {
    display: block;
    margin-bottom: 15px;
  }
}

.pd_list-style01__inner:last-child {
  margin-bottom: 0;
}

.pd_list-style01__title {
  font-weight: bold;
}

@media screen and (min-width: 641px) {
  .pd_list-style01__title {
    width: 220px;
  }
}

@media screen and (max-width: 640px) {
  .pd_list-style01__title {
    margin-bottom: 10px;
  }
}

.pd_list-style01__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 641px) {
  .pd_list-style01__inner__tel .pd_list-style01__content {
    width: calc(100% - 250px);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_list-style01 .pd_is-require--right::after {
  margin-left: 10px;
}

.pd_list-style01__first {
  margin-right: 10px;
}

.pd_list-style01__second {
  margin: 0 10px;
}

.pd_list-style01__caution {
  margin-left: 10px;
  color: #666666;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_list-style01__caution {
    width: 100%;
    margin: 7px 0 0 0;
  }

  .pd_list-style01__caution-name {
    margin-left: 23px;
  }
}

.pd_list-style01__hyphen {
  width: 10px;
  height: 1px;
  margin: 0 5px;
  background: #222222;
}

.pd_list-style01 .pd_btnWrap {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_list-style01 .pd_btnWrap {
    margin-left: 10px;
  }
}

@media screen and (max-width: 320px) {
  .pd_list-style01 .pd_input-name {
    width: 85px;
  }

  .pd_list-style01 .pd_btnWrap {
    margin-top: 10px;
  }
}

/* END list-style01 */
/* form パーツ関連 */
.pd_input-name {
  width: 165px;
}

@media screen and (max-width: 640px) {
  .pd_input-name {
    width: 100px;
  }
}

.pd_input-address {
  width: 125px;
}

.pd_input-delivery {
  width: 416px;
}

.pd_input-city {
  width: 500px;
}

.pd_input-town {
  width: 500px;
}

.pd_input-apartment {
  width: 500px;
}

.pd_input-tel {
  width: 200px;
}

.pd_btn-address {
  min-width: auto;
  padding: 5px 10px;
  border: 17px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_select-prefecture {
  width: 188px;
}

.pd_btn-disable {
  background: #b4b4b4;
  color: #fff;
  border: none;
  pointer-events: none;
}

@media screen and (max-width: 640px) {

  .pd_input-delivery,
  .pd_input-city,
  .pd_input-town,
  .pd_input-apartment {
    width: 100%;
  }

  /*-------------*/
}

.pd_searchWrap {
  position: relative;
  z-index: 5;
  max-width: 1040px;
  margin: 165px auto 0;
  padding: 15px 30px 0 30px;
  background-color: rgba(255, 255, 255, 0.4);
}

.pd_is-require--left,
.pd_is-require--right {
  display: inline-block;
  vertical-align: middle;
}

.pd_is-require--left::before,
.pd_is-require--right::after {
  content: "必須";
  display: inline-block;
  min-width: 38px;
  padding: 3px 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  background: #d00000;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  vertical-align: middle;
}

.pd_is-require--left::before {
  margin-right: 15px;
}

.pd_is-require--right::after {
  margin-left: 15px;
}

/* END form パーツ関連 */
/* box-search-type01 */
.pd_box-search-type01 {
  max-width: 976px;
  margin: 0 auto;
  padding: 30px;
  background: #f7f7f7;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 25px;
}

@media screen and (max-width: 640px) {
  .pd_box-search-type01 {
    margin-bottom: 40px;
    padding: 30px 10px;
    font-size: 1.4rem;
  }
}

.pd_box-search-type01 .pd_btn {
  margin: 0 auto;
}

.pd_box-search__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_box-search__itemWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.pd_box-search__year {
  width: 95px;
}

@media screen and (max-width: 640px) {
  .pd_box-search__year {
    width: 72px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search__year {
    width: 87px;
  }
}

.pd_box-search__month {
  width: 70px;
}

@media screen and (max-width: 640px) {
  .pd_box-search__month {
    width: 50px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search__month {
    width: 57px;
  }
}

/* END box-search-type01 */
/* searchResult */
.pd_searchResult {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  color: #666666;
}

.pd_searchResult .pd_select-type-twoway {
  width: 85px;
}

@media screen and (max-width: 640px) {
  .pd_searchResult {
    margin-bottom: 10px;
  }
}

.pd_searchResult__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_searchResult__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.pd_searchResult__wrap .pd_btn {
  min-width: 110px;
  padding: 7px 10px;
  border-radius: 35px;
  font-size: 1.2rem;
  line-height: 1;
}

@media screen and (min-width: 641px) {
  .pd_searchResult__wrap .pd_btn {
    margin-left: 20px;
  }
}

@media screen and (max-width: 640px) {

  .pd_searchResult__num,
  .pd_searchResult__select {
    margin-bottom: 10px;
  }
}

/* END searchResult */

.pd_addDist {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.pd_addDist .pd_btn {
  min-width: 110px;
  padding: 7px 10px;
  border-radius: 35px;
  font-size: 1.2rem;
  line-height: 1;
}

@media screen and (max-width: 640px) {
  .pd_addDist {
    margin-bottom: 10px;
    justify-content: flex-start;
  }
}

/* textarea-type01 */
.pd_textarea-type01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: transparent;
  font-size: 1.3rem;
  width: 100%;
  min-height: 7em;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_textarea-type01 {
    font-size: 1.6rem;
  }
}

/* END textarea-type01 */
/* input-type01 */
.pd_input-type01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 1.3rem;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_input-type01 {
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .pd_input-type01.hasDatepicker {
    font-size: 1.3rem;
  }
}

/* END input-type01 */
/* select-type01 */
.pd_select-type01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  padding: 0 27px 0 12px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.2;
  background-color: #fff;
  background-image: url(/store/images/common/select_arrow.svg);
  background-position: calc(100% - 11px) 50%;
  background-repeat: no-repeat;
  background-size: 6.4px 4.3px;
  font-size: 1.3rem;
}

.pd_select-type01::-ms-expand {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_select-type01 {
    font-size: 1.6rem;
  }
}

/* END select-type01 */
/* select-type-twoway */
.pd_select-type-twoway {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  padding: 0 27px 0 12px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.2;
  background-color: #fff;
  background-image: url(/store/images/common/select_arrow_twoway.svg);
  background-position: calc(100% - 11px) 50%;
  background-repeat: no-repeat;
  background-size: 6.4px 10.6px;
  font-size: 1.3rem;
}

.pd_select-type-twoway::-ms-expand {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_select-type-twoway {
    font-size: 1.6rem;
  }

  .pd_select-type-twoway.pd_clock {
    font-size: 1.3rem;
  }
}

/* END select-type-twoway */
/* custom-control =================*/
.pd_custom-control {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 25px;
  padding-left: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
}

@media all and (-ms-high-contrast: none) {

  *::-ms-backdrop,
  .pd_custom-control {
    display: inline-block;
  }

  /* IE11 */
}

label.pd_custom-control {
  cursor: pointer;
}

.pd_custom-control-input {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
}

.pd_custom-control-indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100% !important;
}

.pd_custom-control-description {
  display: block;
  margin-left: 5px;
}

@media all and (-ms-high-contrast: none) {

  *::-ms-backdrop,
  .pd_custom-control-description {
    padding-top: 3px;
  }

  /* IE11 */
}

/* custom-checkbox */
.pd_custom-control.pd_custom-checkbox {
  /*
    .pd_custom-control-input:focus {
        & ~ .pd_custom-control-indicator {
            outline: 3px dotted #5091FB;
        }
    }
    */
}

.pd_custom-control.pd_custom-checkbox .pd_custom-control-indicator {
  top: 50%;
  margin-top: -12px;
  background: url(/store/images/common/icon_check.svg) 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-checkbox .pd_custom-control-input:checked~.pd_custom-control-indicator {
  background: url(/store/images/common/icon_check_selected.svg) 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_pressTabkey .pd_custom-control-input:focus~.pd_custom-control-indicator {
  outline-width: 3px;
  outline-style: dotted;
}

/* END custom-checkbox */
/* custom-radio */
.pd_custom-control.pd_custom-radio {
  /*
    .pd_custom-control-input:focus {
        & ~ .pd_custom-control-indicator {
            outline: 3px dotted #5091FB;
        }
    }
    */
}

.pd_custom-control.pd_custom-radio .pd_custom-control-indicator {
  top: 50%;
  margin-top: -12px;
  background: url(/store/images/common/icon_radio.svg) 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-radio .pd_custom-control-input:checked~.pd_custom-control-indicator {
  background: url(/store/images/common/icon_radio_selected.svg) 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-radio .pd_custom-control-input:disabled~.pd_custom-control-indicator {
  opacity: 0.5;
}

/* END custom-radio */
/* END custom-control =================*/
/* MODAL 関係
----------------------------------------------------------- */
.pd_modal-bg {
  display: none;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: auto;
}

.pd_modal-open {
  overflow: hidden;
  pointer-events: none;
}

.pd_modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow: auto;
  /**/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 10px 20px 10px;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
}

.pd_modal-content {
  position: relative;
  width: 100%;
  max-width: 976px;
  margin: auto;
}

.pd_modal-header__ttl {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 0px;
}

.pd_modal-header__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pd_modal-header__close a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/store/images/common/icon_modal_close.svg) 50% 50% no-repeat;
  background-size: 20px 20px;
}

.pd_modal-body {
  position: relative;
  overflow: auto;
  padding: 0;
  border-radius: 0 0px 5px 5px;
  background: #fff;
  text-align: left;
  font-size: 1.4rem;
}


/* モーダル（お届け先追加） */
.pd_modal-body .pd_modal-header {
  padding: 50px 40px 0;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_modal-header {
    padding: 50px 20px 0;
  }
}

.pd_modal-body .pd_list-style01__title {
  min-width: 210px;
}

.pd_modal-body .pd_list-style01__content {
  width: 100%;
}

.pd_modal-body .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
  min-width: calc(210px - 24px);
  margin-right: 24px;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
    min-width: auto;
    margin-right: 0;
  }
}

.pd_modal-body .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
  margin-left: -24px;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
    margin-left: 0;
  }
}

.pd_modal-body .pd_list-style01__inner.pd_family_name .pd_list-style01__first,
.pd_modal-body .pd_list-style01__inner.pd_family_name .pd_list-style01__second {
  min-width: 28px;
}

.pd_modal-body .pd_list-style01__inner.pd_tel {
  align-items: flex-start;
}

.pd_modal-body .pd_list-style01__inner.pd_tel .pd_list-style01__title {
  margin-top: 6.5px;
}

.pd_modal-body .pd_list-style01__cautionWrap {
  display: block;
  width: 100%;
}

.pd_modal-body .pd_list-style01__cautionWrap .pd_list-style01__caution {
  display: block;
  margin-top: 5px;
  margin-left: 0;
}

.pd_modal-body .pd_list-style01__cautionWrap .pd_list-style01__caution:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_list-style01 .pd_btnWrap {
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_btn-address {
    padding: 10px 7px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal-body .pd_btn-address {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_modal-body .pd_input-delivery {
  width: 406px;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_input-delivery {
    width: 100%;
  }
}

.pd_modal-body .pd_input-name {
  width: 160px;
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_input-name {
    width: 93px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal-body .pd_input-name {
    width: 65px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal-body .pd_input-address {
    width: 90px;
  }

  .pd_input-type01.pd_input-address {
    width: 110px;
  }
}

.pd_modal-body .pd_input-city,
.pd_modal-body .pd_input-apartment {
  width: 430px;
}

/* 商品一覧 */
/*
    File Name   : productlist.scss
    Description : 商品一覧 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* **.TITLE
----------------------------------------------------------- */
/* **.TITLE
----------------------------------------------------------- */
/* 商品詳細 */
/*
    File Name   : productdetail.scss
    Description : 商品詳細 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* **.TITLE
----------------------------------------------------------- */
/* **.TITLE
----------------------------------------------------------- */
/* マイページ系(注文・見積・契約など) */
/*
    File Name   : mypage01.scss
    Description : マイページ系(注文・見積・契約など) 用

    01.プラン選択条件一覧
    02.契約履歴一覧
    03.契約詳細
    04.注文履歴一覧

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.プラン選択条件一覧
----------------------------------------------------------- */
/* refine-search */
.pd_refine-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f7f7;
  padding: 25px 20px;
  font-size: 1.6rem;
  margin-bottom: 40px;
}

.pd_refine-search h2 {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.pd_refine-search dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search dl dt {
  font-weight: normal;
}

.pd_refine-search_item01 {
  margin-right: 20px;
}

.pd_refine-search_item01 dt {
  width: 76px;
}

.pd_refine-search_item01 dd {
  width: 160px;
}

.pd_refine-search_item01 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item02 dt {
  width: 76px;
}

.pd_refine-search_item02 dd {
  width: 220px;
}

.pd_refine-search_item02 dd .pd_custom-control {
  margin: 0 10px 0 0;
}

.pd_refine-search_item03 dt {
  width: 93px;
}

.pd_refine-search_item03 dd {
  width: 287px;
}

@media screen and (max-width: 640px) {

  .pd_refine-search_item03 dd .pd_delivery_preferred_date__calendar,
  .pd_refine-search_item03 dd .pd_delivery_preferred_date__calendar .pd_input-name {
    width: 100%;
  }
}

.pd_refine-search_item03 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item03 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item03 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

.pd_refine-search_item04 {
  margin-right: 20px;
}

.pd_refine-search_item04 dt {
  width: 76px;
}

.pd_refine-search_item04 dd {
  width: 488px;
}

.pd_refine-search_item04 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item05 dt {
  width: 62px;
}

.pd_refine-search_item05 dd {
  width: 287px;
}

.pd_refine-search_item05 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item05 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item05 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

.pd_refine-search_item06 {
  width: 100%;
  margin-top: 20px;
}

.pd_refine-search_item06 dt {
  width: 76px;
}

.pd_refine-search_item06 dd {
  width: calc(100% - 76px);
}

.pd_refine-search_item06 dd .pd_custom-control {
  margin: 0 10px 0 0;
}

.pd_refine-search_item07 {
  margin-right: 20px;
}

.pd_refine-search_item07 dt {
  width: 45px;
}

.pd_refine-search_item07 dd {
  width: 160px;
}

.pd_refine-search_item07 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item08 {
  margin-right: 20px;
}

.pd_refine-search_item08 dt {
  width: 65px;
}

.pd_refine-search_item08 dd {
  width: 230px;
}

.pd_refine-search_item08 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item09 dt {
  width: 85px;
}

.pd_refine-search_item09 dd {
  width: 307px;
}

.pd_refine-search_item09 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item09 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item09 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

@media screen and (max-width: 640px) {
  .pd_refine-search_item09 .pd_delivery_preferred_date__calendar {
    width: 100%;
  }
}

.pd_refine-search_item09 .pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
  width: 145px;
}

@media screen and (max-width: 640px) {
  .pd_refine-search_item09 .pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
    width: 100%;
  }
}

.pd_refine-search__btn {
  width: 100%;
  padding-top: 25px;
  text-align: center;
}

.pd_refine-search__btn .pd_btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0 auto;
  width: 160px;
  min-width: 160px;
  font-size: 1.4rem;
  padding: 10px;
}

.pd_refine-search-no-item {
  background: #feeeee;
  padding: 17px 3px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #d00000;
  text-align: center;
  margin: 40px 0 45px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_refine-search {
    display: block;
    padding: 25px 10px 30px;
    margin: 0 -10px 30px;
  }

  .pd_refine-search h2 {
    margin-bottom: 15px;
  }

  .pd_refine-search dl {
    display: block;
    margin: 0 0 15px 0;
  }

  .pd_refine-search dl dt {
    width: auto !important;
    margin-bottom: 5px;
  }

  .pd_refine-search dl dd {
    width: auto !important;
  }

  .pd_refine-search_item02 dd .pd_custom-control {
    width: 48.5%;
    margin: 0 0px 0 0;
  }

  .pd_refine-search_item03 dd ul,
  .pd_refine-search_item05 dd ul,
  .pd_refine-search_item09 dd ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .pd_refine-search_item03 dd ul li,
  .pd_refine-search_item05 dd ul li,
  .pd_refine-search_item09 dd ul li {
    max-width: 45%;
  }

  .pd_refine-search_item03 dd ul li .pd_select-type-twoway,
  .pd_refine-search_item05 dd ul li .pd_select-type-twoway,
  .pd_refine-search_item09 dd ul li .pd_select-type-twoway {
    width: 200px;
    max-width: 100%;
  }

  .pd_refine-search_item03 dd ul li:nth-of-type(2),
  .pd_refine-search_item05 dd ul li:nth-of-type(2),
  .pd_refine-search_item09 dd ul li:nth-of-type(2) {
    padding: 0 3px;
  }

  .pd_refine-search_item06 dd .pd_custom-control {
    width: 48.5%;
    margin: 0 0px 20px 0;
  }

  .pd_refine-search__btn {
    padding-top: 15px;
  }

  .pd_refine-search_item06+.pd_refine-search__btn {
    margin-top: -5px;
    padding-top: 0;
  }

  .pd_refine-search-no-item {
    margin: 50px 0 60px;
  }

  /*-------------*/
}

/* END refine-search */
/* item-search-count-wrap */
.pd_item-search-count-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
}

.pd_item-search-count {
  color: #666666;
  padding-right: 10px;
}

.pd_item-search-viewnum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_item-search-viewnum span {
  color: #666666;
  padding-right: 10px;
}

.pd_item-search-viewnum .pd_select-type01 {
  width: 84px;
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/* END item-search-count-wrap */
/* plan-choice-list */
.pd_plan-choice-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.pd_plan-choice-list>li {
  width: calc(50% - 10px);
  margin: 0 20px 20px 0;
  border: 1px solid #e5e5e5;
  background: #fff;
}

.pd_plan-choice-list>li:nth-of-type(2n) {
  margin-right: 0;
}

.pd_plan-choice-list__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #e5e5e5;
}

.pd_plan-choice-list__header dt {
  font-weight: normal;
}

.pd_plan-choice-list__header dd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_plan-choice-list__header dd a.pd_plan-choice-list__delete-btn {
  display: inline-block;
  padding: 2px 2px 2px 20px;
  min-height: 16px;
  color: #666666;
  text-decoration: underline;
  font-size: 1rem;
  background: url(/store/images/common/icon_trash.svg) 0 50% no-repeat;
  background-size: 15px 16px;
}

.pd_plan-choice-list__header dd .pd_plan-choice-list__detail-btn {
  margin-left: 13px;
  display: block;
  width: 104px;
  text-align: center;
  height: 34px;
  padding-top: 7px;
  border-radius: 17px;
  border: 1px solid #cccccc;
  background: #fff;
  font-size: 1.2rem;
  color: #222222;
}

.pd_plan-choice-list__header dd .pd_plan-choice-list__detail-btn.pd_disabled {
  color: #fff;
  background-color: #b4b4b4;
}

.pd_plan-choice-list__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: center;
  padding: 15px 20px;
}

.pd_plan-choice-list__body figure {
  line-height: 0;
  text-align: center;
  max-height: 105px;
  width: 140px;
}

.pd_plan-choice-list__body figure img {
  max-width: 100%;
  max-height: 100%;
}

.pd_plan-choice-list__body p {
  width: calc(100% - 140px);
  padding-left: 20px;
}

.pd_plan-choice-list__body p strong {
  font-weight: bold;
  display: block;
  margin-bottom: 7px;
}

.pd_plan-choice-list__body p span {
  display: block;
}

.pd_plan-choice-list__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 20px 15px;
}

.pd_plan-choice-list__footer span {
  display: block;
}

.pd_plan-choice-list__footer strong {
  display: block;
  color: #d00000;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_plan-choice-list {
    display: block;
    padding-bottom: 0px;
  }

  .pd_plan-choice-list>li {
    width: auto;
    margin: 0 0px 30px 0;
  }

  .pd_plan-choice-list__header {
    padding: 10px 20px;
    display: block;
  }

  .pd_plan-choice-list__header dt {
    margin-bottom: 3px;
  }

  .pd_plan-choice-list__body {
    padding: 20px 20px 17px;
  }

  .pd_plan-choice-list__footer {
    display: block;
    padding: 0px 20px 15px;
  }

  .pd_plan-choice-list__footer span {
    margin-bottom: 3px;
  }

  /*-------------*/
}

/* plan-choice-list  */
/* 02.契約履歴一覧
----------------------------------------------------------- */
/* contract-history-list */
.pd_contract-history-list>li {
  margin: 0 0px 30px 0;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_contract-history-list__header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 18px 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #e5e5e5;
}

.pd_contract-history-list__header dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_contract-history-list__header dl dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 40px;
}

.pd_contract-history-list__header dl dt .pd_contract-history-list__icon01 {
  color: #d00000;
  display: inline-block;
  padding: 8px 12px;
  background: #feeeee;
  margin: -7px 20px -7px 0;
}

.pd_contract-history-list__header dl dt .pd_status01 {
  color: #003da5;
}

.pd_contract-history-list__header dl dt .pd_status02 {
  color: #158900;
}

.pd_contract-history-list__header dl dt .pd_status03 {
  color: #666666;
}

.pd_contract-history-list__header dl dd {
  margin-right: 25px;
}

.pd_contract-history-list__header a.pd_contract-history-list__detail-link {
  display: inline-block;
  color: #222;
  border-radius: 20px;
  min-width: 105px;
  padding: 5px 15px;
  font-size: 1.2rem;
  background: #fff;
  border: 1px solid #cccccc;
  min-width: 130px;
}

.pd_contract-history-list__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
  padding: 0px 20px;
  width: 620px;
  border-right: 1px solid #e5e5e5;
}

.pd_contract-history-list__body figure {
  line-height: 0;
  text-align: center;
  max-height: 105px;
  width: 140px;
}

.pd_contract-history-list__body figure img {
  max-width: 100%;
  max-height: 100%;
}

.pd_contract-history-list__body strong {
  width: calc(100% - 140px);
  padding-left: 20px;
  font-weight: bold;
}

.pd_contract-history-list__footer {
  width: calc(100% - 620px);
  text-align: right;
  padding: 20px 20px 20px;
}

.pd_contract-history-list__footer p {
  margin-bottom: 2px;
}

.pd_contract-history-list__footer dl {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_contract-history-list__footer dl dt {
  font-weight: bold;
}

.pd_contract-history-list__footer dl dt::after {
  content: "：";
  display: inline;
}

.pd_contract-history-list__footer dl dd {
  font-weight: bold;
}

.pd_contract-history-list__footer dl dd strong {
  font-size: 1.8rem;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_contract-history-list>li {
    display: block;
  }

  .pd_contract-history-list__header {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: center;
    padding: 15px 15px 15px 20px;
  }

  .pd_contract-history-list__header dl {
    display: block;
    font-size: 12px;
  }

  .pd_contract-history-list__header dl dt {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .pd_contract-history-list__header dl dt .pd_contract-history-list__icon01 {
    padding: 6px 10px;
    margin: 0px 10px 0px 0;
  }

  .pd_contract-history-list__header dl dd {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .pd_contract-history-list__header dl dd:last-child {
    margin-bottom: 0;
  }

  .pd_contract-history-list__body {
    margin: 0;
    padding: 20px;
    width: auto;
    border-right: none;
  }

  .pd_contract-history-list__footer {
    border-top: 1px solid #e5e5e5;
    width: auto;
    text-align: right;
    padding: 18px 0px 18px;
    margin: 0 20px;
  }

  .pd_contract-history-list__footer p {
    margin-bottom: 2px;
  }

  .pd_contract-history-list__footer dl {
    margin-top: 10px;
  }

  /*-------------*/
}

/* contract-history-list  */
/* 03.契約詳細
----------------------------------------------------------- */
/* contract-atten-text */
.pd_contract-atten-text {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #d00000;
  text-indent: -1em;
  padding-left: 1em;
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/* END contract-atten-text */
/* contract-info */
.pd_contract-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pd_contract-info__status {
  font-weight: bold;
  font-size: 1.6rem;
  color: #158900;
  margin-right: 20px;
}

.pd_contract-info dl {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 20px;
}

.pd_contract-info dl dt {
  display: inline-block;
}

.pd_contract-info dl dd {
  position: relative;
  display: inline-block;
}

.pd_contract-info dl dd::before {
  content: "：";
  display: inline;
}

.pd_contract-info__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.pd_contract-info__link li {
  margin-right: 19px;
}

.pd_contract-info__link li a {
  display: inline-block;
  color: #1876e9;
  padding-left: 20px;
  min-height: 14px;
}

.pd_contract-info .pd_icon-review {
  background: url(/store/images/common/icon_pen_blue.svg) 1px 50% no-repeat;
  background-size: 12px 12px;
}

.pd_contract-info .pd_icon-repair {
  background: url(/store/images/common/icon_settings_blue.svg) 0 50% no-repeat;
  background-size: 14px 14px;
}

.pd_contract-info .pd_icon-contact {
  background: url(/store/images/common/icon_message_blue.svg) 0 50% no-repeat;
  background-size: 15.5px 14px;
}

.pd_contract-info .pd_icon-receipt {
  background: url(/store/images/common/icon_printer_blue.svg) 0 50% no-repeat;
  background-size: 14px 14px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_contract-info {
    display: block;
    margin-bottom: 20px;
  }

  .pd_contract-info__status {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .pd_contract-info dl {
    margin-right: 0px;
  }

  .pd_contract-info__link {
    margin-top: 15px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .pd_contract-info__link li {
    margin-right: 0px;
  }

  /*-------------*/
}

/* END contract-info */
/* contract-value */
.pd_contract-value {
  background: #f7f7f7;
  padding: 20px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-value__val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 50px 18px 0;
}

.pd_contract-value__val dt {
  font-size: 1.6rem;
  font-weight: normal;
  margin-right: 30px;
}

.pd_contract-value__val dt span {
  font-size: 1.4rem;
}

.pd_contract-value__val dd {
  font-size: 1.8rem;
}

.pd_contract-value__val dd span {
  font-size: 1.4rem;
}

.pd_contract-value__total-val {
  border-top: 1px solid #dedede;
  width: 100%;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pd_contract-value__total-val dt {
  font-size: 1.8rem;
  font-weight: normal;
  margin-right: 40px;
}

.pd_contract-value__total-val dt span {
  font-size: 1.4rem;
}

.pd_contract-value__total-val dd {
  line-height: 1.1;
}

.pd_contract-value__total-val dd strong {
  font-size: 2.8rem;
  font-weight: bold;
}

.pd_contract-value__total-val dd span {
  font-size: 1.6rem;
}

.pd_contract-value__total-val dd em {
  font-size: 1.4rem;
  font-weight: normal;
  padding-left: 10px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_contract-value {
    padding: 30px 20px 20px;
    margin-bottom: 30px;
    display: block;
  }

  .pd_contract-value__val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0px 5px 0;
  }

  .pd_contract-value__val dt {
    margin-right: 10px;
  }

  .pd_contract-value__total-val {
    margin-top: 20px;
    display: block;
  }

  .pd_contract-value__total-val dt {
    text-align: right;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .pd_contract-value__total-val dd {
    text-align: right;
  }

  /*-------------*/
}

/* END contract-value */
/* contract-otherinfo */
.pd_contract-otherinfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item {
  width: 50%;
  padding-right: 30px;
  margin-bottom: 35px;
}

.pd_contract-otherinfo__item dl dt {
  font-weight: normal;
  font-size: 1.4rem;
  margin-bottom: 12px;
}

.pd_contract-otherinfo__item dl dt strong {
  display: block;
  margin-bottom: 3px;
  font-weight: normal;
  font-size: 1.6rem;
}

.pd_contract-otherinfo__item dl dd {
  font-size: 1.4rem;
}

.pd_contract-otherinfo__item dl dd p {
  margin-bottom: 3px;
}

.pd_contract-otherinfo__item dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item dl dd ul li {
  margin-right: 30px;
  word-break: break-all;
}

.pd_contract-otherinfo__item table {
  table-layout: auto;
  width: auto;
  margin-right: 50px;
}

.pd_contract-otherinfo__item table th {
  padding: 2px 0px 2px 0;
  text-align: left;
}

.pd_contract-otherinfo__item table td {
  padding: 2px 5px 2px 0;
}

.pd_contract-otherinfo__item table td::before {
  content: "：";
  display: inline;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_contract-otherinfo.pd_contract-otherinfo-user .pd_contract-otherinfo__item+.pd_contract-otherinfo__item {
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
  }

  .pd_contract-otherinfo {
    display: block;
  }

  .pd_contract-otherinfo__item {
    width: auto;
    padding-right: 0px;
    margin-bottom: 35px;
  }

  .pd_contract-otherinfo__item dl dt {
    font-weight: normal;
    font-size: 1.4rem;
    margin-bottom: 12px;
  }

  .pd_contract-otherinfo__item dl dt strong {
    display: block;
    margin-bottom: 3px;
    font-weight: normal;
    font-size: 1.6rem;
  }

  .pd_contract-otherinfo__item dl dd {
    font-size: 1.4rem;
  }

  .pd_contract-otherinfo__item dl dd p {
    margin-bottom: 3px;
  }

  .pd_contract-otherinfo__item dl dd ul {
    display: block;
  }

  .pd_contract-otherinfo__item dl dd ul li {
    margin-right: 0px;
  }

  .pd_contract-otherinfo__item table {
    table-layout: auto;
    width: auto;
    margin-right: 0px;
  }

  .pd_contract-otherinfo__item table th {
    padding: 2px 0px 2px 0;
  }

  .pd_contract-otherinfo__item table td {
    padding: 2px 5px 2px 0;
  }

  .pd_contract-otherinfo__item table td::before {
    content: "：";
    display: inline;
  }

  /*-------------*/
}

/* END contract-otherinfo */
/* contract-ttl */
.pd_contract-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  color: #222222;
  margin-bottom: 15px;
}

.pd_contract-ttl a {
  display: inline-block;
  margin-left: 20px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #1876e9;
}

.pd_contract-ttl02 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #222222;
  margin-bottom: 13px;
}

.pd_contract-ttl02 a {
  display: inline-block;
  margin-left: 20px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #1876e9;
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/* END contract-ttl */
/* contract-moreinfo */
.pd_contract-moreinfo {
  border: 1px solid #e5e5e5;
  margin-bottom: 45px;
  padding: 20px;
}

.pd_contract-moreinfo .pd_contract-ttl {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}

.pd_contract-moreinfo .pd_contract-moreinfo__tbl-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_contract-moreinfo table {
  table-layout: auto;
  width: auto;
  margin-right: 50px;
}

.pd_contract-moreinfo table a {
  font-size: 1.4rem;
  font-weight: normal;
  color: #1876e9;
}

.pd_contract-moreinfo table th {
  padding: 2px 0px 2px 0;
  text-align: left;
  white-space: nowrap;
}

.pd_contract-moreinfo table td {
  padding: 2px 0px 2px 0;
  text-align: left;
  white-space: nowrap;
}

.pd_contract-moreinfo table td::before {
  content: "：";
  display: inline;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_contract-moreinfo .pd_contract-moreinfo__tbl-wrap {
    display: block;
  }

  .pd_contract-moreinfo table {
    table-layout: auto;
    width: calc(100% + 5px);
    margin-right: -5px;
  }

  .pd_contract-moreinfo table th {
    padding: 2px 0px 2px 0;
    width: 126px;
    white-space: normal;
  }

  .pd_contract-moreinfo table td {
    padding: 2px 0px 2px 0;
    letter-spacing: -0.03em;
    width: calc(100% - 126px);
    white-space: normal;
  }

  /*-------------*/
}

/* END contract-moreinfo */
/* contract-tbl-type01 */
table.pd_contract-tbl-type01 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e5e5e5;
  margin-bottom: 50px;
  table-layout: auto;
}

table.pd_contract-tbl-type01 th {
  padding: 12px 20px;
  font-weight: normal;
  font-size: 1.4rem;
  text-align: center;
  background: #fafafa;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
}

table.pd_contract-tbl-type01 td {
  padding: 12px 20px;
  font-size: 1.4rem;
  text-align: center;
  text-align: center;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name {
  width: 662px;
  text-align: left;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__product_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  table.pd_contract-tbl-type01 .pd_contract-tbl-type01__product_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl {
  width: calc(100% - 150px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl dt {
  width: 205px;
  padding-right: 10px;
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl dd {
  width: calc(532px - 205px - 5px);
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl dd a {
  color: #1876e9;
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-code {
  width: 150px;
}

table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name02 {
  width: calc(100% - 150px);
  text-align: left;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn a:not(:first-child) {
  margin-top: 7px;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn_disable,
table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn_review {
  line-height: 1;
  display: inline-block;
  padding: 8px 17px;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn_disable {
  border: 1px solid #ccc;
  color: #222 !important;
}

table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn_review {
  font-weight: bold;
  background: #000000;
  color: #fff !important;
}

/* SP */
@media screen and (max-width: 640px) {
  table.pd_contract-tbl-type01 th {
    padding: 12px 12px;
  }

  table.pd_contract-tbl-type01 th:first-child {
    width: 50%;
  }

  table.pd_contract-tbl-type01 td {
    padding: 12px 12px;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name {
    position: relative;
    width: auto;
    text-align: left;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl {
    display: block;
    width: 100%;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl dt {
    width: inherit;
    padding-right: 0px;
    white-space: normal;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name dl dd {
    width: inherit;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-code {
    width: 100px;
  }

  table.pd_contract-tbl-type01 td.pd_contract-tbl-type01__item-name02 {
    width: calc(100% - 100px);
  }

  table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn {
    line-height: 1;
    margin-top: 3px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn a {
    margin-top: 7px;
  }

  table.pd_contract-tbl-type01 .pd_contract-tbl-type01__btn a:not(:first-child) {
    margin-left: 0;
  }

  /*-------------*/
}

/* agreement-readbox */
.pd_agreement-readbox {
  margin-bottom: -90px;
  margin-top: 50px;
  padding: 50px 0 90px;
  background: #f7f7f7;
}

.pd_agreement-readbox__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_agreement-readbox__description {
  margin-bottom: 20px;
  text-align: center;
}

.pd_agreement-readbox__text {
  max-height: 335px;
  margin-bottom: 40px;
  padding: 20px;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.pd_check-agreement-wrap {
  text-align: center;
  margin-bottom: 40px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_agreement-readbox {
    margin-bottom: 0px;
    padding: 50px 0 40px;
  }

  .pd_agreement-readbox__text {
    padding: 20px 15px;
    margin-bottom: 30px;
  }

  .pd_check-agreement-wrap {
    text-align: left;
    margin: 0 20px 20px;
  }

  /*-------------*/
}

/* END agreement-readbox */
/* no-continue-modal__total-value */
.pd_no-continue-modal__total-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: -20px 0 40px;
  line-height: 1.2;
}

.pd_no-continue-modal__total-value dt {
  font-size: 1.8rem;
  margin: 0 10px;
}

.pd_no-continue-modal__total-value dd {
  font-size: 1.6rem;
  line-height: 1;
  margin: 0 10px;
}

.pd_no-continue-modal__total-value dd strong {
  font-size: 2.8rem;
  font-weight: bold;
}

.pd_no-continue-modal__total-value dd.pd_no-continue-modal__total-value__atten {
  font-size: 1.4rem;
  color: #d00000;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_no-continue-modal__total-value {
    margin: -20px 0 25px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_no-continue-modal__total-value dt {
    margin: 0 20px 0 0;
  }

  .pd_no-continue-modal__total-value dd {
    margin: 0px;
  }

  .pd_no-continue-modal__total-value dd.pd_no-continue-modal__total-value__atten {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  /*-------------*/
}

/* END no-continue-modal__total-value */
/* continue-plan-list */
.pd_continue-plan-list>li {
  border: 1px solid #e5e5e5;
  background: #fff;
  margin-bottom: 20px;
}

.pd_continue-plan-list>li a {
  display: block;
  color: #000;
  text-decoration: none !important;
  padding: 20px 20px 15px 20px;
}

.pd_continue-plan-list>li a dl dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.pd_continue-plan-list>li a dl dd p {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.pd_continue-plan-list>li a dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_continue-plan-list>li a dl dd ul li {
  font-size: 1.6rem;
  margin-left: 45px;
  text-align: right;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_continue-plan-list>li a dl dd ul {
    display: block;
  }

  .pd_continue-plan-list>li a dl dd ul li {
    margin-left: 0px;
  }

  /*-------------*/
}

/* END continue-plan-list */
/* 04.注文履歴一覧
----------------------------------------------------------- */
.pd_order_history {
  margin-top: 20px;
  margin-bottom: 60px;
}

.pd_order_history__item:nth-child(n + 2) {
  margin-top: 20px;
}

.pd_order_history__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 21px 20px;
  border: 1px solid #e5e5e5;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top {
    padding: 20px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.pd_order_history__top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left {
    display: block;
  }
}

.pd_order_history__top_left .pd_shipment_status,
.pd_order_history__top_left .pd_shipment_status--red,
.pd_order_history__top_left .pd_shipment_status--green,
.pd_order_history__top_left .pd_shipment_status--blue,
.pd_order_history__top_left .pd_shipment_status--gray {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_contract-info__status.pd_shipment_status--red,
.pd_order_history__top_left .pd_shipment_status--red {
  color: #d00000;
}

.pd_contract-info__status.pd_shipment_status--green,
.pd_order_history__top_left .pd_shipment_status--green {
  color: #158900;
}

.pd_contract-info__status.pd_shipment_status--blue,
.pd_order_history__top_left .pd_shipment_status--blue {
  color: #003da5;
}

.pd_contract-info__status.pd_shipment_status--gray,
.pd_order_history__top_left .pd_shipment_status--gray {
  color: #666;
}

.pd_order_history__top_left .pd_order_date {
  margin-left: 40px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left .pd_order_date {
    margin-left: 0;
  }
}

.pd_order_history__top_left .pd_order_number {
  margin-left: 40px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left .pd_order_number {
    margin-left: 0;
  }
}

.pd_order_history__content {
  padding: 21px 20px;
  border: 1px solid #e5e5e5;
  border-top: transparent;
}

.pd_order_history__content_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top {
    display: block;
  }
}

.pd_order_history__content_top+.pd_order_history__content_top {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.pd_order_history__content_top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left {
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}

.pd_order_history__content_top_left_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left_sub {
    display: block;
    padding: 5px 0;
  }
}

.pd_order_history__content_top_left_img {
  min-width: 140px;
}

.pd_order_history__content_top_left_name {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left_name {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left_price {
    margin-top: 5px;
  }
}

.pd_order_history__content_top_left_price .pd_fs_large {
  font-size: 1.4rem;
}

.pd_order_history__content_top_right .pd_btn-horizontal {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_right .pd_btn-horizontal {
    margin-top: 15px;
    margin-left: 0;
    text-align: right;
  }
}

.pd_order_history__content_top_right .pd_btn-horizontal .pd_btn {
  min-width: 170px;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_order_history__content_bottom {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_bottom_return_period {
    margin-bottom: 15px;
  }
}

.pd_order_history__content_bottom_payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_bottom_payment {
    display: block;
  }
}

.pd_order_history__content_bottom_payment .pd_not_payment {
  display: inline-block;
  margin-right: 10px;
  color: #d00000;
}

.pd_order_history__content_bottom_payment .pd_fs_large {
  font-size: 1.8rem;
}

/* 05.注文内容
----------------------------------------------------------- */
/* orderdetail__contents */
.pd_orderdetail__contents .pd_cartproduct__addoptionitem_dt span {
  padding-left: 1em;
}

.pd_orderdetail__contents .pd_cartproduct__price_withTax:after {
  color: #000000;
  content: "★";
}

.pd_orderdetail__contents .pd_orderdetail-otherinfo {
  border-top: 1px solid #e5e5e5;
}

.pd_orderdetail__contents .pd_orderdetail_value__total_summary {
  max-width: none;
}

.pd_orderdetail__contents .pd_orderdetail_value__total_inner {
  width: auto;
}

.pd_orderdetail__contents .pd_cartproduct__quantity_and_price {
  padding-top: 10px;
}

.pd_orderdetail__contents .pd_orderdetail_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.pd_orderdetail__contents .pd_orderdetail_info td a {
  margin-left: -5px;
  color: #1876e9;
}

.pd_orderdetail__contents .pd_orderdetail_info p {
  line-height: 2;
}

.pd_orderdetail__contents .pd_remarks {
  margin-top: 10px;
}

.pd_orderdetail__contents .pd_remarks span {
  margin-left: auto;
}

.pd_orderdetail__contents .pd_contract-otherinfo-user {
  border-bottom: 1px solid #e5e5e5;
}

.pd_orderdetail__contents .pd_cartproduct {
  width: 100%;
}

.pd_orderdetail__contents .pd_cartproduct__top {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 140px 1fr;
  grid-template-rows: auto auto;
  -ms-grid-columns: 140px 1fr;
  -ms-grid-rows: auto auto;
}

.pd_orderdetail__contents .pd_cartproduct__img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
}

.pd_orderdetail__contents .pd_cartproduct__set {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__set_addblock {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  width: 100%;
  padding-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__addoption {
  margin-top: 0;
  margin-bottom: 0;
}

.pd_orderdetail__contents .pd_cartproduct__price .pd_t-col02,
.pd_orderdetail__contents .pd_cartproduct__price--red .pd_t-col02,
.pd_orderdetail__contents .pd_cartproduct__price--reset_line .pd_t-col02 {
  color: #000;
}

.pd_orderdetail__contents .pd_cartproduct__price.pd_bold,
.pd_orderdetail__contents .pd_bold.pd_cartproduct__price--red,
.pd_orderdetail__contents .pd_bold.pd_cartproduct__price--reset_line {
  margin-bottom: 0;
  margin-top: -4px;
  font-size: 1.8rem;
}

.pd_orderdetail__contents .pd_cartproduct__price.pd_bold span,
.pd_orderdetail__contents .pd_bold.pd_cartproduct__price--red span,
.pd_orderdetail__contents .pd_bold.pd_cartproduct__price--reset_line span {
  font-size: 1.4rem;
}

.pd_orderdetail__contents .pd_cartproduct__bottom {
  margin-top: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem {
  margin-top: 10px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_btn {
  padding: 12px 43px;
  font-size: 1.4rem;
  line-height: 1.2;
  min-width: unset;
}

.pd_orderdetail__contents .pd_cartproduct__set_addblock_li span {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/icon_settings_blue.svg) left 50% no-repeat;
  background-size: 14px;
  color: #1876e9;
  margin-left: 5px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem span {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/icon_buy_later.svg) left 50% no-repeat;
  background-size: 14px;
  color: #1876e9;
  margin-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-review {
  background: url(/store/images/common/icon_pen_blue.svg) 1px 50% no-repeat;
  background-size: 12px 12px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-repair {
  background: url(/store/images/common/icon_settings_blue.svg) 0 50% no-repeat;
  background-size: 14px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-contact {
  background: url(/store/images/common/icon_message_blue.svg) 0 50% no-repeat;
  background-size: 15.5px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-receipt {
  background: url(/store/images/common/icon_printer_blue.svg) 0 50% no-repeat;
  background-size: 14px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-return {
  background: url(/store/images/common/icon_return_blue.svg) 0 50% no-repeat;
  background-size: 14px auto;
}

.pd_orderdetail_value {
  background: #f7f7f7;
  padding: 30px 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.pd_orderdetail_value__val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 31%;
  height: 100%;
}

.pd_orderdetail_value__val dt,
.pd_orderdetail_value__val dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem;
}

.pd_orderdetail_value__val dt:not(:nth-of-type(1)),
.pd_orderdetail_value__val dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_orderdetail_value__val dt strong,
.pd_orderdetail_value__val dd strong {
  font-size: 1.8rem;
}

.pd_orderdetail_value__val dt span,
.pd_orderdetail_value__val dd span {
  font-size: 1.4rem;
}

.pd_orderdetail_value__val dt a,
.pd_orderdetail_value__val dd a {
  color: #003da5;
}

.pd_orderdetail_value__val dt a:hover,
.pd_orderdetail_value__val dd a:hover {
  text-decoration: none;
}

.pd_orderdetail_value__val dt {
  text-align: left;
  width: 55%;
}

.pd_orderdetail_value__val dd {
  text-align: right;
  width: 45%;
}

.pd_orderdetail_value__val:not(:nth-of-type(1)) {
  margin-left: 120px;
}

.pd_orderdetail_value__total {
  border-top: 1px solid #dedede;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_orderdetail_value__total_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 60%;
}

.pd_orderdetail_value__total-val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pd_orderdetail_value__total-val dt {
  font-size: 1.8rem;
  font-weight: normal;
  margin-right: 40px;
}

.pd_orderdetail_value__total-val dt span {
  font-size: 1.4rem;
}

.pd_orderdetail_value__total-val dd {
  line-height: 1.3;
}

.pd_orderdetail_value__total-val dd strong {
  font-size: 2.8rem;
  font-weight: bold;
}

.pd_orderdetail_value__total-val dd span {
  font-size: 1.6rem;
}

.pd_orderdetail_value__total-val dd em {
  font-size: 1.4rem;
  font-weight: normal;
  padding-left: 10px;
}

.pd_orderdetail_value__total_summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 30%;
  height: 100%;
  margin-left: 2em;
}

.pd_orderdetail_value__total_summary dt,
.pd_orderdetail_value__total_summary dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)),
.pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_orderdetail_value__total_summary dt {
  text-align: left;
}

.pd_orderdetail_value__total_summary dt::before {
  content: "[";
  padding-right: 0.4rem;
}

.pd_orderdetail_value__total_summary dd {
  text-align: right;
}

.pd_orderdetail_value__total_summary dd:after {
  content: "]";
  padding-left: 0.4rem;
}

.pd_orderdetail_value__total_misc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 30%;
}

.pd_orderdetail_value__total_misc_main {
  background: #fff;
  line-height: 27px;
  display: inline-block;
  font-size: 1.4rem;
  -ms-flex-preferred-size: 217px;
  flex-basis: 217px;

  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;

  text-align: center;
  color: #d00000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.pd_orderdetail_value__total_misc_main span {
  padding-left: 15px;
}

.pd_orderdetail_value__total_misc_sub {
  font-size: 1.2rem;
  padding-top: 2px;
}

.pd_orderdetail-otherinfo_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item_dist {
  width: 50%;
  margin-bottom: 35px;
  border: 1px solid #e5e5e5;
  padding: 20px;
}

.pd_contract-otherinfo__item_dist dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item_dist dt,
.pd_contract-otherinfo__item_dist dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.pd_contract-otherinfo__item_dist dt:not(:nth-of-type(1)),
.pd_contract-otherinfo__item_dist dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_contract-otherinfo__item_dist dt {
  width: 40%;
}

.pd_contract-otherinfo__item_dist dd {
  width: 60%;
  padding-left: 1rem;
  text-indent: -1rem;
}

.pd_contract-otherinfo__item_dist dd:before {
  content: ":";
  padding-right: 1rem;
}

.pd_contract-otherinfo__item_dist dd a {
  color: #1876e9;
}

.pd_orderdetail_value.short {
  padding: 30px 20px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total,
.pd_orderdetail_value.short .pd_orderdetail_value__val,
.pd_orderdetail_value.short dt,
.pd_orderdetail_value.short dd {
  display: inline-block;
  width: auto;
  max-width: none;
  margin: 0;
}

.pd_orderdetail_value.short dd {
  margin-left: 30px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__val:not(:nth-of-type(1)) {
  margin-left: 50px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total {
  width: 100%;
  margin-top: 20px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total_summary {
  margin-left: 2rem;
  height: auto;
  max-width: none;
  display: inline-block;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total_summary:not(:nth-of-type(2)) {
  margin-left: 0;
}

@media screen and (min-width: 641px) {
  .pd_orderdetail_value__total_summary dd {
    padding-left: 1rem;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val {
    display: inline-block;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt,
  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd {
    display: inline-block;
    width: auto;
    max-width: none;
    margin: 0;
  }
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_orderdetail__contents .pd_contract-info__link {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_orderdetail__contents .pd_contract-info__link li {
    margin-left: 19px;
  }

  .pd_orderdetail__contents .pd_cartproduct__price_withTax {
    display: block;
  }

  .pd_orderdetail__contents .pd_cartproduct__top {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 140px 1fr;
    grid-template-rows: auto auto;
    -ms-grid-columns: 140px 1fr;
    -ms-grid-rows: auto auto;
  }

  .pd_orderdetail__contents .pd_cartproduct__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    padding-right: 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__set {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    padding-left: 20px;
  }

  .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price,
  .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price--red,
  .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price--reset_line {
    font-weight: normal;
  }

  .pd_orderdetail__contents .pd_cartproduct__set_addblock {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-colomn-span: 2;
    -ms-grid-row: 2;
    width: 100%;
    padding-left: 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__price,
  .pd_orderdetail__contents .pd_cartproduct__price--red,
  .pd_orderdetail__contents .pd_cartproduct__price--reset_line {
    margin-top: 20px;
  }

  .pd_orderdetail__contents .pd_contract-otherinfo__item table th,
  .pd_orderdetail__contents .pd_contract-otherinfo__item table td {
    white-space: nowrap;
  }

  .pd_orderdetail__contents .pd_contract-info {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .pd_orderdetail__contents .pd_orderdetail_value__total_inner {
    width: 100%;
  }

  .pd_orderdetail__contents .pd_remarks span {
    font-size: 1.2rem;
  }

  .pd_orderdetail__contents .pd_orderdetail_info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 30px 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__img {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__set_name {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price,
  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price--red,
  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price--reset_line {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    margin-left: 20px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choicelist {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-right: 10px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    -ms-grid-columns: 1fr 106px;
    grid-template-columns: 1fr 106px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) a {
    text-align: right;
    padding: 10px 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(1) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(2) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(3) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price,
  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price--red,
  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price--reset_line {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pd_orderdetail_value {
    padding: 25px 20px 30px;
  }

  .pd_orderdetail_value__val {
    width: 100%;
  }

  .pd_orderdetail_value__val dt,
  .pd_orderdetail_value__val dd {
    margin-top: 5px;
  }

  .pd_orderdetail_value__val dt strong,
  .pd_orderdetail_value__val dd strong {
    font-size: 1.6rem;
  }

  .pd_orderdetail_value__val dt {
    width: 50%;
  }

  .pd_orderdetail_value__val dd {
    width: 50%;
  }

  .pd_orderdetail_value__val:not(:nth-of-type(1)) {
    margin-left: 0;
  }

  .pd_orderdetail_value__val dt:not(:nth-of-type(1)),
  .pd_orderdetail_value__val dd:not(:nth-of-type(1)) {
    margin-top: 5px;
  }

  .pd_orderdetail_value__total_inner {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pd_orderdetail_value__total_summary_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pd_orderdetail_value__total-val {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .pd_orderdetail_value__total-val dt {
    margin: 0 0 10px 0;
  }

  .pd_orderdetail_value__total {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pd_orderdetail_value__total_summary_wrap {
    margin-top: 10px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary,
  .pd_orderdetail_value__total_summary {
    max-width: none;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 5px;
    margin-left: 0;
    text-align: right;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)),
  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)),
  .pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)),
  .pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)) {
    margin-top: 0px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt,
  .pd_orderdetail_value__total_summary dt {
    min-width: 30vw;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd,
  .pd_orderdetail_value__total_summary dd {
    width: auto;
  }

  .pd_orderdetail_value__total_misc {
    margin-top: 25px;
    width: 100%;
  }

  .pd_orderdetail_value__total_misc_sub {
    margin-top: 7px;
  }

  .pd_contract-otherinfo__item_dist {
    width: 100%;
    padding: 20px 15px;
  }

  .pd_contract-otherinfo__item_dist dt,
  .pd_contract-otherinfo__item_dist dd {
    width: 50%;
  }

  .pd_contract-otherinfo__item_dist dd a {
    word-break: break-all;
  }

  .pd_contract-otherinfo__item_dist dd:before {
    padding-right: 5px;
  }

  .pd_orderdetail_value.short dd {
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val:not(:nth-of-type(1)) {
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val:nth-of-type(2) {
    margin-top: 5px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val dt {
    width: 60%;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val dd {
    width: 40%;
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    -ms-flex-direction: initial;
    flex-direction: initial;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val dd {
    padding-left: 1em;
  }

  /*-------------*/
}

/* END orderdetail__contents */
/* **.TITLE
----------------------------------------------------------- */
/* マイページ系(クーポン・解約・配送先・クレジットカード・返品など) */
/*
    File Name   : mypage02.scss
    Description : マイページ系(クーポン・解約・配送先・クレジットカード・返品など) 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* クーポン情報
----------------------------------------------------------- */
.pd_coupon__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px 110px;
}

@media screen and (max-width: 640px) {
  .pd_coupon__list {
    margin: 0 20px 70px;
  }
}

.pd_coupon__list>li {
  max-width: 312px;
  margin: 0 10px;
}

@media screen and (min-width: 641px) {
  .pd_coupon__list>li:nth-last-child(-n + 3) {
    margin-top: 65px;
  }
}

@media screen and (max-width: 640px) {
  .pd_coupon__list>li {
    max-width: 100%;
    margin: 0 0 50px;
  }

  .pd_coupon__list>li:last-child {
    margin-bottom: 0;
  }
}

.pd_coupon__list .pd_btn {
  width: 100%;
  padding: 7px 10px;
  background: #000000;
  color: #fff;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_coupon__list .pd_btn {
    font-size: 1.2rem;
  }
}

.pd_coupon__list .pd_btn+.pd_btn {
  margin-top: 15px;
}

.pd_coupon__list .pd_btn-coupon {
  position: relative;
  background: #f7f7f7;
  border: #f7f7f7;
  color: #000000;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_coupon__list .pd_btn-coupon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.pd_coupon__list .pd_btn-coupon:hover {
  color: transparent;
  background: #d8d8d8;
}

.pd_coupon__list .pd_btn-coupon:hover .pd_copy-coupon {
  color: transparent;
}

@media screen and (max-width: 640px) {
  .pd_coupon__list .pd_btn-coupon .pd_sp-view {
    display: inline-block !important;
  }
}

.pd_coupon__list .pd_copy-coupon {
  background: none;
  border: 0;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_coupon__list .pd_copy-coupon {
    font-size: 1.2rem;
  }
}

.pd_coupon__list .pd_coupon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 35px;
  color: #000000;
  text-align: center;
}

.pd_coupon__list__head {
  position: relative;
}

.pd_coupon__list__head img {
  width: 100%;
  vertical-align: bottom;
}

.pd_coupon__attention {
  position: absolute;
  top: -17px;
  right: 0;
  left: 0;
  width: 137px;
  margin: auto;
  padding: 4px 10px;
  border: 2px solid #d00000;
  border-radius: 500px;
  background: #fff;
  color: #d00000;
  font-weight: bold;
}

.pd_coupon__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 11px 10px;
  background: #fff;
  border-radius: 34px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_coupon__lead {
  color: #fff;
}

.pd_coupon__list__discount {
  position: relative;
  background: #0095b5;
  background-image: linear-gradient(to right,
      #fff,
      #fff 3px,
      transparent 3px,
      transparent 8px);
  background-size: 5px 1px;
  background-repeat: repeat-x;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.pd_coupon__list__discount span {
  font-size: 2.6rem;
}

.pd_coupon__list__discount:before,
.pd_coupon__list__discount:after {
  position: absolute;
  top: -4px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  content: "";
}

.pd_coupon__list__discount:before {
  left: -4px;
}

.pd_coupon__list__discount:after {
  right: -4px;
}

.pd_coupon__date {
  margin-bottom: 10px;
  padding: 13px 0 10px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_coupon__expirationDate {
  display: block;
  margin-bottom: 3px;
}

.pd_coupon__text {
  margin-bottom: 15px;
}

.pd_coupon__available {
  margin-bottom: 15px;
  color: #666666;
}

/* 解約申請
----------------------------------------------------------- */
.pd_cancellation__contents .pd_newLine-sp th {
  padding-top: 10px;
  vertical-align: top;
}

.pd_cancellation__contents .pd_table-style01 th .pd_is-require--right::after {
  margin-left: 10px;
}

.pd_cancellation__contents .pd_selectWrap-contractorInfo {
  width: 390px;
}

@media screen and (max-width: 640px) {
  .pd_cancellation__contents .pd_selectWrap-contractorInfo {
    width: 200%;
  }
}

.pd_cancellation__contents .pd_select-type01 {
  width: 100%;
}

.pd_aboutCancel {
  margin-bottom: 40px;
  margin-top: 50px;
  padding: 50px 0 30px;
}

.pd_aboutCancel__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_aboutCancel__description {
  margin-bottom: 20px;
  text-align: center;
}

.pd_aboutCancel__text {
  max-height: 335px;
  margin-bottom: 20px;
  padding: 20px;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_aboutCancel__text {
    padding: 20px 15px;
  }
}

.pd_aboutCancel__terms {
  text-align: right;
}

.pd_aboutCancel__terms span {
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_contractorInfo {
    padding: 0 10px;
  }
}

.pd_informationBox {
  max-width: 976px;
  margin: 0 auto 50px;
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_informationBox__title {
  margin-bottom: 17px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_informationBox__text {
  margin-bottom: 5px;
}

.pd_informationBox__subTitle {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_table-style01 {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_add_flex_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_add_flex_column td {
    padding-left: 0;
  }
}

.pd_table-style01.pd_add_linefeed .pd_newLine-sp {
  margin-top: 10px;
}

.pd_table-style01.pd_add_linefeed .pd_newLine-sp td {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_colon.pd_sp-view {
    display: inline-block !important;
  }
}

.pd_table-style01 th,
.pd_table-style01 td {
  padding: 3px 0;
  text-align: left;
}

.pd_table-style01 th {
  width: 150px;
}

@media screen and (max-width: 640px) {
  .pd_table-style01 th {
    width: 50%;
  }
}

.pd_table-style01 th span {
  display: block;
  position: relative;
}

.pd_table-style01 th span:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "：";
}

.pd_table-style01 th span.pd_is-require--right::after {
  content: "必須";
  position: static;
}

.pd_table-style01 th span.pd_is-require--right::after {
  margin-left: 15px;
}

@media screen and (max-width: 640px) {
  .pd_table-style01 th span.pd_none_colon:after {
    display: none;
  }
}

.pd_table-style01 td {
  padding-left: 5px;
}

.pd_informationBox__selecet-costom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.pd_informationBox__selecet-costom .pd_is-require--right::after {
  margin-left: 10px;
}

.pd_selectWrap-contractorInfo .select-type01 {
  width: 390px;
}

@media screen and (max-width: 640px) {
  .pd_selectWrap-contractorInfo .select-type01 {
    width: 100%;
  }
}

.pd_selectWrap-creditCard .pd_select-type01 {
  width: 90px;
}

.pd_informationBox__reason {
  width: 100%;
  height: 125px;
}

.pd_newLine-sp th {
  vertical-align: top;
}

@media screen and (max-width: 640px) {
  .pd_newLine-sp {
    display: block;
  }

  .pd_newLine-sp th,
  .pd_newLine-sp td {
    display: block;
    padding: 0;
  }

  .pd_newLine-sp th {
    width: 100%;
  }

  .pd_newLine-sp td {
    width: 200%;
    margin-top: 10px;
  }
}

.pd_cancellation__modalEl {
  display: none;
  position: fixed;
  z-index: 1005;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 976px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 40px 20px 50px;
  background: #fff;
  border-radius: 5px;
}

@media screen and (max-width: 640px) {
  .pd_cancellation__modalEl {
    width: 94.6%;
    margin: 0 auto;
  }
}

.pd_cancellation__modalEl__inner {
  position: relative;
}

/* 解約申請確認
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_cancellation-confirm__contents .pd_newLine-sp th {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200%;
  }

  .pd_cancellation-confirm__contents .pd_newLine-sp th span {
    width: 100%;
  }

  .pd_cancellation-confirm__contents .pd_newLine-sp th span:after {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .pd_cancellation-confirm__contents .pd_table-style01 th {
    width: 215px;
  }
}

/* 解約申請完了
----------------------------------------------------------- */
.pd_cancellation__completed .pd_btn-horizontal {
  margin-top: 40px;
}

.pd_cancellation__completed .pd_box {
  margin-bottom: 20px;
}

/* クレジットカード情報登録
----------------------------------------------------------- */
.pd_creditCard-register__contents .pd_input-short {
  width: 240px;
}

@media screen and (max-width: 640px) {
  .pd_creditCard-register__contents .pd_input-short {
    width: 100%;
  }
}

.pd_creditCard-register__contents .pd_input-middle {
  width: 300px;
}

@media screen and (max-width: 640px) {
  .pd_creditCard-register__contents .pd_input-middle {
    width: 100%;
  }
}

/* クレジット情報更新
----------------------------------------------------------- */
.pd_requiredText {
  margin-bottom: 15px;
}

.pd_requiredText .pd_is-require--right::after {
  margin-right: 10px;
}

.pd_table-style02 {
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_table-style02 {
    display: block;
  }

  .pd_table-style02 tbody,
  .pd_table-style02 tr,
  .pd_table-style02 th,
  .pd_table-style02 td {
    display: block;
    width: 100%;
  }
}

.pd_table-style02 tr {
  border-bottom: 1px solid #e5e5e5;
}

.pd_table-style02 th,
.pd_table-style02 td {
  padding: 25px 30px;
  text-align: left;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {

  .pd_table-style02 th,
  .pd_table-style02 td {
    display: block;
  }
}

.pd_table-style02 th {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_table-style02 th {
    padding: 15px 15px 0;
  }
}

.pd_table-style02 td {
  padding-left: 0;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_table-style02 td {
    padding: 15px 15px;
  }
}

.pd_table-style02 .pd_creditCard__expirationDate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_table-style02 .pd_creditCard__expirationDate span {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.4rem;
}

.pd_table-style02 .pd_creditCard__expirationDate span.slash {
  margin-right: 18px;
}

.pd_table-style02 .pd_is-require--right::after {
  margin-left: 10px;
}

.pd_table-style02 .pd_creditCard__list img {
  max-width: 203px;
  vertical-align: middle;
}

.pd_table-style02__caution {
  margin-left: 5px;
  color: #666666;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_table-style02__caution {
    display: block;
    margin: 5px 0 0 0;
  }
}

.pd_creditCard__table {
  margin-bottom: 40px;
}

.pd_creditCard__table th {
  width: 265px;
}

@media screen and (max-width: 640px) {
  .pd_creditCard__table th {
    width: 100%;
  }
}

.pd_creditCard__securityCord input {
  width: 90px;
}

.pd_creditCard__checkBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 70px;
}

/* クレジットカード情報登録完了
----------------------------------------------------------- */
.pd_creditCard-completed__contents .pd_content__title-col01 {
  margin-bottom: 60px;
}

.pd_creditCard-completed__contents .pd_box {
  margin-bottom: 60px;
}

/* 配送先一覧
----------------------------------------------------------- */
.pd_shippingaddress__contents .pd_searchResult__wrap .pd_btn {
  font-weight: bold;
}

.pd_shippingaddress__contents .pd_box-list-type01__head-left {
  font-weight: bold;
}

.pd_shippingaddress__contents .pd_box-list-type01__head-left span {
  font-size: 1.6rem;
}

.pd_shippingaddress__contents .pd_box-list-type01__head-left .pd_box-list-type01__head__tag {
  font-size: 1.1rem;
}

.pd_shippingaddress__contents .pd_box-list-type01__head__tag {
  margin-left: 10px;
  padding: 5px 8px;
  border-radius: 3px;
  background: #ffffff;
}

.pd_shippingaddress__contents .pd_box-list-type01__head span {
  margin-right: 5px;
}

.pd_shippingaddress__contents .pd_box-list-type01__head-active {
  color: #003da5;
}

@media screen and (max-width: 640px) {

  .pd_shippingaddress__contents .pd_searchResult__num,
  .pd_shippingaddress__contents .pd_searchResult__select {
    display: none;
  }
}

.pd_shippingaddress__contents .pd_btn {
  margin-bottom: 0;
}

.pd_box-list-type01-shippingaddress {
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .pd_box-list-type01-shippingaddress {
    margin-bottom: 30px;
  }
}

/* 配送先登録
----------------------------------------------------------- */
.pd_shippingaddress-register__contents .pd_box {
  margin-bottom: 30px;
  font-weight: bold;
}

.pd_shippingaddress-register__contents .pd_list-style01__cautionWrap span {
  display: block;
}

/* 配送先登録確認
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_shippingaddress-confirm__contents .pd_list-style01__title {
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 640px) {
  .pd_shippingaddress-confirm__contents .pd_list-style01__inner {
    margin-bottom: 10px;
  }
}

/* 配送先登録完了
----------------------------------------------------------- */
.pd_shippingaddress-completed__contents .pd_box {
  margin-bottom: 50px;
}

/* 請求情報
----------------------------------------------------------- */
.pd_box-list-type01__body-billing {
  margin: 15px 20px 0;
  padding: 0 0 5px;
}

.pd_box-list-type01__body-billing table {
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.pd_box-list-type01__body-billing td {
  text-align: right;
}

.pd_box-list-type01__body-billing td span {
  font-size: 1.2rem;
}

.pd_box-list-type01__body-billing .pd_billing-total-text {
  padding-bottom: 10px;
  font-weight: bold;
}

.pd_box-list-type01__body-billing .pd_billing-total {
  padding-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_box-list-type01__body-billing .pd_billing-total span {
  font-size: 1.4rem;
}

.pd_box-list-type01__body-billing .pd_billing-note {
  min-height: 22px;
  text-align: right;
}

.pd_box-list-type01-billing {
  margin-bottom: 50px;
}

@media screen and (max-width: 640px) {
  .pd_box-list-type01-billing {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-list-type01-billing .pd_box-list-type01__head-right {
    display: block;
  }

  .pd_box-list-type01-billing .pd_box-list-type01__head-right span {
    display: block;
  }
}

.pd_box-list-type01__state {
  font-weight: bold;
}

.pd_box-list-type01__state-before {
  color: #158900;
}

.pd_box-list-type01__state-finished {
  color: #003da5;
}

.pd_box-list-type01__state-error {
  color: #d00000;
}

@media screen and (max-width: 320px) {
  .pd_box-list-type01__state {
    display: block;
  }
}

.pd_billing-caution {
  margin: 15px 0 25px;
  text-align: right;
}

.pd_billing__contents .pd_btn {
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .pd_billing__contents .pd_searchResult__select {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .pd_box-search-billing .pd_select-type-twoway {
    padding: 0 20px 0 7px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search-billing .pd_select-type-twoway {
    margin-bottom: 10px;
  }
}

.pd_box-search-billing .pd_box-search__input-after {
  margin: 0 10px 0 5px;
}

@media screen and (max-width: 640px) {
  .pd_box-search-billing .pd_box-search__input-after {
    margin: 0 5px 0 3px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search-billing .pd_box-search__input-after {
    margin: 0 10px 10px 5px;
  }
}

.pd_box-search-billing .pd_box-search__input-between {
  margin-right: 10px;
}

@media screen and (max-width: 640px) {
  .pd_box-search-billing .pd_box-search__input-between {
    margin-right: 5px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search-billing .pd_box-search__input-between {
    margin-bottom: 10px;
  }
}

.pd_btn-wrap {
  text-align: center;
}

/* お気に入り商品一覧
----------------------------------------------------------- */
.pd_favoriteList {
  margin-bottom: 20px;
}

.pd_favoriteList .pd_btn {
  min-width: 210px;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_favoriteList_item {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 140px 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  -ms-grid-columns: 140px 1fr 1fr;
  -ms-grid-rows: auto auto 1fr;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}

.pd_favoriteList_item:first-child {
  border-top: 1px solid #e5e5e5;
}

.pd_favorite__img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  padding-right: 20px;
}

.pd_favorite__img img {
  vertical-align: bottom;
}

.pd_favorite__campaign {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  margin-bottom: 10px;
}

.pd_favorite__campaign_inner {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #d00000;
  border-radius: 3px;
  color: #d00000;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_favorite__campaign:empty {
  height: 0;
  margin-bottom: 0;
}

.pd_favorite__productName {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  font-weight: bold;
  margin-bottom: 5px;
  word-break: normal;
}

.pd_favorite__product {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 3;
  grid-row: 3;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
}

.pd_favorite__action {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pd_favorite__action .pd_btn-wrap {
  position: relative;
}

.pd_favorite__productCord,
.pd_favorite__productId {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.pd_favorite__text {
  position: relative;
  min-width: 100px;
  margin-right: 3px;
}

.pd_favorite__text:after {
  position: absolute;
  top: 3px;
  right: 0;
  content: "：";
}

.pd_favorite__productList {
  margin: 10px 0;
}

.pd_favorite__productList li {
  color: #666666;
}

.pd_add-cart {
  position: absolute;
  z-index: 1;
  top: -65px;
  right: 0;
  left: 0;
  width: 190px;
  margin: auto;
}

.pd_favorite__price {
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.pd_favorite__price-line-through {
  margin-right: 20px;
  font-size: 1.4rem;
  font-weight: normal;
  text-align: right;
  text-decoration: line-through;
}

.pd_favorite__price-line-through span {
  font-size: 1.4rem;
}

.pd_favorite__productReview {
  margin-top: 10px;
}

.pd_favorite__price-taxInclude {
  font-size: 1.8rem;
}

.pd_favorite__price-campaign {
  color: #d00000;
  font-weight: bold;
  text-align: right;
}

.pd_favorite__price-campaign:after {
  color: #000000;
  content: "※";
}

.pd_favorite__delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 20px;
}

.pd_favorite__delete .pd_icon-delete {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 5px;
}

.pd_favorite__delete a {
  color: #666666;
  font-size: 1rem;
  text-decoration: underline;
}

/* SP
----------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_favoriteList .pd_btn-wrap {
    text-align: right;
  }

  .pd_favoriteList .pd_btn {
    min-width: 170px;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 7px 12px;
  }

  .pd_favoriteList_item {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 140px 1fr;
    -ms-grid-rows: auto (auto) [3];
    grid-template-rows: auto repeat(3, auto);
    -ms-grid-columns: 140px 1fr;
    -ms-grid-rows: auto repeat(3, auto);
    padding: 20px 0;
  }

  .pd_favorite__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    padding-right: 20px;
  }

  .pd_favorite__campaign {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 2;
    margin-bottom: 10px;
  }

  .pd_favorite__campaign_inner {
    display: inline-block;
    padding: 2px 8px;
    border: 1px solid #d00000;
    border-radius: 3px;
    color: #d00000;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .pd_favorite__campaign:empty {
    height: 0;
    margin-bottom: 0;
  }

  .pd_favorite__productName {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 /3;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .pd_favorite__product {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 2;
    padding-top: 10px;
  }

  .pd_favorite__action {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4 / 5;
    -ms-grid-column: 1;
    -ms-grid-row: 4;
    -ms-grid-column-span: 2;
    padding-top: 10px;
  }
}

/* 返品申請
----------------------------------------------------------- */
.pd_return__overview {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 140px 1fr;
  -ms-grid-rows: auto auto auto 1fr;
  grid-template-rows: auto auto auto 1fr;
  -ms-grid-rowss: auto auto auto 1fr;
  -ms-grid-columns: 140px 1fr;
  padding: 20px 0;
}

.pd_return__img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  grid-row: 1 / 5;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.pd_informationBox__subTitle {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  margin-bottom: 5px;
}

.pd_return__productList_wrap {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  padding-top: 5px;
}

.pd_return__productList_wrap:empty {
  height: 0;
  padding-top: 0;
}

.pd_return__num {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3 / 4;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
}

.pd_return__priceWrap {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4 / 5;
  -ms-grid-column: 2;
  -ms-grid-row: 4;
}

.pd_informationBox__subTitle {
  font-size: 1.6rem;
  word-break: normal;
}

.pd_return__price {
  font-weight: bold;
}

.pd_return__price-line-through {
  margin-right: 20px;
  font-size: 1.4rem;
  font-weight: normal;
  text-align: right;
  text-decoration: line-through;
}

.pd_return__price-line-through span {
  font-size: 1.4rem;
}

.pd_return__price-taxInclude {
  font-size: 1.8rem;
}

.pd_return__priceWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_return__priceWrap .pd_select-type-twoway {
  width: 73px;
  margin-left: 5px;
}

@media screen and (max-width: 640px) {
  .pd_return__priceWrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    white-space: nowrap;
  }
}

@media screen and (min-width: 641px) {
  .pd_table-return .pd_newLine-sp th {
    padding-top: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_table-return .pd_newLine-sp th {
    display: inline-block;
    width: auto;
  }

  .pd_table-return .pd_newLine-sp th span:after {
    right: -20px;
  }
}

@media screen and (max-width: 640px) {
  .pd_table-return .pd_newLine-sp td {
    width: 100%;
  }
}

.pd_table-return .pd_newLine-sp .pd_is-require--right::after {
  margin-left: 10px;
}

@media screen and (min-width: 641px) {
  .pd_table-return .pd_newLine-sp .pd_select-type-twoway {
    width: 390px;
  }
}

@media screen and (max-width: 640px) {
  .pd_table-return .pd_select-type-twoway {
    width: 257px;
    font-size: 1.3rem;
  }
}

.pd_return__table {
  margin-bottom: 40px;
}

.pd_return__table th {
  width: 205px;
}

.pd_return__table .pd_input-short {
  width: 155px;
}

.pd_return__table .pd_input-middle {
  width: 305px;
}

@media screen and (max-width: 640px) {
  .pd_return__table .pd_input-middle {
    width: 100%;
  }
}

.pd_return__table .pd_select-type-twoway {
  width: 210px;
}

@media screen and (max-width: 640px) {
  .pd_return__table .pd_select-type-twoway {
    width: 100%;
  }
}

.pd_return__subTitle {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_informationBox__caution {
  display: inline-block;
  color: #d00000;
  font-size: 1.4rem;
  font-weight: normal;
}

@media screen and (min-width: 641px) {
  .pd_informationBox__caution {
    margin-left: 20px;
  }
}

@media screen and (max-width: 640px) {
  .pd_informationBox__caution {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .pd_return__position-sp {
    width: 100%;
  }
}

.pd_return__productList {
  margin-bottom: 20px;
  color: #666666;
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_return__productList {
    margin-top: 15px;
  }
}

.pd_return__productList li {
  margin-bottom: 2px;
}

.pd_return__productList li:last-child {
  margin-bottom: 0;
}

/* SP
----------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_return__contents .pd_informationBox__caution {
    padding-top: 5px;
  }

  .pd_return__overview {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 140px 1fr;
    grid-template-rows: auto auto auto auto;
    -ms-grid-columns: 140px 1fr;
    -ms-grid-rows: auto auto auto auto;
    padding: 20px 0;
  }

  .pd_return__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    padding-right: 20px;
    -ms-grid-row-span: 3;
  }

  .pd_informationBox__subTitle {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    margin-bottom: 5px;
  }

  .pd_return__productList_wrap {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }

  .pd_return__num {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  .pd_return__priceWrap {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4 / 5;
    -ms-grid-column: 2;
    -ms-grid-row: 4;
  }

  .pd_return__priceWrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-top: 10px;
  }

  .pd_return__price {
    grid-column: 1 / 3;
    -ms-grid-column: 1;
    padding-top: 20px;
    text-align: right;
  }
}

/* 返品申請確認
----------------------------------------------------------- */
.pd_return-confirm__contents .pd_return__quantity {
  display: block;
  width: 100%;
  text-align: right;
}

.pd_return-confirm__contents .pd_return__price {
  width: 100%;
}

.pd_return-confirm__table th,
.pd_return-confirm__table td {
  padding: 15px 30px;
}

.pd_return-confirm__table td {
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_return-confirm__table {
    display: table;
  }

  .pd_return-confirm__table tbody {
    display: table-row-group;
  }

  .pd_return-confirm__table tr {
    display: table-row;
  }

  .pd_return-confirm__table th,
  .pd_return-confirm__table td {
    display: table-cell;
  }

  .pd_return-confirm__table th {
    width: 175px;
    padding: 15px 30px;
  }

  .pd_return-confirm__table td {
    padding: 15px 30px 15px 0;
  }
}

/* **.TITLE
----------------------------------------------------------- */
/* マイページA・マイページB */
/*
    File Name   : mypage03.scss
    Description : マイページA・マイページB

    01.マイページA
    02.マイページB
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}


/* 01.マイページA
----------------------------------------------------------- */
.pd_mypage_A .pd_btn-horizontal .pd_btn {
  min-width: 220px;
  font-size: 1.4rem;
}

.pd_mypage_A_info__link {
  margin-left: 15px;
  color: #000;
  text-decoration: underline;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_info__link {
    display: block;
    margin-top: 8px;
    margin-left: 0;
  }
}

.pd_mypage_A_box {
  margin-top: 30px;
  padding: 32px 40px;
  background-color: #edf4ff;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box {
    padding: 18px 10px 84px;
  }
}

.pd_mypage_A_box_heading__name {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_mypage_A_box_heading__honorifics {
  margin-left: 10px;
  font-size: 1.6rem;
}

.pd_mypage_A_box_heading__ty {
  margin-left: 10px;
}

.pd_mypage_A_box_entry {
  margin-top: 12px;
}

.pd_mypage_A_box_entry__rank {
  margin-left: 10px;
  font-size: 1.2rem;
  color: #1876e9;
}

.pd_mypage_A_box__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box__flex {
    display: block;
  }
}

.pd_mypage_A_box_p_coin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 448px;
  padding: 20px;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box_p_coin {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.pd_mypage_A_box_p_coin__left_point {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box_p_coin__left_point {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_mypage_A_box_p_coin__left_point__num {
  margin-left: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #003da5;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box_p_coin__left_point__num {
    margin-left: 0;
  }
}

.pd_mypage_A_box_p_coin__left_text {
  margin-top: 5px;
  font-size: 1.2rem;
  color: #666;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box_p_coin__right {
    margin-top: 10px;
  }
}

.pd_mypage_A_box_p_coin__right .pd_btn {
  min-width: 169px;
  width: 169px;
  padding: 8px 12px;
  font-size: 1.4rem;
}

.pd_mypage_A_box_p_coin__right_text {
  display: block;
  margin-top: 10px;
  color: #1876e9;
  text-align: center;
}

.pd_mypage_A_box_adding_change {
  width: 410px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_box_adding_change {
    width: 100%;
  }
}

.pd_mypage_A_box_adding_change__dt {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: bold;
}

.pd_mypage_A_box_adding_change__dd {
  margin: 20px 25px 0 0;
}

.pd_mypage_A_box_adding_change__dd_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_mypage_A_box_adding_change__a {
  color: #1876e9;
}

.pd_mypage_A_logout__link {
  font-weight: bold;
  color: #1876e9;
  position: absolute;
  top: 36px;
  right: 40px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_logout__link {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 11px;
  }
}

.pd_mypage_A_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.pd_mypage_A_list__item {
  width: calc((100% - 20px) / 2);
  padding: 20px 30px;
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_list__item {
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_list__item:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.pd_mypage_A_list__item:nth-child(n + 3) {
  margin-top: 20px;
}

.pd_mypage_A_panel__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_mypage_A_panel__head_heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_mypage_A_panel__head .pd_btn {
  min-width: 100px;
  width: 100px;
  padding: 8px 12px;
  font-size: 1.2rem;
}

.pd_mypage_A_panel__p {
  margin-top: 20px;
}

.pd_mypage_A_panel__p a {
  text-decoration: underline;
  color: #000;
}

.pd_mypage_A_panel_sub_block {
  margin-top: 20px;
  border: 1px solid #e5e5e5;
}

.pd_mypage_A_panel_sub_block__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  background-color: #fafafa;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_panel_sub_block__top {
    display: block;
  }
}

.pd_mypage_A_panel_sub_block__top_purchase,
.pd_mypage_A_panel_sub_block__top_order {
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_panel_sub_block__top_order {
    margin-top: 5px;
  }
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_panel_sub_block__top.pd_mypage_color_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_panel_sub_block__top.pd_mypage_color_flex .pd_mypage_A_panel_sub_block__top_order {
    margin-top: 0;
  }
}

.pd_mypage_A_panel_sub_block__top .pd_mypage_color,
.pd_mypage_A_panel_sub_block__top .pd_mypage_color--green,
.pd_mypage_A_panel_sub_block__top .pd_mypage_color--black {
  font-size: 1.4rem;
}

.pd_mypage_A_panel_sub_block__top .pd_mypage_color--green {
  color: #158900;
}

.pd_mypage_A_panel_sub_block__top .pd_mypage_color--black {
  color: #222;
}

.pd_mypage_A_panel_sub_block__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 7px;
  border-top: 1px solid #e5e5e5;
}

.pd_mypage_A_panel_sub_block__bottom_img {
  width: 73px;
  margin-right: 20px;
  border: 1px solid #e5e5e5;
  font-size: 0;
}

.pd_mypage_A_panel_sub_block__bottom_name {
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_mypage_A_panel_sub_block__bottom_name .pd_fs10 {
  font-size: 1rem;
}

.pd_mypage_A_favorite_list {
  margin-top: 50px;
}

.pd_mypage_A_favorite_list__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_mypage_A_favorite_list .pd_product_history__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_favorite_list .pd_product_history__ul {
    display: block;
    margin: 20px -10px 0;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_favorite_list .pd_product_history__ul .slick-arrow {
    background-color: #f7f7f7;
  }
}

.pd_mypage_A_favorite_list .pd_product_history__li {
  width: calc((100% - 80px) / 5);
  padding: 0;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_favorite_list .pd_product_history__li {
    padding: 0 10px;
  }
}

.pd_mypage_A_favorite_list .pd_product_history__li:nth-child(n + 2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_favorite_list .pd_product_history__li:nth-child(n + 2) {
    width: 100%;
    margin-left: 0;
  }
}

.pd_mypage_A_favorite_list .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd_mypage_A_favorite_list .pd_favorite__productReview .pd_icon-star img {
  display: inline-block;
}

.pd_mypage_A_coupon {
  margin-top: 70px;
}

.pd_mypage_A_coupon__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_mypage_A_coupon .pd_coupon__list {
  margin-top: 20px;
  margin-bottom: 0;
}

.pd_mypage_A_coupon .pd_coupon__list li {
  margin-top: 0;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_coupon .pd_coupon__list li {
    margin-bottom: 30px;
  }
}

.pd_mypage_A_coupon .pd_coupon__list li:last-child {
  margin-bottom: 0;
}

.pd_mypage_A_coupon .pd_btn-horizontal {
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_coupon .pd_btn-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_mypage_A_coupon .pd_btn-horizontal .pd_mypage_A_coupon__howto {
  display: inline-block;
  width: 100px;
  height: 18px;
  margin: auto;
  font-size: 1.2rem;
  color: #003da5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -385px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_A_coupon .pd_btn-horizontal .pd_mypage_A_coupon__howto {
    margin-top: 20px;
    position: static;
  }
}

.pd_mypage_A_news {
  margin-top: 60px;
}

.pd_mypage_A_news__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_mypage_A_news .pd_news-list {
  margin-top: 20px;
}

/* 02.マイページB
----------------------------------------------------------- */
.pd_mypage_B_box {
  margin-top: 30px;
  padding: 32px 40px;
  background-color: #edf4ff;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_box {
    padding: 18px 10px 62px;
  }
}

.pd_mypage_B_box__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_box__flex {
    display: block;
  }
}

.pd_mypage_B_logout__link {
  font-weight: bold;
  color: #1876e9;
  position: absolute;
  bottom: 40px;
  left: 30px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_logout__link {
    bottom: 20px;
  }
}

.pd_mypage_B_various {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_various {
    display: block;
  }
}

.pd_mypage_B_various_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 378px;
  margin-top: 33px;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_various_btn {
    width: 100%;
  }
}

.pd_mypage_B_various_btn .pd_btn {
  min-width: 169px;
  width: 169px;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_various_btn .pd_btn {
    width: 100%;
    min-width: auto;
  }
}

.pd_mypage_B_various_btn__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_various_btn__left {
    width: 48.5%;
  }
}

.pd_mypage_B_various_btn__left_text {
  margin-top: 10px;
  color: #1876e9;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .pd_mypage_B_various_btn__right {
    width: 48.5%;
  }
}

.pd_only_your_B_modal .pd_modal-header__ttl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal .pd_modal-header__ttl {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_only_your_B_modal .pd_modal-header__ttl .pd_only_your_B_modal__link {
  margin-left: 20px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #1876e9;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal .pd_modal-header__ttl .pd_only_your_B_modal__link {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_only_your_B_modal .pd_modal-header {
  padding: 35px 40px 0 40px;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal .pd_modal-header {
    padding: 35px 20px 0 20px;
  }
}


.pd_only_your_B_modal__list {
  margin-top: 20px;
}

.pd_only_your_B_modal__item {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 170px 1fr;
  grid-template-rows: auto auto;
  -ms-grid-rows: auto auto;
  -ms-grid-columns: 170px 1fr;
  padding: 20px;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item {
    grid-template-columns: 90px 1fr;
    -ms-grid-columns: 90px 1fr;
  }
}

.pd_only_your_B_modal__item+.pd_only_your_B_modal__item {
  margin-top: 10px;
}

.pd_only_your_B_modal__item_img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  font-size: 0;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
  }
}

.pd_only_your_B_modal__item_right_heading {
  -ms-grid-column: 2;
  grid-column: 2 / 2;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  padding-left: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_right_heading {
    grid-column: 2 / 3;
    -ms-grid-column: 1;
  }
}

.pd_only_your_B_modal__item_right_p {
  -ms-grid-column: 2;
  grid-column: 2 / 2;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  padding-left: 20px;
  margin-top: 20px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_right_p {
    grid-column: 1 / 3;
    -ms-grid-column: 1;
    padding-left: 0;
  }
}

/* お知らせ一覧 */
/*
    File Name   : news.scss
    Description : お知らせ一覧用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_tabWrap {
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .pd_tabWrap {
    min-height: 66px;
    margin: 0 -10px 30px;
  }
}

.pd_tab-border {
  position: relative;
}

.pd_tab-border:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  content: "";
}

@media screen and (max-width: 640px) {
  .pd_tab-border:after {
    bottom: 6px;
    left: -10px;
    width: calc(100% + 20px);
  }
}

.pd_tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 6px;
}

.pd_tab>li {
  position: relative;
  z-index: 1;
  padding: 10px 38px;
  font-size: 1.6rem;
  white-space: nowrap;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_tab>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 60px;
    padding: 10px;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.28;
  }
}

.pd_tab>li.pd_is-active {
  border-bottom: 5px solid #000000;
  font-weight: bold;
  cursor: auto;
}

.pd_tab>li.pd_is-active:after {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-top: 5px solid #000000;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
}

@media screen and (max-width: 640px) {
  .pd_tab-all {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .pd_tab-message {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }

  .pd_tab-product {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .pd_tab-maintenance {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
  }
}

.pd_tab-content {
  margin-bottom: 40px;
}

.pd_news-list>li {
  padding: 20px 0;
  border-bottom: 1px dashed #cccccc;
}

.pd_news-list>li:first-child {
  border-top: 1px dashed #cccccc;
}

.pd_news-list__date {
  margin-bottom: 10px;
  font-weight: bold;
}

.pd_news-list__tag {
  margin-left: 15px;
}

.pd_news-list__tag-caution {
  color: #d00000;
}

.pd_news-list__tag-product {
  color: #003da5;
}

.pd_news-list__tag-new {
  margin-left: 5px;
  color: #d00000;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}

.pd_news-list__tag-maintenance {
  color: #666;
}

.pd_news-list__text {
  position: relative;
  font-size: 1.6rem;
}

.pd_news-list__text a {
  color: #000000;
  text-decoration: underline;
}

.pd_tab-content__inner {
  display: none;
}

.pd_tab-content__inner.pd_is-active {
  display: block;
}

/* **.TITLE
----------------------------------------------------------- */
/* カート */
/*
    File Name   : cart.scss
    Description : カート 用

    01.カート
    02.お届け先指定
    03.お届け先指定（ゲスト）
    04.お支払い方法選択
    05.注文内容確認
    06.注文完了
    07.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.カート
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_cartflow {
    padding-bottom: 15px;
    /* overflow-x: scroll; */
  }
}

.pd_cartflow__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pd_cartflow__li {
  width: 25%;
  text-align: center;
}

.pd_cartflow__li span {
  display: block;
}

.pd_cartflow__li span img {
  height: 18px;
  width: 50px;
}

.pd_cartflow__name {
  font-weight: bold;
}

.pd_cartflow__statusbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  height: 12px;
  border-radius: 20px;
  background-color: #ddd;
  position: relative;
  z-index: 0;
}

.pd_cartflow__statusbar::before {
  content: "";
  display: block;
  height: 12px;
  margin: auto;
  border-radius: 20px;
  background-color: #edf4ff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.pd_cartflow__statusbar.pd_step2::before {
  width: calc((100% / 5) * 2);
}

.pd_cartflow__statusbar.pd_step3::before {
  width: calc((100% / 5) * 3);
}

.pd_cartflow__statusbar.pd_step4::before {
  width: calc((100% / 5) * 4);
}

.pd_cartflow__statusbar.pd_step5::before {
  width: calc((100% / 5) * 5);
}

.pd_cartflow__statusbar_li {
  width: 25%;
  display: block;
  height: 12px;
  border-radius: 20px;
  position: relative;
  z-index: 2;
}

.pd_cartflow__statusbar_li.pd_is-active {
  background-color: #003da5;
}

.pd_cartbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 40px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_cartbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_cartbox__left {
  width: 620px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox__left {
    width: 100%;
  }
}

.pd_cartbox__right {
  width: 316px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox__right {
    width: 100%;
    margin-top: 40px;
  }
}

.pd_cartbox .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_cartproduct__li {
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_cartproduct__li:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_cartproduct__top {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 140px 1fr;
  grid-template-rows: auto auto 1fr;
  -ms-grid-columns: 140px 1fr;
  -ms-grid-rows: auto auto 1fr;
  margin-bottom: 14px;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__top {
    grid-template-columns: 140px 1fr;
    grid-template-rows: auto repeat(3, auto);
    margin-bottom: 0;
  }
}

.pd_cartproduct__img {
  min-width: 140px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
}

.pd_cartproduct__img img {
  vertical-align: bottom;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    padding-right: 20px;
  }
}

.pd_cartproduct__set {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__set {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 /3;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.pd_cartproduct__set_campaign {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  padding-left: 20px;
  margin-bottom: 10px;
}

.pd_cartproduct__set_campaign_inner {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #d00000;
  border-radius: 3px;
  color: #d00000;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__set_campaign {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 2;
    margin-bottom: 10px;
    padding-left: 0;
  }
}

.pd_cartproduct__set_name {
  font-weight: bold;
  word-break: normal;
}

.pd_orderdetail__contents .pd_cartproduct__set_name {
  width: calc(100% - 270px);
}

@media screen and (max-width: 640px) {
  .pd_orderdetail__contents .pd_cartproduct__set_name {
    width: inherit;
  }
}

.pd_cartproduct__set_option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}

.pd_cartproduct__set_option:last-of-type {
  margin-bottom: 10px;
}

.pd_cartproduct__set_option_dt {
  min-width: 100px;
}

.pd_cartproduct__set_option_dd {
  word-break: break-all;
}

.pd_cartproduct__set_addblock {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3 / 4;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
  width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__set_addblock {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 2;
    padding-top: 10px;
    padding-left: 0;
  }
}

.pd_cartproduct__set_addblock_li {
  font-size: 1.2rem;
  color: #666;
}

.pd_cartproduct__set_addblock_li:nth-child(n + 2) {
  margin-top: 5px;
}

.pd_cartproduct__bottom .pd_cartproduct__price,
.pd_cartproduct__bottom .pd_cartproduct__price--red,
.pd_cartproduct__bottom .pd_cartproduct__price--reset_line {
  margin-top: 0;
}

.pd_cartproduct__bottom .pd_color_black {
  color: #000;
}

.pd_cartproduct__price,
.pd_cartproduct__price--red,
.pd_cartproduct__price--reset_line {
  margin-top: 10px;
}

.pd_cartproduct__price--red {
  color: #d00000;
}

.pd_cartproduct__price--reset_line {
  text-decoration: line-through;
}

.pd_cartproduct__quantity_and_price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 3px;
}

.pd_cartproduct__quantity_and_price .pd_cartproduct__price,
.pd_cartproduct__quantity_and_price .pd_cartproduct__price--red,
.pd_cartproduct__quantity_and_price .pd_cartproduct__price--reset_line {
  margin-top: 0;
  margin-left: 25px;
}

.pd_cartproduct__quantity_and_price .pd_input-name {
  width: 65px;
}

.pd_cartproduct__quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_cartproduct__choicelist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

.pd_cartproduct__choiceitem:nth-child(n + 2) {
  margin-left: 30px;
}

.pd_cartproduct__addoption {
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #f7f7f7;
}

.pd_cartproduct__addoption_dt {
  padding-bottom: 10px;
  font-weight: bold;
}

.pd_cartproduct__addoption_dd {
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_cartproduct__addoptionitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__addoptionitem {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__addoptionitem_dd {
    margin-top: 15px;
    text-align: right;
  }
}

.pd_remarks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_remarks {
    flex-direction: column;
  }

  .pd_remarks .pd_remarks__trash,
  .pd_remarks>.pd_bold {
    width: 100%;
  }

  .pd_remarks .pd_remarks__trash {
    margin-bottom: 6px;
  }
}

.pd_remarks__trash .pd_icon-trash {
  font-size: 1.4rem;
}

.pd_recalculation {
  margin-bottom: 15px;
}

.pd_recalculation .pd_btn-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_recalculation .pd_btn-horizontal .pd_btn {
  min-width: 82px;
  margin: 0;
  padding: 8px 11px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_result {
  padding: 34px 15px;
  background-color: #f7f7f7;
}

.pd_result__top {
  margin-bottom: 20px;
  padding-bottom: 3px;
  border-bottom: 1px solid #dedede;
}

.pd_result__top__total {
  margin-bottom: 15px;
  padding-bottom: 3px;
  border-bottom: 1px dashed #dedede;
}

.pd_result__top__subtotal {}

.pd_result .pd_tax,
.pd_result .pd_yen {
  font-size: 1.4rem;
}

.pd_result .pd_taxlist {
  margin-bottom: 20px;
}

.pd_result .pd_taxlist__sum {
  font-size: 1.2rem;
  text-align: right;
}

.pd_result .pd_taxlist__sum:nth-child(n + 2) {
  margin-top: 3px;
}

.pd_result .pd_btn-horizontal.pd_procedure {
  display: block;
}

.pd_result .pd_btn-horizontal.pd_procedure .pd_btn {
  width: 100%;
  margin: 0;
  font-weight: bold;
}

.pd_result__bottom_small {
  font-size: 1.2rem;
}

.pd_productsum,
.pd_optionsum,
.pd_productsum--large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 9px;
}

.pd_productsum__name,
.pd_productsum__num,
.pd_optionsum__name,
.pd_optionsum__num {
  font-size: 1.6rem;
}

.pd_productsum__num,
.pd_optionsum__num {
  font-weight: bold;
}

.pd_productsum--large {
  margin-bottom: 10px;
}

.pd_productsum--large__name {
  margin-bottom: 3px;
  font-size: 1.7rem;
}

.pd_productsum--large__num {
  margin-left: auto;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
}

.pd_optionsum.pd_t-col02+.pd_t-col02 {
  margin-top: 10px;
}

.pd_optionsum.pd_t-col02 .pd_fs14 {
  font-size: 1.4rem;
}

.pd_optionsum.pd_t-col03+.pd_t-col03 {
  margin-top: 10px;
}

.pd_optionsum.pd_t-col03 .pd_fs14 {
  font-size: 1.4rem;
}

.pd_earnedpoints {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 7px 20px 5px;
  border-radius: 3px;
  background-color: #fff;
}

.pd_earnedpoints__name,
.pd_earnedpoints__num {
  font-size: 1.4rem;
}

.pd_icon-shippingfee {
  display: inline-block;
  padding-left: 25px;
  background: url(/store/images/common/icon_shippingfee.svg) left 50% no-repeat;
  background-size: 18px;
}

.pd_icon-trash {
  display: inline-block;
  padding: 2px 2px 2px 20px;
  min-height: 16px;
  color: #666666;
  text-decoration: underline;
  font-size: 1rem;
  background: url(/store/images/common/icon_trash.svg) 0 50% no-repeat;
  background-size: 15px 16px;
}

.pd_icon-buy-later {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/icon_buy_later.svg) left 50% no-repeat;
  background-size: 14px;
  color: #1876e9;
}

.pd_shippingfee {
  margin-top: 20px;
  padding: 15px 20px 20px;
  background-color: #edf4ff;
}

.pd_shippingfee__heading {
  font-size: 1.6rem;
  text-align: center;
}

.pd_shippingfee__dl {
  padding: 10px;
  background-color: #fff;
}

.pd_shippingfee__dl:nth-child(n + 2) {
  margin-top: 10px;
}

.pd_shippingfee__dt {
  margin-bottom: 5px;
  font-weight: bold;
}

.pd_carts-empty__contents .pd_box {
  margin-top: 40px;
}

.pd_carts-empty__contents .pd_btn-horizontal {
  margin-top: 40px;
}

/* 02.お届け先指定
----------------------------------------------------------- */
.pd_delivery_target__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_delivery_target__heading {
    display: block;
  }
}

.pd_delivery_target__heading .pd_btn {
  padding: 7px 10.5px;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_delivery_target__name {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_delivery_target__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_delivery_target__left .pd_select-type-twoway {
  width: 131px;
  margin-left: 15px;
}

@media screen and (max-width: 320px) {
  .pd_delivery_target__left .pd_select-type-twoway {
    width: 120px;
  }
}

.pd_delivery_target__left .pd_btn {
  min-width: 81px;
  width: 81px;
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_target__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.pd_delivery_target__right .pd_btn {
  min-width: 145px;
}

.pd_personal_information {
  margin-bottom: 45px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.pd_personal_information__name {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_personal_information__name .honorifics {
  font-size: 1.4rem;
}

.pd_personal_information__postal_code,
.pd_personal_information__address {
  margin-bottom: 5px;
}

.pd_cartproduct__heading {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cartproduct__delivery_method {
  margin-bottom: 15px;
}

.pd_free_shipping {
  margin-top: 10px;
  font-size: 1.4rem;
}

.pd_registration_code {
  margin-top: 60px;
}

.pd_registration_code__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_registration_code__input {
  width: 303px;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_registration_code__input {
    width: 100%;
  }
}

.pd_registration_code__input.pd_border,
.pd_registration_code__input.pd_border--input {
  width: 100%;
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_registration_code__input.pd_border--input .pd_input-type01 {
  width: 303px;
}

@media screen and (max-width: 640px) {
  .pd_registration_code__input.pd_border--input .pd_input-type01 {
    width: 100%;
  }
}

.pd_registration_code__input .pd_input-type01 {
  width: 100%;
}

.pd_registration_code__caution {
  margin-top: 10px;
  font-size: 1.4rem;
  color: #666;
}

.pd_modal.pd_cart .pd_modal-header {
  padding: 50px 40px 0;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_modal-header {
    padding: 50px 20px 0;
  }
}


.pd_modal.pd_cart .pd_list-style01__title {
  min-width: 210px;
}

.pd_modal.pd_cart .pd_list-style01__content {
  width: 100%;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
  min-width: calc(210px - 24px);
  margin-right: 24px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
    min-width: auto;
    margin-right: 0;
  }
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
  margin-left: -24px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
    margin-left: 0;
  }
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_family_name .pd_list-style01__first,
.pd_modal.pd_cart .pd_list-style01__inner.pd_family_name .pd_list-style01__second {
  min-width: 28px;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_tel {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_tel .pd_list-style01__title {
  margin-top: 6.5px;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap {
  display: block;
  width: 100%;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap .pd_list-style01__caution {
  display: block;
  margin-top: 5px;
  margin-left: 0;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap .pd_list-style01__caution:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01 .pd_btnWrap {
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_btn-address {
    padding: 10px 7px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_btn-address {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_modal.pd_cart .pd_input-name {
  width: 160px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-name {
    width: 93px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_input-name {
    width: 65px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-address {
    width: 90px;
  }
}

.pd_modal.pd_cart .pd_input-town,
.pd_modal.pd_cart .pd_input-apartment {
  width: 430px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-tel {
    width: 75px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_input-tel {
    width: 57px;
  }
}

/* 03.お届け先指定（ゲスト）
----------------------------------------------------------- */
.pd_cartbox_guest__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cart_guest .pd_list-style01__title {
  min-width: 210px;
  margin-top: 6.5px;
}

.pd_cart_guest .pd_list-style01__content {
  width: 100%;
}

.pd_cart_guest .pd_list-style01__inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pd_cart_guest .pd_list-style01__inner.pd_family_name .pd_list-style01__first,
.pd_cart_guest .pd_list-style01__inner.pd_family_name .pd_list-style01__second {
  min-width: 28px;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_list-style01 .pd_btnWrap {
    margin-left: 5px;
  }
}

.pd_cart_guest .pd_list-style01__caution {
  display: block;
  width: 100%;
  margin-top: 3px;
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_list-style01__caution-name {
    margin-left: 23px;
  }
}

.pd_cart_guest .pd_input-name {
  width: 130px;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_input-name {
    width: 100px;
  }
}

@media screen and (max-width: 320px) {
  .pd_cart_guest .pd_input-name {
    width: 84px;
  }
}

.pd_cart_guest .pd_input-city,
.pd_cart_guest .pd_input-apartment,
.pd_cart_guest .pd_input-email {
  width: 100%;
}

.pd_by_delivery_target {
  padding: 25px;
  background-color: #f7f7f7;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target {
    padding: 20px;
  }
}

.pd_by_delivery_target .pd_custom-checkbox {
  margin: 0;
}

.pd_by_delivery_target__content {
  display: none;
  margin-top: 20px;
  text-align: left;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content {
    background-color: #f7f7f7;
  }
}

.pd_by_delivery_target__content .pd_list-style01 {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 {
    border: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 li {
    padding: 10px 0 0 0;
    border-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 li:first-child {
    padding-top: 0;
  }
}

.pd_by_delivery_target__content .pd_list-style01__title {
  min-width: 190px;
}

.pd_by_delivery_target__content .pd_input-name {
  width: 115px;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_input-name {
    width: 100px;
  }
}

@media screen and (max-width: 320px) {
  .pd_by_delivery_target__content .pd_input-name {
    width: 84px;
  }
}

.pd_by_delivery_target__content .pd_input-address {
  width: 120px;
}

.pd_cart_guest_terms {
  margin-top: 60px;
}

.pd_cart_guest_terms__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cart_guest_terms__content {
  padding: 20px;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.pd_cart_guest_terms__content_p {
  text-align: left;
}

.pd_cart_guest_terms__content_overflow {
  height: 130px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  text-align: left;
  overflow-y: scroll;
}

.pd_cart_guest_terms .pd_custom-checkbox {
  margin: 20px 0 0 0;
}

/* 04.お支払い方法選択
----------------------------------------------------------- */
.pd_js-radio-switch_contnet {
  display: none;
}

.pd_cartbox_payment .pd_custom-radio {
  margin-bottom: 25px;
  font-size: 1.6rem;
}

.pd_cartbox_payment__fieldset_inr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__fieldset_inr {
    display: block;
  }
}

.pd_cartbox_payment__fieldset_inr .pd_custom-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc((100% - 61px) / 3);
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__fieldset_inr .pd_custom-radio {
    width: 100%;
    margin-right: 0;
  }
}

.pd_cartbox_payment__remarks {
  display: block;
  width: 100%;
  margin-left: 5px;
  font-size: 1.4rem;
  color: #666;
}

.pd_cartbox_payment__remarks_box {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_cartbox_payment__bg,
.pd_cartbox_payment__bg--gray,
.pd_cartbox_payment__bg--white {
  margin-top: 20px;
  padding: 20px;
}

@media screen and (max-width: 320px) {

  .pd_cartbox_payment__bg,
  .pd_cartbox_payment__bg--gray,
  .pd_cartbox_payment__bg--white {
    padding: 15px;
  }
}

.pd_cartbox_payment__bg--gray {
  background-color: #f7f7f7;
}

.pd_cartbox_payment__bg--white {
  background-color: #fff;
}

.pd_cartbox_payment__heading {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  vertical-align: middle;
}

.pd_cartbox_payment__heading_box .pd_cartbox_payment__heading {
  margin-bottom: 0;
}

.pd_cartbox_payment__input_box,
.pd_cartbox_payment__input_box--section1,
.pd_cartbox_payment__input_box--section2 {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_cartbox_payment__input_box--section1 .pd_custom-radio {
  margin-bottom: 0;
}

.pd_cartbox_payment__input_box--section1 .pd_custom-radio:nth-child(n + 4) {
  margin-top: 25px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_box--section1 .pd_custom-radio:not(:last-child) {
    margin-bottom: 25px;
  }
}

.pd_cartbox_payment__input_box--section2 {
  padding: 20px;
}

.pd_cartbox_payment__input_box--section2+.pd_cartbox_payment__input_box--section2 {
  border-top: transparent;
}

.pd_cartbox_payment__input_content .pd_list-style01 {
  margin-bottom: 0;
  border: 0;
}

.pd_cartbox_payment__input_content .pd_list-style01 li {
  padding: 0;
  border-bottom: 0;
}

.pd_cartbox_payment__input_content .pd_list-style01 li:nth-child(n + 2) {
  margin-top: 10px;
}

.pd_cartbox_payment__input_content .pd_list-style01__caution {
  margin-top: 5px;
  margin-left: 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_handlable_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_handlable_card__img {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_handlable_card__img {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner .pd_list-style01__title {
  min-width: 210px;
  width: 210px;
  margin-top: 6.5px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_card_number .pd_input-name {
  width: 295px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_card_holder .pd_input-name {
  width: 100%;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_expiration_date .pd_select-type-twoway {
  min-width: 90px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_expiration_date .pd_select-type-twoway {
    min-width: 78px;
  }
}

@media screen and (max-width: 320px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_expiration_date .pd_select-type-twoway {
    min-width: 55px;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_expiration_date .pd_span__month {
  margin: 0 10px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_expiration_date .pd_span__year {
  margin-left: 10px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_security_code .pd_input-name {
  width: 90px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_list-style01__inner.pd_credit_security_code .pd_list-style01__caution-name {
  margin-top: 0;
  margin-left: 5px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card .pd_custom-checkbox {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card .pd_custom-checkbox {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card .pd_btnWrap {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_normal_card .pd_btn {
  min-width: auto;
  padding: 8px 12px;
  border: 17px;
  font-size: 1.2rem;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout {
  background-color: #fff;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout .pd_custom-radio {
  margin: 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  text-align: center;
  background-color: #fafafa;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th,
.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 46px;
  padding: 12px 17px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

@media screen and (max-width: 640px) {

  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th,
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td {
    padding: 5px;
  }
}

@media screen and (max-width: 320px) {

  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th,
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td {
    padding: 3px;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th:first-child,
.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td:first-child {
  width: 50%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 640px) {

  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th:first-child,
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td:first-child {
    min-width: auto;
    width: 60%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th:nth-child(2),
.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td:nth-child(2) {
  width: 50%;
  border-left: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {

  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th:nth-child(2),
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td:nth-child(2) {
    min-width: auto;
    width: 40%;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th .pd_custom-checkbox,
.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td .pd_custom-checkbox {
  margin: 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th .pd_custom-control-description,
.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__td .pd_custom-control-description {
  font-size: 1.4rem;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 320px) {
  .pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__th {
    font-size: 1rem;
  }
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  text-align: center;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content+.pd_table_layout__content {
  border-top: transparent;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub {
  display: none;
  padding: 20px 15px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub .pd_list-style01 {
  margin-top: 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub .pd_list-style01__title {
  min-width: 195px;
  width: 195px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub .pd_list-style01 .pd_input-name {
  width: 94px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub .pd_list-style01__caution {
  margin-top: 0;
  margin-left: 5px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_table_layout__content_sub .pd_custom-checkbox {
  margin: 15px 0 0 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_new_credit .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_new_credit .pd_list-style01__title {
  min-width: 195px;
  width: 195px;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_new_credit .pd_normal_card,
.pd_cartbox_payment__input_content.pd_credit_payment .pd_new_credit .pd_when_clearing {
  padding: 0;
}

.pd_cartbox_payment__input_content.pd_credit_payment .pd_new_credit .pd_normal_card {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs__a {
  color: #1876e9;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs__p {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs .pd_icon-otherlink {
  background: url(/store/images/common/icon_otherlink.svg) 100% 50% no-repeat;
  background-size: 10px 10px;
  padding-right: 16px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__title {
  min-width: 160px;
  width: 160px;
  margin-top: 6.5px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__title {
    min-width: auto;
    width: 100%;
    margin-top: 0;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name {
  width: 170px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name {
    width: 100%;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name:nth-child(2) {
  margin-left: 10px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_is-require--right::after {
  margin-left: 4px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_customer_number {
  display: none;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_customer_number .pd_customer_number__p {
  margin-top: 6.5px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01 .pd_select-type-twoway {
  width: 170px;
}

.pd_cartbox_payment__input_content.pd_cod .pd_cod__p+.pd_cod__p {
  margin-top: 5px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__dt {
  min-width: 75px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__p {
  margin-top: 5px;
  margin-left: 75px;
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__p {
    margin-left: 0;
  }
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01__title {
  min-width: 188px;
  width: 188px;
  margin-top: 6.5px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_select-type-twoway {
  width: 170px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name {
  width: 170px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name {
    width: 100%;
  }
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name:nth-child(2) {
  margin-left: 10px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_semicolon {
  display: inline-block;
  margin-left: auto;
  font-weight: normal;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01 .pd_select-type-twoway {
  width: 170px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_bank_transfer .pd_semicolon {
    margin-left: 5px;
  }
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dl {
    display: block;
  }
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 160px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-width: 160px;
  width: 160px;
  margin-top: 6.5px;
}

.pd_coupon_use {
  margin-top: 50px;
}

.pd_coupon_use .pd_custom-radio {
  margin: 0;
}

.pd_coupon_use .pd_custom-radio .pd_custom-control-indicator {
  top: 0;
  margin-top: 0;
}

.pd_coupon_use__p,
.pd_coupon_use__p--gray,
.pd_coupon_use__p--red,
.pd_coupon_use__heading {
  margin-top: 20px;
  margin-left: 30px;
}

.pd_coupon_use__p--gray {
  color: #666;
}

.pd_coupon_use__p--red {
  margin-top: 10px;
  color: #d00000;
}

.pd_coupon_use__input_wrap {
  margin-top: 20px;
  margin-left: 30px;
}

.pd_coupon_use__input_wrap .pd_input-name {
  width: 300px;
}

@media screen and (max-width: 640px) {
  .pd_coupon_use__input_wrap .pd_input-name {
    width: 100%;
  }
}

.pd_coupon_use .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_coin_use {
  margin-top: 50px;
}

.pd_coin_use .pd_custom-radio {
  width: 100%;
  margin-right: 0;
}

.pd_coin_use .pd_custom-radio:last-child {
  margin-bottom: 0;
}

.pd_coin_use .pd_custom-radio .pd_custom-control-indicator {
  top: 0;
  margin-top: 0;
}

.pd_coin_use .pd_custom-radio .pd_custom-control-indicator.pd_in_input {
  top: 10%;
}

@media screen and (max-width: 640px) {
  .pd_coin_use .pd_custom-radio .pd_custom-control-indicator.pd_in_input {
    top: 0;
  }
}

.pd_coin_use .pd_input-name {
  width: 110px;
  text-align: right;
}

.pd_coin_use__caution {
  font-size: 1.4rem;
  color: #d00000;
}

.pd_coin_use .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_delivery_preferred_date {
  margin-top: 50px;
}

.pd_delivery_preferred_date .pd_input-name {
  width: 165px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_input-name {
    width: 100%;
  }
}

.pd_delivery_preferred_date .pd_select-type-twoway {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_select-type-twoway {
    margin-left: 0;
  }
}

.pd_delivery_preferred_date .pd_select-type-twoway.pd_clock {
  width: 165px;
  padding-left: 30px;
  background-image: url(/store/images/common/icon_clock.svg),
    url(/store/images/common/select_arrow_twoway.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: center left 10px, calc(100% - 11px) 50%;
  background-size: 13px, 6.4px 10.6px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_select-type-twoway.pd_clock {
    width: 49%;
  }
}

.pd_delivery_preferred_date__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date__wrap {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_cartbox_payment__input_box--section2 .pd_delivery_preferred_date__wrap .pd_clear_btn {
  width: 165px;
  min-width: 165px;
  height: 36px;
  margin-left: 10px;
  padding: 7px 25px;
  border: 1px solid #ccc;
  border-radius: 0;
  font-size: 1.3rem;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_box--section2 .pd_delivery_preferred_date__wrap .pd_clear_btn {
    width: 49%;
    min-width: auto;
    margin-left: 0;
  }
}

.pd_delivery_preferred_date .pd_btn-horizontal {
  margin-top: 50px;
}

.pd_delivery_preferred_date__info_dt {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.pd_delivery_preferred_date__info_dd {
  font-size: 1.4rem;
}

.pd_delivery_preferred_date .pd_cartproduct__top {
  margin-top: 20px;
  grid-template-columns: 100px 1fr;
  -ms-grid-columns: 100px 1fr;
}

.pd_delivery_preferred_date .pd_cartproduct__img {
  min-width: 100px;
}

.pd_delivery_preferred_date__calendar {
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date__calendar {
    width: 49%;
  }
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
  padding-left: 30px;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::-webkit-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date:-ms-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::-ms-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .ui-datepicker-trigger {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}

.ui-datepicker {
  display: none;
  width: 320px;
  padding: 20px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 10px 0px black;
  box-shadow: 0 0 10px 0px black;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .ui-datepicker {
    width: calc(100% - 50px);
    margin: 0 auto;
  }
}

.ui-datepicker *,
.ui-datepicker *::before,
.ui-datepicker *::after {
  line-height: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-datepicker-header {
  position: relative;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 2px;
  cursor: pointer;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: block;
  width: 12px;
  height: 12px;
  font-size: 0;
  border: 2px solid #1876e9;
  border-top: transparent;
  border-right: transparent;
}

.ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker-prev span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ui-datepicker-next {
  right: 2px;
}

.ui-datepicker-next span {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.ui-datepicker-title {
  padding: 0 20px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
}

.ui-datepicker-calendar {
  margin-top: 25px;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  font-size: 1.5rem;
  color: #acacac;
  text-align: center;
}

.ui-datepicker .ui-state-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  font-size: 1.5rem;
  color: #000;
  text-align: center;
}

.ui-datepicker .ui-state-default.ui-state-highlight {
  border-radius: 40px;
  background-color: #1876e9;
  color: #fff;
}

.pd_no_payment__p {
  font-size: 1.6rem;
  margin-top: -5px;
}

/* 05.注文内容確認
----------------------------------------------------------- */
.pd_order_confirmation__dl+.pd_order_confirmation__dl {
  margin-top: 50px;
}

.pd_order_confirmation__dt {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_order_confirmation__dd+.pd_order_confirmation__dd {
  margin-top: 12px;
}

.pd_delivery_target_person {
  margin-top: 50px;
}

.pd_delivery_target_person .pd_cartbox_payment__heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_delivery_target_person .pd_cartbox_payment__heading_box .pd_btn {
  margin-left: 20px;
  padding: 4px 12px;
  font-size: 1.4rem;
  font-weight: bold;
  min-width: 83px;
}

.pd_delivery_target_person .pd_delivery_preferred_date__info_dt {
  margin-bottom: 0;
}

.pd_delivery_target_person .pd_delivery_preferred_date__info_dt:nth-child(2) {
  margin-bottom: 20px;
}

.pd_ordering_product {
  margin-top: 50px;
}

.pd_ordering_product__delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .pd_ordering_product__delivery {
    display: block;
  }
}

.pd_ordering_product__delivery_method {
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_ordering_product__delivery_method {
    display: block;
  }
}

.pd_ordering_product__delivery_due_date {
  margin-left: 20px;
  padding: 7px 18px;
  color: #d00000;
  background-color: #feeeee;
}

@media screen and (max-width: 640px) {
  .pd_ordering_product__delivery_due_date {
    display: block;
    margin-top: 12px;
    margin-left: 0;
  }
}

.pd_ordering_product .pd_cartproduct__choiceitem_a {
  margin-right: 15px;
}

@media screen and (max-width: 640px) {
  .pd_ordering_product .pd_cartproduct__choiceitem_a {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: right;
  }
}

@media screen and (max-width: 640px) {
  .pd_ordering_product .pd_cartproduct__quantity {
    display: inline-block;
  }
}

@media screen and (max-width: 640px) {

  .pd_ordering_product .pd_cartproduct__price,
  .pd_ordering_product .pd_cartproduct__price--red,
  .pd_ordering_product .pd_cartproduct__price--reset_line {
    margin-left: 20px;
  }
}

.pd_ordering_product .pd_icon-return {
  display: inline-block;
  padding-left: 20px;
  color: #1876e9;
  background: url(/store/images/common/icon_return_blue.svg) 0 50% no-repeat;
  background-size: 14px auto;
}

.pd_ordering_product .pd_remarks {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* 06.注文完了
----------------------------------------------------------- */
.pd_cartbox.pd_order_complete {
  display: block;
}

.pd_order_complete__info {
  text-align: center;
}

.pd_order_complete__info_p+.pd_order_complete__info_p {
  margin-top: 20px;
}

.pd_order_complete__info_num,
.pd_order_complete__info_num--blue {
  font-size: 2.4rem;
  font-weight: bold;
}

.pd_order_complete__info_num--blue {
  color: #003da5;
}

.pd_order_complete__info_link {
  color: #1876e9;
}

.pd_order_complete__info_caution {
  margin-top: 20px;
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__info_caution {
    text-align: left;
  }
}

.pd_order_complete__box {
  margin-top: 80px;
  padding: 50px;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box {
    margin-top: 60px;
    padding: 30px 15px;
  }
}

.pd_order_complete__box .pd_btn-horizontal {
  margin-top: 20px;
}

.pd_order_complete__box .pd_btn {
  min-width: 443px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box .pd_btn {
    min-width: auto;
    width: 100%;
  }
}

.pd_order_complete__top {
  margin-top: 50px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__top {
    margin-top: 40px;
  }
}

.pd_order_complete__box.pd_guest {
  padding: 32px 40px 52px 40px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box.pd_guest {
    padding: 30px 16px 40px;
  }
}

.pd_order_complete__box.pd_guest .pd_order_complete__heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box.pd_guest .pd_order_complete__heading_box {
    display: block;
  }
}

.pd_order_complete__box.pd_guest .pd_order_complete__heading_box .pd_icon_registration_free {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 3px;
  font-weight: bold;
  color: #d00000;
  background-color: #fff;
}

.pd_order_complete__box.pd_guest .pd_order_complete__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box.pd_guest .pd_order_complete__heading {
    margin-top: 15px;
  }
}

.pd_order_complete__box_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box_sub {
    display: block;
  }
}

.pd_order_complete__box_sub .pd_loginbox__feature {
  width: calc((100% - 40px) / 3);
  padding-left: 64px;
  text-align: left;
  background-position: center left 10px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box_sub .pd_loginbox__feature {
    width: 100%;
  }
}

.pd_order_complete__box_sub .pd_loginbox__feature:nth-child(n + 2) {
  margin-top: 0;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box_sub .pd_loginbox__feature:nth-child(n + 2) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.pd_order_complete__box .pd_btn-horizontal {
  margin-top: 40px;
}

.pd_order_complete__box .pd_btn-horizontal .pd_new_member {
  min-width: 400px;
  width: 400px;
}

@media screen and (max-width: 640px) {
  .pd_order_complete__box .pd_btn-horizontal .pd_new_member {
    min-width: auto;
    width: 100%;
  }
}

/* 07.TITLE
----------------------------------------------------------- */
/* ログイン */
/*
    File Name   : login.scss
    Description : ログイン 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_loginbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_loginbox {
    display: block;
  }
}

.pd_loginbox__left,
.pd_loginbox__right {
  width: 478px;
}

@media screen and (max-width: 640px) {

  .pd_loginbox__left,
  .pd_loginbox__right {
    width: 100%;
  }
}

.pd_loginbox__left.pd_box-col03,
.pd_loginbox__left.pd_box-col01,
.pd_loginbox__right.pd_box-col03,
.pd_loginbox__right.pd_box-col01 {
  padding: 40px;
}

@media screen and (max-width: 640px) {

  .pd_loginbox__left.pd_box-col03,
  .pd_loginbox__left.pd_box-col01,
  .pd_loginbox__right.pd_box-col03,
  .pd_loginbox__right.pd_box-col01 {
    padding: 40px 16px;
  }
}

@media screen and (max-width: 640px) {
  .pd_loginbox__right {
    margin-top: 30px;
  }
}

.pd_loginbox__h2,
.pd_loginbox__h2--addlabel {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}

.pd_loginbox__h2--addlabel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 14px;
}

.pd_loginbox__h2--addlabel span {
  display: inline-block;
}

.pd_loginbox__p,
.pd_loginbox__p--caution {
  margin-bottom: 12px;
  font-size: 1.4rem;
  letter-spacing: -0.1px;
}

.pd_loginbox__p--caution {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #d00000;
}

.pd_loginbox .pd_box-col02 {
  margin-bottom: 20px;
  padding: 10px;
}

.pd_loginbox .pd_input,
.pd_loginbox .pd_input--uid,
.pd_loginbox .pd_input--pwd {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 3px 3px 0;
  background: #fff;
  padding: 0px 13px 0px 13px;
  width: 100%;
  height: 60px;
  line-height: 1.3;
  border: none;
  outline: 0;
  font-size: 1.3rem;
}

.pd_loginbox .pd_input--uid,
.pd_loginbox .pd_input--pwd {
  border: 1px solid #ccc;
}

.pd_loginbox__label {
  margin-right: 11px;
  padding: 9px 19px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  background-color: #fff;
}

.pd_loginbox .pd_icon-play {
  display: inline-block;
  padding-left: 30px;
  background: url(/store/images/common/icon_volume_play.svg) left 50% no-repeat;
  background-size: 20px;
}

.pd_loginbox .pd_icon-pause {
  display: inline-block;
  padding-left: 20px;
  background: url(/store/images/common/icon_volume_pause.svg) left 50% no-repeat;
  background-size: 14px;
}

.pd_loginbox .pd_loginbox__tab {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_loginbox .pd_loginbox__tab {
    margin: 30px 0 0;
  }
}

.pd_loginbox .pd_loginbox__tab_li {
  width: 49.5%;
  padding: 20px 0 15px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 3px 3px 0 0;
  text-align: center;
  background-color: #d8d8d8;
}

@media screen and (max-width: 640px) {
  .pd_loginbox .pd_loginbox__tab_li {
    min-height: 45px;
  }
}

.pd_loginbox .pd_loginbox__tab_li.pd_is-active {
  background-color: #fff;
  border-bottom: none;
}

.pd_loginbox .pd_loginbox__tab_li.pd_is-active::after {
  display: none;
}

.pd_loginbox .pd_loginbox__tab_content {
  padding: 20px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  text-align: center;
}

.pd_loginbox .pd_loginbox__tab_content .pd_input-type01 {
  width: 234px;
}

@media screen and (max-width: 640px) {
  .pd_loginbox .pd_loginbox__tab_content .pd_btn-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.pd_loginbox .pd_loginbox__tab_content .pd_btn-horizontal.pd_audio .pd_btn {
  min-width: 174px;
  margin: 0 4px;
}

@media screen and (max-width: 640px) {
  .pd_loginbox .pd_loginbox__tab_content .pd_btn-horizontal.pd_audio .pd_btn {
    min-width: auto;
    padding: 8px 24px;
    font-size: 1.4rem;
  }
}

.pd_btn.pd_new_member {
  width: 100%;
  padding: 20px;
  border-radius: 40px;
}

.pd_icon-beginner {
  display: inline-block;
  padding-left: 30px;
  background: url(/store/images/common/icon_beginner.svg) left 50% no-repeat;
  background-size: 15px;
}

.pd_loginbox__feature {
  padding: 17px 17px 17px 74px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center left 16px;
}

.pd_loginbox__feature:nth-child(n + 2) {
  margin-top: 10px;
}

.pd_loginbox__feature.pd_num1 {
  background-image: url(../images/common/num01.svg);
}

.pd_loginbox__feature.pd_num2 {
  background-image: url(../images/common/num02.svg);
}

.pd_loginbox__feature.pd_num3 {
  background-image: url(../images/common/num03.svg);
}

.pd_loginbox__feature_dd {
  font-size: 1.4rem;
}

/* **.TITLE
----------------------------------------------------------- */
/* 契約 */
/*
    File Name   : contract.scss
    Description : 契約 用

    01.契約手続き
    02.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_cartflow.pd_contractflow .pd_cartflow__li,
.pd_cartflow.pd_contractflow .pd_cartflow__statusbar_li {
  width: calc(100% / 3);
}

.pd_cartflow.pd_contractflow .pd_cartflow__statusbar.pd_step2::before {
  width: calc((100% / 3) * 2);
}

.pd_cartflow.pd_contractflow .pd_cartflow__statusbar.pd_step3::before {
  width: calc((100% / 3) * 3);
}

/* 01.契約手続き
----------------------------------------------------------- */
.pd_cartbox.pd_contract_procedure .pd_new_credit {
  margin: 20px 0;
  padding: 0;
  border: 0;
}

.pd_cartbox.pd_contract_procedure .pd_new_credit .pd_creditcard_btn .pd_btn {
  margin-top: 20px;
  padding: 4px 12px;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_cartbox.pd_contract_procedure .pd_contract_procedure__credit_heading {
  font-size: 1.6rem;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__th:first-child {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__th:first-child,
.pd_cartbox.pd_contract_procedure .pd_table_layout__td:first-child {
  /* width: 62.93%; */
  width: 77%;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__td:nth-child(2),
.pd_cartbox.pd_contract_procedure .pd_table_layout__th:nth-child(2) {
  width: 37.24%;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__th:nth-child(2),
.pd_cartbox.pd_contract_procedure .pd_table_layout__td:nth-child(2) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}

.pd_table_layout__tag {
  display: inline-block;
  margin-top: 10px;
  padding: 3px 5px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 11px;
  font-weight: bold;
  color: #003da5;
  background: #fff;
}

.pd_plan_confirmation {
  margin-top: 50px;
}

.pd_plan_confirmation__box {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_plan_confirmation__dt {
  margin-bottom: 0.5em;
  font-weight: bold;
}

.pd_plan_confirmation__dd+.pd_plan_confirmation__dd {
  margin-top: 0.5em;
}

.pd_plan_confirmation__a {
  color: #1876e9;
}

.pd_plan_confirmation__a.pd_plan_confirmation__arw--left::before {
  content: "＜";
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}


.pd_custom-radio_coution {
  color: #d00000;
  margin-top: 10px;
  font-size: 14px;
}



.pd_contract__heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box .pd_modal-header__ttl {
    text-align: left;
  }
}

.pd_contract__heading_box .pd_btn {
  margin-left: 20px;
  padding: 4px 12px;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box .pd_btn {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_delivery_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pd_delivery_list {
    display: block;
  }
}

.pd_delivery_list__item {
  width: calc((100% - 20px) / 2);
  border: 1px solid #C7C7C7;
}

@media screen and (max-width: 640px) {
  .pd_delivery_list__item {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_delivery_list__item:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.pd_delivery_list__item:nth-child(n + 3) {
  margin-top: 20px;
}

.pd_delivery_list__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #C7C7C7;
  background-color: #E0E0E0;
  height: 69px;
}

.pd_delivery_list__top.pd_active {
  color: #003da5;
  background-color: #edf4ff;
  border-bottom: 1px solid #e5e5e5;
}

.pd_delivery_list__top_name {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_delivery_list__top_name .pd_honorifics {
  font-size: 1.4rem;
}

.pd_delivery_list__top_name .pd_icon_person {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 1.1rem;
  background-color: #fff;
}

.pd_delivery_list__top .pd_btn {
  min-width: 104px;
  padding: 8px;
  font-size: 1.2rem;
}

.pd_delivery_list__bottom {
  padding: 16px 20px;
}

.pd_delivery_list__bottom p+p {
  margin-top: 0.5em;
}

/* 02.TITLE
----------------------------------------------------------- */
/* プラン */
/*
    File Name   : plan.scss
    Description : プラン 用

    01.プラン
    02.プラン詳細
    03.プラン選択条件保存完了
    04.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.プラン
----------------------------------------------------------- */
.pd_plan_area {
  margin-top: 30px;
}

.pd_plan_list__item {
  padding: 24px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_plan_list__item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_plan_list__item_inr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_plan_list__item_inr {
    display: block;
  }
}

.pd_plan_list__item_img {
  min-width: 300px;
}

.pd_plan_list__item_right {
  margin-left: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_plan_list__item_right {
    margin-top: 15px;
    margin-left: 0;
  }
}

.pd_plan_list__item_heading {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: bold;
}

.pd_plan_list__item_p {
  margin-top: 15px;
  font-size: 1.6rem;
  flex-grow: 1;
}

.pd_plan_list__item .pd_btn-horizontal {
  margin-top: 15px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_plan_list__item .pd_btn-horizontal .pd_btn {
  margin: 0;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .pd_plan_list__item .pd_btn-horizontal .pd_btn {
    margin: 0 auto;
  }
}

/* 02.プラン詳細
----------------------------------------------------------- */
.pd_headbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_headbox {
    display: block;
  }
}

.pd_headbox__img {
  min-width: 226px;
}

.pd_headbox__right {
  margin-top: 8.5px;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_headbox__right {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_plan_selection__inr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_plan_selection__name {
  font-weight: bold;
}

.pd_plan_selection__right_link {
  color: #1876e9;
}

.pd_radio_panel {
  margin-top: 50px;
}

.pd_radio_panel_list__item+.pd_radio_panel_list__item {
  margin-top: 10px;
}

.pd_radio_panel_list.type2 .pd_radio_label__main_center,
.pd_radio_panel_list.type3 .pd_radio_label__main_center {
  margin-left: 0;
}

@media screen and (max-width: 640px) {

  .pd_radio_panel_list.type2 .pd_radio_label__main_center,
  .pd_radio_panel_list.type3 .pd_radio_label__main_center {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_radio_panel_list.type3 .pd_radio_label__main {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.pd_radio_panel_list.type3 .pd_radio_label__inr {
  padding: 5px 20px;
}

@media screen and (max-width: 640px) {
  .pd_radio_panel_list.type3 .pd_radio_label__main_right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.pd_radio_label {
  display: block;
  cursor: pointer;
}

.pd_radio_label input {
  display: none;
}

.pd_radio_label input:checked+.pd_radio_label__inr {
  border: 1px solid #72b1fd;
  background-color: #edf4ff;
}

.pd_radio_label__inr {
  padding: 15px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.pd_radio_label__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_radio_label__main {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.pd_radio_label__main_img {
  min-width: 89px;
  width: 89px;
}

.pd_radio_label__main_img img {
  vertical-align: middle;
}

.pd_radio_label__main_center {
  width: 335px;
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_radio_label__main_center {
    width: calc((100% - 10px) - 89px);
  }
}

.pd_radio_label__main_center_heading {
  font-weight: bold;
}

.pd_radio_label__main_center_p {
  margin-top: 5px;
}

.pd_radio_label__main_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-left: auto;
}

@media screen and (max-width: 640px) {
  .pd_radio_label__main_right {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.pd_radio_label__main_right_p {
  font-size: 1.4rem;
  color: #d00000;
}

.pd_radio_label__main_right_p .pd_yen_tax {
  font-size: 1.2rem;
}

.pd_radio_label__main_right_btn {
  display: inline-block;
  min-width: 66px;
  width: 66px;
  margin-top: 5px;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #222;
}

.pd_radio_label__main_right_btn.pd_btn-disable {
  background-color: #ccc;
}

@media screen and (max-width: 640px) {
  .pd_radio_label__main_right_btn {
    margin-top: 0;
    margin-left: 10px;
  }
}

.pd_icon_save {
  display: inline-block;
  padding-left: 20px;
  color: #1876e9;
  background: url(/store/images/common/icon_save.svg) 0 50% no-repeat;
  background-size: 12px 12px;
}

.pd_fixed_result {
  display: none;
}

@media screen and (max-width: 640px) {
  .pd_fixed_result {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}

.pd_fixed_result__left {
  text-align: right;
}

@media screen and (max-width: 640px) {
  .pd_fixed_result__left_total {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 640px) {
  .pd_fixed_result__left_total .pd_remarks_span {
    display: inline-block;
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_fixed_result__left_yen {
    font-size: 2.6rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 640px) {
  .pd_fixed_result__left_yen .pd_yen {
    font-size: 1.6rem;
    font-weight: normal;
  }
}

.pd_fixed_result__right {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_fixed_result__right .pd_btn {
    min-width: 168px;
  }
}

/* 03.プラン選択条件保存完了
----------------------------------------------------------- */
.pd_my_plan_number {
  margin-bottom: 15px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_my_plan_number {
    margin-top: 30px;
  }
}

@media screen and (max-width: 640px) {
  .pd_plan-completed__contents .pd_content__title {
    margin-top: 0;
  }
}

.pd_selection_criteria {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 640px) {
  .pd_selection_criteria {
    display: block;
  }
}

.pd_selection_criteria__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_selection_criteria__left {
    display: block;
  }
}

.pd_selection_criteria__left_main {
  margin-left: 25px;
  padding: 7px 20px;
  border-radius: 3px;
  color: #d00000;
  background-color: #feeeee;
}

@media screen and (max-width: 640px) {
  .pd_selection_criteria__left_main {
    width: 285px;
    margin-top: 10px;
    margin-left: 0;
    padding: 7px 18px;
    text-align: center;
  }
}

.pd_selection_criteria__left_sub {
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_selection_criteria__right {
    display: none;
  }
}

.pd_selection_criteria__right .pd_btn {
  min-width: 88px;
  padding: 8px;
  font-size: 1.2rem;
}

.pd_plan_table {
  margin-top: 20px;
}

.pd_plan_table__th,
.pd_plan_table__td {
  padding: 23px 20px;
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {

  .pd_plan_table__th,
  .pd_plan_table__td {
    padding: 15px;
  }
}

.pd_plan_table__th {
  width: 160px;
  background-color: #f7f7f7;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_plan_table__th {
    width: 130px;
  }
}

.pd_plan_table__td {
  width: 100%;
}

.pd_plan_table__td .pd_radio_label__main_right_btn {
  margin-top: 0;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_plan_table__td .pd_radio_label__main_right_btn {
    margin-left: 15px;
  }
}

.pd_plan_table_caution {
  margin-top: 10px;
}

.pd_plan_table_caution__a {
  color: #1876e9;
}

.pd_plan_table_caution__a.pd_plan_confirmation__arw--left::before {
  content: "＜";
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.pd_plan_completed {
  margin-top: 40px;
  padding: 20px;
  background-color: #f7f7f7;
}

.pd_plan_completed .pd_fs14 {
  font-size: 1.4rem;
}

.pd_plan_completed .pd_fs16 {
  font-size: 1.6rem;
}

.pd_plan_completed .pd_fs28 {
  font-size: 2.8rem;
}

.pd_plan_completed__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

@media screen and (max-width: 640px) {
  .pd_plan_completed__top {
    display: block;
  }
}

.pd_plan_completed__top_p {
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_plan_completed__top_p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_plan_completed__top_p+.pd_plan_completed__top_p {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_plan_completed__top_p+.pd_plan_completed__top_p {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_plan_completed__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dedede;
}

@media screen and (max-width: 640px) {
  .pd_plan_completed__bottom {
    display: block;
  }
}

.pd_plan_completed__bottom_p+.pd_plan_completed__bottom_p {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_plan_completed__bottom_p+.pd_plan_completed__bottom_p {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_planbox .pd_btn.pd_large {
  min-width: 275px;
}

/* 04.TITLE
----------------------------------------------------------- */
/* キャンペーン */
/*
    File Name   : campaign.scss
    Description : キャンペーン 用

    01.キャンペーン一覧
    02.キャンペーントップ
    03.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.キャンペーン一覧
----------------------------------------------------------- */
.pd_campaign_list__item {
  border-top: 1px solid #e5e5e5;
}

.pd_campaign_list__item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_campaign_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
  color: #000;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item {
    display: block;
  }
}

.pd_campaign_item .pd_campaign_discount {
  padding: 5px 8px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #d00000;
}

.pd_campaign_item .pd_campaign_item_count {
  margin-left: 10px;
}

.pd_campaign_item .pd_campaign_event_date {
  margin-left: 20px;
  padding: 5px 8px;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item .pd_campaign_event_date {
    margin-left: 0;
  }
}

.pd_campaign_item .pd_campaign_icon_new {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #d00000;
}

.pd_campaign_item__left {
  min-width: 280px;
}

.pd_campaign_item__right {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__right {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_campaign_item__heading {
  font-size: 1.8rem;
  font-weight: bold;
  word-break: normal;
}

.pd_campaign_item__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__option {
    display: block;
  }
}

.pd_campaign_item__option_left,
.pd_campaign_item__option_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__option_right {
    margin-top: 15px;
  }
}

.pd_campaign_item__p {
  margin-top: 30px;
  font-size: 1.6rem;
}

/* 02.キャンペーントップ
----------------------------------------------------------- */
.pd_campaign__contents.pd_has_flex .pd_content__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner .pd_category_sidebar {
    margin-top: 60px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner .pd_categorybox {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.pd_campaign_description__tag {
  margin-top: 30px;
}

.pd_campaign_description__tag_sale {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #d00000;
}

.pd_campaign_description__tag_new {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #d00000;
}

.pd_campaign_description__tag_period {
  display: inline-block;
  margin-left: 7px;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 1.4rem;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_campaign_description__tag_period {
    display: block;
    max-width: 296px;
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_campaign_description__p {
  margin-top: 20px;
  font-size: 1.6rem;
}

.pd_category_main__hr {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: #e5e5e5;
  border: none;
}

.pd_category_main__hr.pd_mt80 {
  margin-top: 80px;
}

.pd_category_main__etc {
  margin-top: 40px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__etc {
    margin: 0 -10px;
    padding-top: 40px;
    border-top: none;
  }
}

.pd_category_main__etc_heading {
  font-size: 2.4rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_category_main__etc_heading {
    padding: 0 10px;
  }
}

.pd_category_main__etc_list {
  margin-top: 30px;
  padding-bottom: 70px;
  position: relative;
}

.pd_category_main__etc_list .slick-arrow {
  width: 44px;
  height: 44px;
  margin: auto;
  border: none;
  border-radius: 50%;
  font-size: 0;
  background-color: #f7f7f7;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.pd_category_main__etc_list .slick-arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  border: 2px solid #000;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_category_main__etc_list .slick-arrow.slick-prev {
  right: 54px;
}

.pd_category_main__etc_list .slick-arrow.slick-next {
  left: 54px;
}

.pd_category_main__etc_list .slick-arrow.slick-next::before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_category_main__etc_list__item {
  padding: 0 7px;
}

.pd_category_main__etc_list__link {
  display: block;
  color: #000;
}

.pd_category_main__etc_list__link_img {
  font-size: 0;
}

.pd_category_main__etc_list__link_img img {
  width: 100%;
}

.pd_category_main__etc_list__link_bottom {
  padding: 20px;
  border: 1px solid #dbdbdb;
}

.pd_category_main__etc_list__link_bottom__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_category_main__etc_list__link_bottom__event_date {
  margin-top: 15px;
  padding: 10px;
  font-size: 1.2rem;
  background-color: #f7f7f7;
}

.pd_category_main__etc_list__link_bottom__p {
  margin-top: 15px;
  font-size: 1.6rem;
}

.pd_category_main__etc_list__link_bottom__item_count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px solid #dbdbdb;
}

.pd_category_main__etc_list__link_bottom__item_count .pd_icon_sale {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #d00000;
}

.pd_category_main__etc_list__link_bottom__item_count .pd_sum_text {
  margin-left: 10px;
}

/* 03.TITLE
----------------------------------------------------------- */
/* カテゴリ・商品 */
/*
    File Name   : category.scss
    Description : カテゴリ 用

    01.カテゴリ
    02.商品詳細（単品）
    03.商品詳細（セット品）
    04.テキストモード
    05.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.カテゴリ
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner .pd_category_sidebar {
    margin-top: 60px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner .pd_categorybox {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.pd_category_sidebar {
  width: 200px;
  float: left;
}

@media screen and (max-width: 640px) {
  .pd_category_sidebar {
    width: 100%;
    float: none
  }
}

.pd_category_sidebar__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_category_sidebar__heading {
    padding: 0 10px;
  }
}

.pd_category_sidebar_box+.pd_category_sidebar_box {
  margin-top: 50px;
}

.pd_category_sidelist {
  margin-top: 20px;
}

.pd_category_sidelist__item {
  border-top: 1px solid #e5e5e5;
}

.pd_category_sidelist__item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_category_sidelist__link {
  display: block;
  padding: 16px 32px 16px 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #222;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_category_sidelist__link {
    padding: 16px 32px 16px 10px;
  }
}

.pd_category_sidelist__link.pd_icon_plus_minus::before,
.pd_category_sidelist__link.pd_icon_plus_minus::after {
  content: "";
  display: block;
  width: 12px;
  height: 1px;
  margin: auto;
  background-color: #adadad;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
}

.pd_category_sidelist__link.pd_icon_plus_minus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pd_category_sidelist__link.pd_icon_plus_minus.pd_is-active::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.pd_category_sidelist__link.pd_icon_arw_right::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  border-top: 1px solid #adadad;
  border-right: 1px solid #adadad;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_category_sidelist.pd_category_sidebar_lv2 {
  display: none;
  margin-top: 0;
}

.pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__item {
  border-top: 1px solid #e5e5e5;
  border-bottom: none;
}

.pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__link {
  padding: 16px 32px 16px 10px;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__link {
    padding: 16px 32px 16px 20px;
  }
}

.pd_category_main {
  width: 736px;
}

@media screen and (max-width: 640px) {
  .pd_category_main {
    width: 100%;
    padding: 0 10px;
  }
}

.pd_category_main__heading {
  font-size: 2.6rem;
  font-weight: bold;
}

.pd_category_main__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head {
    display: block;
  }
}

.pd_category_main__head_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 300px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head_left {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_category_main__head_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 315px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head_right {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.pd_category_main__display_count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__display_count {
    margin-left: 0;
  }
}

.pd_category_main__display_count .pd_select-type-twoway {
  width: 86px;
}

.pd_category_main__btn .pd_btn {
  min-width: 118px;
  padding: 8px 12px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_category_main__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__option {
    margin-left: 0;
  }
}

.pd_category_main__option .pd_select-type-twoway {
  width: 96px;
}

.pd_category_main__layout {
  margin-left: 20px;
  font-size: 0;
}

.pd_category_main__layout_btn:nth-child(2) {
  margin-left: 10px;
}

.pd_category_main .pd_product_history__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 20px 0 30px 0;
  padding: 20px 0 45px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.pd_category_main .pd_product_history__li {
  width: calc((100% - 40px) / 3);
  padding: 0;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li {
    width: calc((100% - 10px) / 2);
  }
}

.pd_category_main .pd_product_history__li:nth-child(n + 4) {
  margin-top: 40px;
}

.pd_category_main .pd_product_history__li:nth-child(3n + 2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(3n + 2) {
    margin-left: 0;
  }
}

.pd_category_main .pd_product_history__li:nth-child(3n) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(3n) {
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(n + 3) {
    margin-top: 30px;
  }
}

.pd_category_main .pd_product_history__li:nth-child(2n) {
  margin-left: 10px;
}

.pd_category_main .pd_relation_item__favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 15;
}

.pd_category_main .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.pd_category_main .pd_relation_item__description {
  margin-top: 12px;
  font-weight: bold;
  word-break: normal;
}

.pd_category_main .pd_relation_item__part_number {
  margin-top: 12px;
  color: #666;
}

.pd_category_main .pd_relation_item__part_number+.pd_product_history__p--red_bold,
.pd_category_main .pd_relation_item__part_number+.pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_category_main .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd_category_main .pd_category_bnr__list {
  padding-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list {
    margin-bottom: 20px;
    padding: 18px 0;
    background-color: #000;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 74px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots {
    width: 176px;
    margin: auto;
    bottom: -18px;
    left: 0;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots button {
  width: 24px;
  height: 4px;
  padding: 0;
  border: none;
  font-size: 0;
  background-color: #ddd;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots button {
    width: 48px;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots li:nth-child(n + 2) {
  margin-left: 2px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots li:nth-child(n + 2) {
    margin-left: 16px;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots li.slick-active button {
  background-color: #1876e9;
}

@media screen and (max-width: 640px) {
  .pd_category_modal .pd_modal-header {
    padding: 25px 20px 0 20px;
  }
}


.pd_category_modal__heading_bottom {
  margin-top: 10px;
  font-size: 1.4rem;
  color: #666;
}

.pd_category_modal__heading_bottom .pd_fs20 {
  font-size: 2rem;
}

.pd_category_modal__body {
  margin-top: 20px;
  padding: 20px;
  background-color: #f7f7f7;
}

.pd_category_modal__body_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box {
    display: block;
  }
}

.pd_category_modal__body_box+.pd_category_modal__body_box {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box+.pd_category_modal__body_box {
    margin-top: 20px;
  }
}

.pd_category_modal__body_box.pd_category_modal_keyword {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_category_modal__body_box.pd_category_modal_keyword .pd_input-name {
  width: 578px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_keyword .pd_input-name {
    width: 100%;
  }
}

.pd_category_modal__body_box.pd_category_modal_keyword .pd_select-type-twoway {
  width: 178px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_keyword .pd_select-type-twoway {
    display: block;
    width: 80%;
    margin-top: 15px;
    margin-left: auto;
  }
}

.pd_category_modal__body_box.pd_category_modal_category .pd_select-type-twoway {
  width: 208px;
}

.pd_category_modal__body_box.pd_category_modal_price .pd_input-name {
  width: 155px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_price .pd_input-name {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_price .pd_category_modal__body_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.pd_category_modal__body_box.pd_category_modal_price .pd_category_modal__body_right .pd_category_tilde {
  display: inline-block;
  margin: 0 10px;
}

.pd_category_modal__body_box.pd_category_modal_sale .pd_custom-checkbox {
  margin: 0;
}

.pd_category_modal__body_heading {
  min-width: 80px;
  margin-right: 10px;
  font-size: 1.6rem;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_heading {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left;
  }
}

.pd_category_modal .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_category_filtering {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
}

@media screen and (max-width: 640px) {
  .pd_category_filtering {
    right: 10px;
    bottom: 20px;
  }
}

.pd_category_filtering__a {
  display: block;
  width: 66px;
  height: 66px;
  margin: 70px 0 10px auto;
  padding: 35px 0 10px 0;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #222;
  background-image: url(/store/images/common/icon_filtering.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center top 15px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}

/* 02.商品詳細（単品）
----------------------------------------------------------- */
.pd_fixed_cart {
  display: none;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart {
    max-height: 100px;
  }
}

.pd_fixed_cart__inr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 980px;
  margin: 0 auto;
  padding: 10px 2px;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__inr {
    display: block;
    padding: 10px;
  }
}

.pd_fixed_cart__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_fixed_cart__left_img {
  width: 74px;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__left {
    display: none;
  }
}

.pd_fixed_cart__left_heading {
  width: 250px;
  margin-left: 10px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_fixed_cart__center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 20px;
  width: 326px;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__center {
    justify-content: flex-start;
    padding-right: 0;
    width: 100%;
  }
}

.pd_fixed_cart__center_bold {
  font-weight: bold;
}

.pd_fixed_cart__center_bold .pd_num {
  font-size: 2rem;
}

.pd_fixed_cart__center_bold .pd_sub {
  font-size: 1.2rem;
}

.pd_fixed_cart__center_normal {
  margin-left: 5px;
}

.pd_fixed_cart__center_normal .pd_num {
  font-size: 1.6rem;
}

.pd_fixed_cart__center_normal .pd_sub {
  font-size: 1.4rem;
}

.pd_fixed_cart__center .pd_earnedpoints {
  margin-top: 8px;
  margin-bottom: 0;
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__center .pd_earnedpoints {
    display: none;
  }
}

.pd_fixed_cart__right {
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__right {
    margin-top: 6px;
  }
}

.pd_fixed_cart__right .pd_select-type-twoway {
  margin-left: 10px;
}

.pd_fixed_cart__right .pd_btn-horizontal {
  margin-left: 15px;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__right .pd_btn-horizontal {
    margin-left: auto;
  }
}

.pd_fixed_cart__right .pd_btn-horizontal .pd_btn {
  margin: 0;
}

@media screen and (max-width: 640px) {
  .pd_fixed_cart__right .pd_btn-horizontal .pd_btn {
    padding: 8px 12px;
  }
}

.pd_max_height_auto {
  max-height: none !important;
}

.pd_categorybox__heading {
  margin-bottom: 30px;
  font-size: 2.4rem;
  font-weight: bold;
}

.pd_categorybox__table_img {
  margin-top: 20px;
}

.pd_categorybox__table_img img {
  width: 100%;
}

.pd_categorybox__close {
  width: 100%;
  padding: 20px;
  background-color: #f7f7f7;
  position: absolute;
  bottom: 0px;
  left: 0;
  cursor: pointer;
}

.pd_categorybox__close_p {
  text-align: center;
}

.pd_categorybox__close_p .pd_arw_inr {
  padding-right: 20px;
  position: relative;
}

.pd_categorybox__close_p .pd_arw_inr::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: auto;
  border-top: 1px solid #666;
  border-right: 1px solid #666;
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 5px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.pd_categorybox__close_p .pd_arw_inr.pd_is-active::after {
  top: 5px;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.pd_categorybox .pd_icon_pdf {
  color: #1876e9;
  background: url(/store/images/common/icon_file_pdf.svg) 100% 50% no-repeat;
  background-size: 12px 12px;
  padding-right: 16px;
}

.pd_categorybox .pd_icon-otherlink {
  color: #1876e9;
  background: url(/store/images/common/icon_otherlink.svg) 100% 50% no-repeat;
  background-size: 10px 10px;
  padding-right: 16px;
}

.pd_categorybox .pd_earnedpoints {
  width: 220px;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 7px 15px 5px;
  border: 1px solid #e5e5e5;
}

.pd_categorybox_slick_wrap {
  position: relative;
}

.pd_categorybox_slick_wrap .pd_icon_zoom {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 5;
}

/* .pd_categorybox_slick .pd_label, .pd_categorybox_slick .pd_label--yellow,
.pd_categorybox_slick .pd_label, .pd_categorybox_slick .pd_label--red {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 0;
} */

.pd_categorybox_slick .pd_label--yellow {
  background-color: #ffba00;
}

.pd_categorybox_slick .pd_label--red {
  background-color: #d00000;
}

.pd_categorybox_slick__ul {
  position: relative;
}

.pd_categorybox_slick .slick-arrow {
  width: 44px;
  height: 44px;
  margin: auto;
  border: none;
  border-radius: 50%;
  font-size: 0;
  background-color: #f7f7f7;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 1000;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_slick .slick-arrow {
    top: 0;
    bottom: 0;
  }
}

.pd_categorybox_slick .slick-arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  border: 2px solid #000;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_categorybox_slick .slick-arrow.slick-prev {
  left: 10px;
}

.pd_categorybox_slick .slick-arrow.slick-next {
  left: 64px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_slick .slick-arrow.slick-next {
    right: 10px;
    left: auto;
  }
}

.pd_categorybox_slick .slick-arrow.slick-next::before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_categorybox_slick_thumb {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.pd_categorybox_slick_thumb__li {
  width: calc((100% - 40px) / 5) !important;
  cursor: pointer;
}

.pd_categorybox_slick_thumb__li:nth-child(n + 2) {
  margin-left: 10px;
}

.pd_categorybox_slick_thumb__li:nth-child(n + 6) {
  margin-top: 10px;
}

.pd_categorybox_slick_thumb__li:nth-child(5n + 6) {
  margin-left: 0;
}

.pd_categorybox_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_product {
    display: block;
  }
}

.pd_categorybox_product__left {
  width: 500px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_product__left {
    width: 100%;
  }
}

.pd_categorybox_product__right {
  width: 436px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_product__right {
    width: 100%;
  }
}

.pd_categorybox_product__item_code {
  color: #666;
}

.pd_categorybox_product__brand {
  margin-top: 20px;
}

.pd_categorybox_product__heading {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  word-break: normal;
}

.pd_categorybox_product_tag_box {
  margin-top: 10px;
}

.pd_categorybox_product_tag_box__tag,
.pd_categorybox_product_tag_box__tag--black,
.pd_categorybox_product_tag_box__tag--blue {
  display: inline-block;
  min-width: 86px;
  margin-right: 10px;
  padding: 5px 7px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.pd_categorybox_product_tag_box__tag--black {
  color: #fff;
  background-color: #000;
}

.pd_categorybox_product_tag_box__tag--blue {
  color: #fff;
  background-color: #1876e9;
}

.pd_categorybox_product_tag_box__text {
  font-size: 1.2rem;
}

.pd_categorybox_product__release {
  margin-top: 20px;
}

.pd_categorybox_product__price,
.pd_categorybox_product__price--bold,
.pd_categorybox_product__price--red,
.pd_categorybox_product__price--line,
.pd_categorybox_product__price--line_bold {
  margin-top: 3px;
}

.pd_categorybox_product__price--bold {
  margin-top: 10px;
}

.pd_categorybox_product__price,
.pd_categorybox_product__price--red,
.pd_categorybox_product__price--line,
.pd_categorybox_product__price--line_bold {
  line-height: 20px;
}

.pd_categorybox_product__price--bold,
.pd_categorybox_product__price--red_bold {
  line-height: 26px;
}

.pd_categorybox_product__price--bold .pd_categorybox_product__price_amount {
  font-size: 2.4rem;
  font-weight: bold;
}

.pd_categorybox_product__price--line_bold .pd_categorybox_product__price_amount {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_categorybox_product__price--red_bold_text,
.pd_categorybox_product__price--red,
.pd_categorybox_product__price--red_bold {
  color: #d00000;
}

.pd_categorybox_product__price--red_bold_text {
  font-weight: bold;
}

.pd_categorybox_product__price--line,
.pd_categorybox_product__price--line_bold {
  text-decoration: line-through;
}

.pd_categorybox_product__price--red_bold .pd_categorybox_product__price_amount {
  font-size: 2.4rem;
  font-weight: bold;
}

.pd_categorybox_product__price--bold .pd_categorybox_product__price_yen {
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_categorybox_product__price--line .pd_categorybox_product__price_yen {
  font-size: 1.2rem;
}

.pd_categorybox_product__price--line_bold .pd_categorybox_product__price_yen {
  font-weight: bold;
}

.pd_categorybox_product__price_amount {
  font-size: 1.6rem;
}

.pd_categorybox_product__price_yen {
  font-size: 1.4rem;
}

.pd_categorybox_product__status {
  margin-top: 20px;
  font-size: 1.6rem;
}

.pd_categorybox_product_option {
  margin-top: 20px;
  background-color: #f7f7f7;
}

.pd_categorybox_product_option__top {
  padding: 20px 40px 20px 20px;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  cursor: pointer;
}

.pd_categorybox_product_option__top::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: auto;
  border-top: 1px solid #adadad;
  border-right: 1px solid #adadad;
  position: absolute;
  top: 0;
  right: 25px;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.pd_categorybox_product_option__top.pd_is-active::after {
  top: -5px;
  bottom: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.pd_categorybox_product_option__bottom {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
}

.pd_categorybox_product_option__bottom .pd_custom-checkbox {
  margin: 0;
  font-size: 1.6rem;
}

.pd_categorybox_product_option__bottom .pd_select-type-twoway {
  width: 100%;
  margin-top: 15px;
}

.pd_categorybox_product_option__bottom_p {
  margin-top: 15px;
}

.pd_categorybox_product_option__bottom .pd_input-name {
  width: 100%;
  margin-top: 10px;
}

.pd_categorybox_product_cart {
  margin-top: 20px;
}

.pd_categorybox_product_cart .pd_btn-horizontal .pd_btn {
  width: 100%;
  margin: 0;
}

.pd_categorybox_product_cart__favorite {
  margin-top: 20px;
}

.pd_categorybox_product_cart__favorite .pd_icon-favorite {
  margin-right: 10px;
  cursor: pointer;
}

.pd_categorybox_product_cart__favorite_p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_categorybox_product_cart__favorite .pd_tooltip {
  display: inline-block;
  margin-left: 15px;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  position: relative;
}

.pd_categorybox_product_cart__favorite .pd_tooltip::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: auto;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_categorybox .pd_categorybox_color_box,
.pd_categorybox .pd_categorybox_color_box--red,
.pd_categorybox .pd_categorybox_color_box--yellow,
.pd_categorybox .pd_categorybox_color_box--gray {
  margin-top: 20px;
  border: 1px solid;
}

.pd_categorybox .pd_categorybox_color_box--red {
  border-color: #f4bdbd;
  background-color: #feeeee;
}

.pd_categorybox .pd_categorybox_color_box--yellow {
  border-color: #edcda9;
  background-color: #fff8eb;
}

.pd_categorybox .pd_categorybox_color_box--gray {
  border-color: #dedede;
  background-color: #f7f7f7;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_prodetail_color_box__inr,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_prodetail_color_box__inr,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_prodetail_color_box__inr,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_prodetail_color_box__inr {
  padding: 20px;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto {
  max-height: 200px;
  padding-bottom: 60px;
  overflow-y: hidden;
  position: relative;
}

@media screen and (max-width: 640px) {

  .pd_categorybox .pd_categorybox_color_box .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
  .pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
  .pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto,
  .pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_prodetail_color_box__inr.pd_js-max_height_auto {
    max-height: 250px;
  }
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close {
  background-color: #fff8eb;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close_p .pd_arw_inr::after,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close_p .pd_arw_inr::after,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close_p .pd_arw_inr::after,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_prodetail_color_box__inr .pd_categorybox__close_p .pd_arw_inr::after {
  width: 10px;
  height: 10px;
  border-color: #adadad;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox__heading {
  font-size: 1.8rem;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_color_heading_box,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_color_heading_box,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_color_heading_box,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_color_heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_color_heading_box.pd_flex__space_between,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_color_heading_box.pd_flex__space_between,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_color_heading_box.pd_flex__space_between,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_color_heading_box.pd_flex__space_between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_color_heading_box .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_color_heading_box .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_color_heading_box .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_color_heading_box .pd_categorybox__heading {
  margin-bottom: 0;
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox_color_heading_box__right,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_color_heading_box__right,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_color_heading_box__right,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_color_heading_box__right {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {

  .pd_categorybox .pd_categorybox_color_box .pd_categorybox_color_heading_box__right,
  .pd_categorybox .pd_categorybox_color_box--red .pd_categorybox_color_heading_box__right,
  .pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox_color_heading_box__right,
  .pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox_color_heading_box__right {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_categorybox .pd_categorybox_color_box .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--red .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--yellow .pd_categorybox__heading,
.pd_categorybox .pd_categorybox_color_box--gray .pd_categorybox__heading {
  margin-bottom: 20px;
}

.pd_categorybox_prodetail {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #e5e5e5;
}

.pd_categorybox_prodetail__inr {
  max-height: none;
  padding-bottom: 100px;
  overflow-y: hidden;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail__inr {
    max-height: none;
  }
}

.pd_categorybox_prodetail_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.pd_categorybox_prodetail_heading .pd_categorybox__heading {
  margin-bottom: 0;
}

.pd_categorybox_prodetail__p {
  font-size: 1.6rem;
}

.pd_categorybox_prodetail__p+.pd_categorybox_prodetail__p {
  margin-top: 30px;
}

.pd_categorybox_prodetail_column {
  margin-top: 40px;
}

.pd_categorybox_prodetail_column__img {
  font-size: 0;
}

.pd_categorybox_prodetail_column__heading {
  margin: 28px 0 28px;
  font-size: 2rem;
  font-weight: bold;
}

.pd_categorybox_prodetail_column.pd_col2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col {
  width: calc((100% - 20px) / 2);
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col {
    width: 100%;
  }
}

.pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col:nth-child(2n) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col:nth-child(2n) {
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.pd_categorybox_prodetail_column.pd_col2 .pd_categorybox_prodetail_column__col:nth-child(n + 3) {
  margin-top: 20px;
}

.pd_categorybox_prodetail_column.pd_col4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__heading {
  margin: 16px 0;
  font-size: 1.8rem;
}

.pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col {
  width: calc((100% - 60px) / 4);
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col {
    width: calc((100% - 20px) / 2);
  }
}

.pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col:nth-child(n + 2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col:nth-child(n + 2) {
    margin-left: 0;
  }
}

.pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col:nth-child(n + 5) {
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col:nth-child(2n) {
    margin-left: 20px;
  }
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column.pd_col4 .pd_categorybox_prodetail_column__col:nth-child(n + 3) {
    margin-top: 20px;
  }
}

.pd_categorybox_prodetail_column_flex__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_categorybox_prodetail_column_flex__col+.pd_categorybox_prodetail_column_flex__col {
  margin-top: 40px;
}

.pd_categorybox_prodetail_column_flex__col.pd_reverse .pd_categorybox_prodetail_column_flex__img {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__col.pd_reverse .pd_categorybox_prodetail_column_flex__img {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.pd_categorybox_prodetail_column_flex__col.pd_reverse .pd_categorybox_prodetail_column_flex__right {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__col.pd_reverse .pd_categorybox_prodetail_column_flex__right {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

.pd_categorybox_prodetail_column_flex__img {
  font-size: 0;
}

.pd_categorybox_prodetail_column_flex__heading {
  margin-bottom: 28px;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__heading {
    margin-top: 28px;
  }
}

.pd_categorybox_prodetail_column_flex__img {
  min-width: 480px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__img {
    min-width: auto;
  }
}

.pd_categorybox_prodetail_column_flex__right {
  width: 456px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex__right {
    width: 100%;
  }
}

.pd_categorybox_prodetail_column_flex.pd_type2 .pd_categorybox_prodetail_column_flex__img {
  min-width: 280px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex.pd_type2 .pd_categorybox_prodetail_column_flex__img {
    min-width: auto;
  }
}

.pd_categorybox_prodetail_column_flex.pd_type2 .pd_categorybox_prodetail_column_flex__right {
  width: 656px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_column_flex.pd_type2 .pd_categorybox_prodetail_column_flex__right {
    width: 100%;
  }
}

.pd_categorybox_prodetail_panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 50px;
}

.pd_categorybox_prodetail_panel__img {
  font-size: 0;
}

.pd_categorybox_prodetail_panel__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_categorybox_prodetail_panel__bottom {
  padding: 24px 21px;
  background-color: #f7f7f7;
}

.pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col {
  width: calc((100% - 40px) / 3);
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col {
    width: 100%;
  }
}

.pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col:nth-child(n + 2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col:nth-child(n + 2) {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col:nth-child(n + 4) {
  margin-top: 20px;
}

.pd_categorybox_prodetail_panel.pd_col3 .pd_categorybox_prodetail_panel__col:nth-child(3n + 4) {
  margin-left: 0;
}

.pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col {
  width: calc((100% - 60px) / 4);
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col {
    width: calc((100% - 20px) / 2);
  }
}

.pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col:nth-child(n + 2) {
  margin-left: 20px;
}

.pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col:nth-child(n + 5) {
  margin-top: 20px;
}

.pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col:nth-child(4n + 5) {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col:nth-child(n + 3) {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__col:nth-child(even) {
    margin-left: 20px;
  }
}

.pd_categorybox_prodetail_panel.pd_col4 .pd_categorybox_prodetail_panel__bottom {
  padding: 20px 15px;
}

.pd_categorybox_spec {
  margin-top: 40px;
  padding: 40px 0 50px;
  border-top: 1px solid #e5e5e5;
}

.pd_categorybox_spec__inr {
  /* max-height: 430px; */
  padding-bottom: 100px;
  overflow-y: hidden;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_spec__inr {
    /* max-height: 980px; */
  }
}

.pd_categorybox_spec_caution {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  color: #666;
}

.pd_categorybox_spec_caution__span {
  width: 36px;
}

.pd_categorybox_spec_caution__p {
  width: calc(100% - 36px);
}

.pd_categorybox .pd_categorybox_tableTypeA {
  margin-top: 20px;
}

.pd_categorybox .pd_categorybox_tableTypeA__th,
.pd_categorybox .pd_categorybox_tableTypeA__td {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_categorybox .pd_categorybox_tableTypeA__th {
  width: 40%;
  background-color: #f7f7f7;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeA__th {
    width: 50%;
  }
}

.pd_categorybox .pd_categorybox_tableTypeA__td {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeA__td {
    width: 50%;
  }
}

.pd_categorybox .pd_categorybox_tableTypeA.pd_type2 .pd_categorybox_tableTypeA__tr:nth-child(n + 2) .pd_categorybox_tableTypeA__th {
  border-top: none;
}

.pd_categorybox .pd_categorybox_tableTypeA.pd_type2 .pd_categorybox_tableTypeA__th {
  width: 35%;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeA.pd_type2 .pd_categorybox_tableTypeA__th {
    display: block;
    width: 100%;
  }
}

.pd_categorybox .pd_categorybox_tableTypeA.pd_type2 .pd_categorybox_tableTypeA__td {
  width: 65%;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeA.pd_type2 .pd_categorybox_tableTypeA__td {
    display: block;
    width: 100%;
    border-top: none;
  }
}

.pd_categorybox .pd_categorybox_tableTypeB {
  margin-top: 20px;
}

.pd_categorybox .pd_categorybox_tableTypeB__td {
  padding: 20px;
  border: 1px solid #e5e5e5;
  background-color: #f7f7f7;
}

.pd_categorybox .pd_categorybox_tableTypeB__td.pd_table_column {
  width: 20%;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeB__td.pd_table_column {
    width: 25%;
  }
}

.pd_categorybox .pd_categorybox_tableTypeB__td.pd_main_text {
  width: 60%;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeB__td.pd_main_text {
    width: 50%;
  }
}

.pd_categorybox .pd_categorybox_tableTypeC {
  margin-top: 20px;
}

.pd_categorybox .pd_categorybox_tableTypeC__td {
  padding: 20px;
  border: 1px solid #e5e5e5;
  background-color: #f7f7f7;
}

.pd_categorybox .pd_categorybox_tableTypeC__td.pd_table_column {
  width: 20%;
}

.pd_categorybox .pd_categorybox_tableTypeC__td.pd_main_text {
  width: 40%;
  background-color: #fff;
}

.pd_categorybox .pd_categorybox_tableTypeD {
  margin-top: 20px;
}

.pd_categorybox .pd_categorybox_tableTypeD__tr {
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeD__tr {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeD__thead {
    display: none;
  }

  .pd_categorybox .pd_categorybox_tableTypeD__thead+.pd_categorybox_tableTypeD__tr {
    border-top: 1px solid #e5e5e5;
  }
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeD__tbody+.pd_categorybox_tableTypeD__tbody {
    margin-top: 20px;
  }

  .pd_categorybox .pd_categorybox_tableTypeD__tbody+.pd_categorybox_tableTypeD__tbody .pd_categorybox_tableTypeD__th {
    border-top: 1px solid #e5e5e5;
  }
}

.pd_categorybox .pd_categorybox_tableTypeD__tbody .pd_categorybox_tableTypeD__th {
  display: none;
}

@media screen and (max-width: 640px) {
  .pd_categorybox .pd_categorybox_tableTypeD__tbody .pd_categorybox_tableTypeD__th {
    display: block;
    border-top: none;
  }
}

.pd_categorybox .pd_categorybox_tableTypeD__tr .pd_categorybox_tableTypeD__th:nth-of-type(2),
.pd_categorybox .pd_categorybox_tableTypeD__tr .pd_categorybox_tableTypeD__td:nth-of-type(2) {
  border-left: none;
}

@media screen and (max-width: 640px) {

  .pd_categorybox .pd_categorybox_tableTypeD__tr .pd_categorybox_tableTypeD__th:nth-of-type(2),
  .pd_categorybox .pd_categorybox_tableTypeD__tr .pd_categorybox_tableTypeD__td:nth-of-type(2) {
    border-left: 1px solid #e5e5e5;
  }
}

.pd_categorybox .pd_categorybox_tableTypeD__th,
.pd_categorybox .pd_categorybox_tableTypeD__td {
  display: block;
  width: 100%;
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_categorybox .pd_categorybox_tableTypeD__th {
  background-color: #f7f7f7;
}

.pd_categorybox .pd_categorybox_tableTypeD__td {
  border-top: none;
}

.pd_categorybox_review {
  padding: 40px 0 80px;
  border-top: 1px solid #e5e5e5;
}

.pd_categorybox_review__item {
  padding: 20px 0;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
}

.pd_categorybox_review__item+.pd_categorybox_review__item {
  border-top: 0;
}

.pd_categorybox_review__heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_categorybox_review__name {
  margin-top: 12px;
  font-size: 1.4rem;
}

.pd_categorybox_review__mark {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 12px;
  font-size: 1.4rem;
}

.pd_categorybox_review__mark_time {
  margin-left: 5px;
}

.pd_categorybox_review__p {
  margin-top: 15px;
  font-size: 1.4rem;
}

.pd_categorybox_keyword {
  padding: 40px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_categorybox_keyword__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
}

.pd_categorybox_keyword__item {
  margin-top: 10px;
  margin-right: 10px;
}

.pd_categorybox_keyword__link {
  display: block;
  padding: 5px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  color: #000;
}

/* 03.商品詳細（セット品）
----------------------------------------------------------- */
.pd_category-detail-set__contents .pd_categorybox_prodetail__inr {
  max-height: none;
  padding-bottom: 0;
}

.pd_categorybox_prodetail_box {
  margin-top: 45px;
  border: 1px solid #e5e5e5;
}

.pd_categorybox_prodetail_box+.pd_categorybox_prodetail_box {
  margin-top: 0;
  border-top: none;
}

.pd_categorybox_prodetail_box_heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_categorybox_prodetail_box__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 60px 20px 20px;
  cursor: pointer;
  position: relative;
}

.pd_categorybox_prodetail_box__top::before,
.pd_categorybox_prodetail_box__top::after {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  margin: auto;
  background-color: #adadad;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
}

.pd_categorybox_prodetail_box__top::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pd_categorybox_prodetail_box__top.pd_is-active::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.pd_categorybox_prodetail_box__top .pd_categorybox_prodetail_box_heading {
  margin-left: 20px;
}

.pd_categorybox_prodetail_box__bottom {
  display: none;
  padding: 0 40px 50px;
}

@media screen and (max-width: 640px) {
  .pd_categorybox_prodetail_box__bottom {
    padding: 0 20px 50px;
  }
}

.pd_categorybox_prodetail_box__bottom_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_categorybox_prodetail_box__bottom_text {
  margin-left: 20px;
}

.pd_categorybox_prodetail_box__bottom_item_code {
  color: #666;
}

.pd_categorybox_prodetail_box__bottom_stock {
  margin-top: 5px;
  font-size: 1.6rem;
}

.pd_categorybox_prodetail_box__bottom_release_date {
  margin-top: 5px;
  font-weight: bold;
}

/* 04.テキストモード
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_relation_item__description {
    margin-top: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode [class^="pd_product_history_item__tag--"] {
    margin-right: 10px;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item_taxoption {
  margin-right: 10px;
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item_disc_txt {
  padding-top: 20px;
  color: #666666;
}

.pd_product_history__ul.pd_txtmode .pd_product_history__p--red_bold,
.pd_product_history__ul.pd_txtmode .pd_product_history__p--black_bold {
  margin-top: 0;
}

.pd_product_history__ul.pd_txtmode .pd_product_history__li {
  width: 100%;
  margin-left: 0;
}

.pd_product_history__ul.pd_txtmode .pd_product_history__li:not(:nth-of-type(1)) {
  border-top: 1px solid #e5e5e5;
  padding-top: 27px;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history__li:not(:nth-of-type(1)) {
    padding-top: 20px;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 232px calc(100% - 232px);
  grid-template-rows: auto auto auto auto;
  -ms-grid-columns: 232px calc(100% - 232px);
  -ms-grid-rows: auto auto auto auto;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item {
    grid-template-columns: 172px calc(100% - 172px);
    grid-template-rows: auto auto auto auto;
    -ms-grid-columns: 172px calc(100% - 172px);
    -ms-grid-rows: auto auto auto auto;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item__img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item__option {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  /*----*/
  padding-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item__option {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    /*----*/
    padding-left: 0;
    padding-bottom: 12px;
  }
}

.pd_product_history__ul.pd_txtmode .pd_favorite__productReview {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  /*----*/
  margin-top: 0;
  position: static;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_favorite__productReview {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    /*----*/
    text-align: left;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item_itemname {
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-column: 2 / 4;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  /*----*/
  padding-left: 20px;
  word-break: normal;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item_itemname {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    /*----*/
    padding-left: 10px;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item_misc {
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-column: 2 / 4;
  -ms-grid-row: 3;
  grid-row: 3;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item_misc {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
  }
}

.pd_product_history__ul.pd_txtmode .pd_product_history_item_disc {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-row: 4;
}

@media screen and (max-width: 640px) {
  .pd_product_history__ul.pd_txtmode .pd_product_history_item_disc {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 4;
    grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-row: 4;
  }
}

/* 05.TITLE
----------------------------------------------------------- */
/* other（追加認証、利用規約、特定商取引、、、etc） */
/*
    File Name   : other.scss
    Description : 追加認証、利用規約、特定商取引、、、etc

    01.追加認証
    02.規約同意
    03.アンケート入力
    04.アンケート完了
    05.特定商取引に基づく表記
    06.利用規約
    07.個人情報保護方針
    08.アクセスエラー
    09.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* 01.追加認証
----------------------------------------------------------- */
.pd_add_info_box {
  margin: 60px 0;
  text-align: center;
}

.pd_add_info_box__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_add_info_box_input_area {
  width: 342px;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_add_info_box_input_area {
    width: 100%;
  }
}

.pd_add_info_box_input_area .pd_input-name {
  width: 100%;
  margin-top: 20px;
}

.pd_add_info_box_input_area .pd_add_info_box__input_p {
  margin-top: 10px;
  color: #666;
}

/* 02.規約同意
----------------------------------------------------------- */
.pd_terms__contents {
  margin-top: 24px;
}

.pd_terms_logo {
  padding: 0 5px;
  margin-bottom: 50px;
}

@media screen and (max-width: 640px) {
  .pd_terms_logo {
    padding: 0;
  }
}

.pd_terms_logo__link {
  color: #000;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_terms_box_section {
  margin-top: 60px;
}

.pd_terms_box__heading {
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_terms_box__p+.pd_terms_box__p {
  margin-top: 20px;
}

.pd_terms_box__overflow {
  height: 335px;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  overflow-y: scroll;
}

.pd_terms_box__input_wrap {
  margin-top: 30px;
  text-align: center;
}

/* 03.アンケート入力
----------------------------------------------------------- */
.pd_enquete_entry_box__p .pd_num_large {
  font-size: 1.6rem;
  font-weight: bold;
  color: #d00000;
}

.pd_enquete_entry_box_input_area {
  margin-top: 40px;
}

.pd_enquete_entry_box__dl+.pd_enquete_entry_box__dl {
  margin-top: 35px;
}

.pd_enquete_entry_box__dd {
  margin-top: 15px;
}

.pd_enquete_entry_box__dd .pd_select-type-twoway {
  width: 320px;
}

@media screen and (max-width: 640px) {
  .pd_enquete_entry_box__dd .pd_select-type-twoway {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_enquete_entry_box__fieldset {
    width: 100%;
  }
}

.pd_enquete_entry_box__fieldset .pd_custom-control {
  margin-bottom: 20px;
}

/* 04.アンケート完了
----------------------------------------------------------- */
.pd_enquete_complete_box {
  margin: 60px 0;
  text-align: center;
}

.pd_enquete_complete_box [class^="pd_enquete_complete_box__p"] {
  margin-top: 30px;
}

.pd_enquete_complete_box__p,
.pd_enquete_complete_box__p--bold,
.pd_enquete_complete_box__p--gray {
  font-size: 1.6rem;
}

.pd_enquete_complete_box__p .pd_num_large,
.pd_enquete_complete_box__p--bold .pd_num_large,
.pd_enquete_complete_box__p--gray .pd_num_large {
  font-size: 2.4rem;
  font-weight: bold;
  color: #003da5;
}

.pd_enquete_complete_box__p--bold {
  font-weight: bold;
}

.pd_enquete_complete_box__p--gray {
  font-size: 1.4rem;
  color: #666;
}

/* 05.特定商取引に基づく表記
----------------------------------------------------------- */
.pd_particular_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_particular_box_section {
  margin-top: 50px;
}

.pd_particular_box__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_particular_box__p {
  font-size: 1.6rem;
}

.pd_particular_box__p+.pd_particular_box__p {
  margin-top: 20px;
}

.pd_particular_box__p.pd_ver-align img {
  display: inline;
  width: 200px;
  margin-left: 5px;
  vertical-align: middle;
}

.pd_particular_box__caution {
  margin-top: 20px;
  color: #666;
}

.pd_particular_box_tableTypeA {
  margin-top: 20px;
}

.pd_particular_box_tableTypeA__th,
.pd_particular_box_tableTypeA__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_particular_box_tableTypeA__th {
  width: 17%;
  background-color: #f7f7f7;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_particular_box_tableTypeA__th {
    width: 30%;
  }
}

.pd_particular_box_tableTypeA__td {
  width: 83%;
}

@media screen and (max-width: 640px) {
  .pd_particular_box_tableTypeA__td {
    width: 70%;
  }
}

.pd_particular_box_tableTypeA__td a {
  color: #1876e9;
}

.pd_particular_box_tableTypeB {
  margin-top: 20px;
}

.pd_particular_box_tableTypeB__th,
.pd_particular_box_tableTypeB__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_particular_box_tableTypeB__th {
  width: 34%;
  background-color: #f7f7f7;
  vertical-align: middle;
  text-align: left;
}

.pd_particular_box_tableTypeB__td {
  width: 66%;
}

/* 06.利用規約
----------------------------------------------------------- */
.pd_terms_use_section+.pd_terms_use_section {
  margin-top: 40px;
}

.pd_terms_use_section__heading {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_terms_use_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_terms_use_box__p {
  font-size: 1.6rem;
}

.pd_terms_use_box__p+.pd_terms_use_box__p {
  margin-top: 20px;
}

.pd_terms_use_box__ul {
  padding-left: 1em;
  list-style-type: decimal;
}

.pd_terms_use_box__li+.pd_terms_use_box__li {
  margin-top: 20px;
}

.pd_terms_use_box__in_ul {
  margin-top: 20px;
  padding-left: 2.5em;
  text-indent: -2.5em;
}

.pd_terms_use_box__in_li.pd_two_digits {
  text-indent: -3em;
}

.pd_terms_use_box__in_li+.pd_terms_use_box__in_li {
  margin-top: 10px;
}

/* 07.個人情報保護方針
----------------------------------------------------------- */
.pd_person_info_section+.pd_person_info_section {
  margin-top: 70px;
  padding-top: 50px;
  border-top: 1px solid #e5e5e5;
}

.pd_person_info_section.pd_border_none {
  margin-top: 60px;
  padding-top: 0;
  border-top: none;
}

.pd_person_info_section__h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_person_info_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box .pd_person_info_box_tableTypeA {
    width: calc(100% + 30px);
    margin-left: -30px;
  }
}

.pd_person_info_box__p {
  font-size: 1.6rem;
}

.pd_person_info_box__p+.pd_person_info_box__p {
  margin-top: 20px;
}

.pd_person_info_box__ul {
  margin-top: 50px;
  padding-left: 2em;
  text-indent: -2em;
}

.pd_person_info_box__li {
  font-size: 1.6rem;
}

.pd_person_info_box__li+.pd_person_info_box__li {
  margin-top: 20px;
}

.pd_person_info_box__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 50px;
}

.pd_person_info_box__info p {
  text-align: right;
}

.pd_person_info_box__info_right {
  margin-left: 30px;
}

.pd_person_info_box__dl+.pd_person_info_box__dl {
  margin-top: 20px;
}

.pd_person_info_box__dt {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box__dt.pd_indent {
    padding-left: 2em;
    text-indent: -2em;
  }
}

.pd_person_info_box__dd_wrap {
  padding-left: 30px;
}

.pd_person_info_box__dd+.pd_person_info_box__dd {
  margin-top: 10px;
}

.pd_person_info_box__in_dl {
  margin-top: 20px;
}

.pd_person_info_box__in_dt {
  margin-bottom: 10px;
  font-weight: bold;
}

.pd_person_info_box__in_dd+.pd_person_info_box__in_dd {
  margin-top: 5px;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box__in_dd.pd_indent {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}

.pd_person_info_box_tableTypeA {
  margin-top: 20px;
}

.pd_person_info_box_tableTypeA__th,
.pd_person_info_box_tableTypeA__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_person_info_box_tableTypeA__th {
  width: 17%;
  background-color: #f7f7f7;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box_tableTypeA__th {
    width: 30%;
  }
}

.pd_person_info_box_tableTypeA__td {
  width: 83%;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box_tableTypeA__td {
    width: 70%;
  }
}

.pd_person_info_box__link_list {
  margin-top: 20px;
}

.pd_person_info_box__link_item+.pd_person_info_box__link_item {
  margin-top: 20px;
}

.pd_person_info_box__link_a {
  padding-right: 16px;
  color: #1876e9;
  background: url(/store/images/common/icon_otherlink.svg) 100% 50% no-repeat;
  background-size: 10px 10px;
}

/* 08.アクセスエラー
----------------------------------------------------------- */
.pd_error_box {
  text-align: center;
}

.pd_error_box__p {
  font-size: 1.6rem;
}

.pd_error_box__p+.pd_error_box__p {
  margin-top: 20px;
}

/* 09.TITLE
----------------------------------------------------------- */
/* その他の各page */
/*
    File Name   : pageother.scss
    Description : その他の各コンテンツページ用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* **.TITLE
----------------------------------------------------------- */
/* TOP ONLY */
/*
    File Name   : top.scss
    Description : トップページ専用

    01.TOP
*/

/* 最近見た商品用 */
/*
    File Name   : product_history.scss
    Description : 最近見た商品 用

    **.TITLE
*/

/*------------------------------------------------------*/
/*
$starFull: 16px;//星1個の幅
$starHalf: $starFull / 2;//星半分（0.5）の幅

$rate0-5: $starHalf;//星1
$rate1: $starFull;//星1
$rate1-5: $starFull + $starHalf;//星1.5
$rate2: $starFull * 2;//星2
$rate2-5: ($starFull * 2) + $starHalf;//星2.5
$rate3: $starFull * 3;//星3
$rate3-5: ($starFull * 3) + $starHalf;//星3.5
$rate4: $starFull * 4;//星4
$rate4-5: ($starFull * 4) + $starHalf;//星4.5
$rate5 :$starFull * 5;//星5

.pd_rate {
  position: relative;
  display: inline-block;
  width: $starFull * 5;
  height: $starFull;
  font-size: $starFull;
  &:before,&:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '★★★★★';
    display: inline-block;
    height: $starFull;
    line-height: $starFull;
  }
  &:before {
  color: #ddd;
  }
  &:after {
  color: #ffa500;
  overflow: hidden;
  white-space: nowrap;//これがないと改行されて星の半分（0.5）が表現できなくなる
  }
}
.pd_rate0:after {//星0
  width: 0;
}
.pd_rate0-5:after {//星0.5
    width: $rate0-5;
}
.pd_rate1:after {//星1
  width: $rate1;
}
.pd_rate1-5:after {//星1.5
  width: $rate1-5;
}
.pd_rate2:after {//星2
  width: $rate2;
}
.pd_rate2-5:after {//星2.5
  width: $rate2-5;
}
.pd_rate3:after {//星3
  width: $rate3;
}
.pd_rate3-5:after {//星3.5
  width: $rate3-5;
}
.pd_rate4:after {//星4
  width: $rate4;
}
.pd_rate4-5:after {//星4.5
  width: $rate4-5;
}
.pd_rate5:after {//星5
  width: $rate5;
}
*/
.pd_product_history {
  margin-top: 100px;
  padding: 40px 0;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_product_history {
    margin-top: 30px;
  }
}

.pd_product_history__inr {
  max-width: 980px;
  margin: 0 auto;
}

.pd_product_history__heading {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_product_history__heading {
    padding: 0 10px;
  }
}

.pd_product_history__ul {
  padding-bottom: 70px;
  position: relative;
}

.pd_product_history__ul .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_product_history__ul .slick-arrow {
  width: 44px;
  height: 44px;
  margin: auto;
  border: none;
  border-radius: 50%;
  font-size: 0;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.pd_product_history__ul .slick-arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  border: 2px solid #000;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_product_history__ul .slick-arrow.slick-prev {
  right: 54px;
}

.pd_product_history__ul .slick-arrow.slick-next {
  left: 54px;
}

.pd_product_history__ul .slick-arrow.slick-next::before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_product_history__li {
  padding: 0 10px;
}

.pd_product_history__li.slick-slide {
  height: auto;
  float: none;
}

.pd_product_history__a {
  display: block;
  height: 100%;
  padding-bottom: 27px;
  color: #000;
  position: relative;
}

.pd_product_history__p,
.pd_product_history__p--red,
.pd_product_history__p--red_bold,
.pd_product_history__p--black,
.pd_product_history__p--black_bold,
.pd_product_history__p--gray,
.pd_product_history__p--line {
  margin-top: 5px;
  font-size: 1.2rem;
  text-align: right;
}

.pd_product_history__p--red,
.pd_product_history__p--red_bold {
  color: #d00000;
}

.pd_product_history__p--red_bold {
  margin-top: 44px;
  font-weight: bold;
}

.pd_product_history__p--red_bold .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history__p--black,
.pd_product_history__p--black_bold {
  color: #000;
}

.pd_product_history__p--black_bold {
  margin-top: 44px;
  font-weight: bold;
}

.pd_product_history__p--black_bold .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history__p--gray {
  color: #666;
}

.pd_product_history__p--gray+.pd_relation_item__description {
  margin-top: 3px;
}

.pd_product_history__p--line {
  text-decoration: line-through;
}

.pd_product_history__p--line .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history_item__img {
  margin-bottom: 10px;
  font-size: 0;
  position: relative;
}

.pd_product_history_item__img img:first-child {
  width: 100%;
}

.pd_product_history_icons {
  position: absolute;
  top: 10px;
  left: 0;
}

.pd_product_history_icons .pd_product_history__sale,
.pd_product_history_icons .pd_product_history__sale--blue,
.pd_product_history_icons .pd_product_history__sale--yellow {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #d00000;
}

.pd_product_history_icons .pd_product_history__sale--blue {
  background-color: #1876e9;
}

.pd_product_history_icons .pd_product_history__sale--yellow {
  background-color: #ffba00;
}

.pd_product_history_item__img.pd_end_item::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.pd_product_history_item__img_end_text {
  width: 100%;
  margin: auto;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 11;
}

.pd_product_history_item__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 29px;
}

.pd_product_history_item__option span {
  font-size: 1.2rem;
}

.pd_product_history_item__option+.pd_product_history__p--red_bold,
.pd_product_history_item__option+.pd_product_history__p--black_bold {
  margin-top: 5px;
}

@media screen and (max-width: 640px) {

  .pd_product_history_item__option .pd_product_history_item__tag,
  .pd_product_history_item__option .pd_product_history_item__tag--black,
  .pd_product_history_item__option .pd_product_history_item__tag--blue {
    min-width: 79px;
    margin-right: 5px;
  }
}

.pd_product_history_item__option .pd_product_history_item__tag--black {
  color: #fff;
  background-color: #000;
}

.pd_product_history_item__option .pd_product_history_item__tag--blue {
  color: #fff;
  background-color: #1876e9;
}

.pd_product_history .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd_product_history .pd_favorite__productReview .pd_icon-star img {
  display: inline-block;
}

/* 関連商品 */
/*
    File Name   : relation_item.scss
    Description : 関連商品 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_product_history.pd_relation_item {
  margin-top: 0;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
}

.pd_product_history.pd_relation_item .pd_product_history__heading {
  font-size: 2.4rem;
}

@media screen and (max-width: 640px) {
  .pd_product_history.pd_relation_item .pd_product_history__heading {
    font-size: 2rem;
  }
}

.pd_product_history.pd_relation_item .pd_relation_item__favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}

.pd_product_history.pd_relation_item .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_product_history.pd_relation_item .pd_relation_item__description {
  margin-top: 12px;
  font-weight: bold;
}

.pd_product_history.pd_relation_item .pd_relation_item__part_number {
  margin-top: 12px;
  color: #666;
}

.pd_product_history.pd_relation_item .pd_relation_item__part_number+.pd_product_history__p--red_bold,
.pd_product_history.pd_relation_item .pd_relation_item__part_number+.pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_product_history.pd_relation_item .slick-arrow {
  background-color: #f7f7f7;
}

/* こんな商品も買われています */
/*
    File Name   : also_product.scss
    Description : こんな商品も買われています 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_product_history.pd_also_product {
  margin-top: 0;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
}

.pd_product_history.pd_also_product .pd_product_history__heading {
  font-size: 2.4rem;
}

@media screen and (max-width: 640px) {
  .pd_product_history.pd_also_product .pd_product_history__heading {
    font-size: 2rem;
  }
}

.pd_product_history.pd_also_product .pd_also_product__favorite {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 5;
}

.pd_product_history.pd_also_product .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_product_history.pd_also_product .pd_also_product__description {
  margin-top: 12px;
  font-weight: bold;
}

.pd_product_history.pd_also_product .pd_also_product__part_number {
  margin-top: 12px;
  color: #666;
}

.pd_product_history.pd_also_product .pd_also_product__part_number+.pd_product_history__p--red_bold,
.pd_product_history.pd_also_product .pd_also_product__part_number+.pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_product_history.pd_also_product .slick-arrow {
  background-color: #f7f7f7;
}

.pd_product_history.pd_also_product .slick-arrow.slick-disabled {
  opacity: .4;
  pointer-events: none;
}

/* あなたにおすすめ */
/*
    File Name   : recommend_for_you.scss
    Description : あなたにおすすめ 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
.pd_product_history.pd_recommend_for_you {
  background-color: #fff;
}

.pd_product_history.pd_recommend_for_you .slick-arrow {
  background-color: #f7f7f7;
}

/* おすすめ商品 */
/*
    File Name   : recommend_product.scss
    Description : おすすめ商品 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* サジェスト用 */
/*
    File Name   : suggest.scss
    Description : サジェスト用
*/
/* PC */
@media screen and (min-width: 641px) {
  /*-------------*/
}

/* SP */
@media screen and (max-width: 640px) {
  /*-------------*/
}

/*------------------------------------------------------*/
/* jquery UI autocomplete
----------------------------------------------------------- */
.ui-widget-content.ui-autocomplete {
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background: #ffffff;
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  z-index: 999;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  margin: 0;
  cursor: pointer;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 12px 15px;
  color: #000;
  font-size: 1.2rem;
  border-bottom: 1px solid #e5e5e5;
}

/* SP */
@media screen and (max-width: 640px) {
  .ui-widget-content.ui-autocomplete {
    left: 10px !important;
    width: calc(100% - 20px) !important;
  }

  .ui-menu .ui-menu-item-wrapper {
    padding: 12px 15px;
  }

  /*-------------*/
}

/* module */
/*
    File Name   : module.scss
    Description : 共通モジュールcss
*/
/* common
----------------------------------------------- */
.pd_pos-rela {
  position: relative !important;
}

.pd_op_view {
  opacity: 1 !important;
}

.pd_visible {
  overflow: visible !important;
}

img {
  max-width: 100%;
  height: auto;
}

td img,
th img {
  max-width: inherit;
  height: inherit;
}

.pd_cl {
  clear: both;
}

.pd_none {
  display: none !important;
}

/* clearfix */
.pd_cf:after,
.pd_cf:before {
  content: "";
  display: table;
}

.pd_cf:after {
  clear: both;
}

/* font系 */
.pd_bold {
  font-weight: bold !important;
}

.pd_normal {
  font-weight: normal !important;
}

.pd_t-col01 {
  color: #d00000;
}

.pd_t-col02 {
  color: #1876e9;
}

.pd_t-col03 {
  color: #003da5;
}

.pd_t-col04 {
  color: #158900;
}

.pd_t-col05 {
  color: #666666;
}

.pd_gray {
  color: #666666 !important;
}

/* 背景色系 */
.pd_bg-col01 {
  background: #f7f7f7;
}

/* PC
----------------------------------------------- */
@media screen and (min-width: 641px) {
  .pd_align-r {
    text-align: right !important;
  }

  .pd_align-c {
    text-align: center !important;
  }

  .pd_align-l {
    text-align: left !important;
  }

  /* view */
  .pd_sp-view {
    display: none;
  }

  /* margin-top */
  .pd_mt10_minus {
    margin-top: -10px !important;
  }

  .pd_mt5_minus {
    margin-top: -5px !important;
  }

  .pd_mt0 {
    margin-top: 0 !important;
  }

  .pd_mt5 {
    margin-top: 5px !important;
  }

  .pd_mt10 {
    margin-top: 10px !important;
  }

  .pd_mt15 {
    margin-top: 15px !important;
  }

  .pd_mt20 {
    margin-top: 20px !important;
  }

  .pd_mt25 {
    margin-top: 25px !important;
  }

  .pd_mt30 {
    margin-top: 30px !important;
  }

  .pd_mt35 {
    margin-top: 35px !important;
  }

  .pd_mt40 {
    margin-top: 40px !important;
  }

  .pd_mt45 {
    margin-top: 45px !important;
  }

  .pd_mt50 {
    margin-top: 50px !important;
  }

  /* margin-bottom */
  .pd_mb0 {
    margin-bottom: 0 !important;
  }

  .pd_mb5 {
    margin-bottom: 5px !important;
  }

  .pd_mb10 {
    margin-bottom: 10px !important;
  }

  .pd_mb15 {
    margin-bottom: 15px !important;
  }

  .pd_mb20 {
    margin-bottom: 20px !important;
  }

  .pd_mb25 {
    margin-bottom: 25px !important;
  }

  .pd_mb30 {
    margin-bottom: 30px !important;
  }

  .pd_mb35 {
    margin-bottom: 35px !important;
  }

  .pd_mb40 {
    margin-bottom: 40px !important;
  }

  .pd_mb50 {
    margin-bottom: 50px !important;
  }

  .pd_mb60 {
    margin-bottom: 60px !important;
  }

  .pd_mb70 {
    margin-bottom: 70px !important;
  }

  .pd_mb80 {
    margin-bottom: 80px !important;
  }

  .pd_mb100 {
    margin-bottom: 100px !important;
  }

  /* margin-right */
  .pd_mr0 {
    margin-right: 0 !important;
  }

  .pd_mr5 {
    margin-right: 5px !important;
  }

  .pd_mr10 {
    margin-right: 10px !important;
  }

  .pd_mr15 {
    margin-right: 15px !important;
  }

  .pd_mr20 {
    margin-right: 20px !important;
  }

  .pd_mr30 {
    margin-right: 30px !important;
  }

  .pd_mr40 {
    margin-right: 40px !important;
  }

  .pd_mr50 {
    margin-right: 50px !important;
  }

  /* margin-left */
  .pd_ml0 {
    margin-left: 0 !important;
  }

  .pd_ml5 {
    margin-left: 5px !important;
  }

  .pd_ml10 {
    margin-left: 10px !important;
  }

  .pd_ml15 {
    margin-left: 15px !important;
  }

  .pd_ml20 {
    margin-left: 20px !important;
  }

  .pd_ml30 {
    margin-left: 30px !important;
  }

  .pd_ml40 {
    margin-left: 40px !important;
  }

  .pd_ml50 {
    margin-left: 50px !important;
  }

  /* padding-top */
  .pd_pt0 {
    padding-top: 0 !important;
  }

  .pd_pt5 {
    padding-top: 5px !important;
  }

  .pd_pt8 {
    padding-top: 8px !important;
  }

  .pd_pt18 {
    padding-top: 18px !important;
  }

  .pd_pt10 {
    padding-top: 10px !important;
  }

  .pd_pt15 {
    padding-top: 15px !important;
  }

  .pd_pt20 {
    padding-top: 20px !important;
  }

  .pd_pt25 {
    padding-top: 25px !important;
  }

  .pd_pt30 {
    padding-top: 30px !important;
  }

  .pd_pt35 {
    padding-top: 35px !important;
  }

  .pd_pt40 {
    padding-top: 40px !important;
  }

  .pd_pt45 {
    padding-top: 45px !important;
  }

  .pd_pt50 {
    padding-top: 50px !important;
  }

  .pd_pt55 {
    padding-top: 55px !important;
  }

  .pd_pt70 {
    padding-top: 70px !important;
  }

  .pd_pt75 {
    padding-top: 75px !important;
  }

  /* padding-bottom */
  .pd_pb0 {
    padding-bottom: 0 !important;
  }

  .pd_pb5 {
    padding-bottom: 5px !important;
  }

  .pd_pb10 {
    padding-bottom: 10px !important;
  }

  .pd_pb15 {
    padding-bottom: 15px !important;
  }

  .pd_pb20 {
    padding-bottom: 20px !important;
  }

  .pd_pb30 {
    padding-bottom: 30px !important;
  }

  .pd_pb40 {
    padding-bottom: 40px !important;
  }

  .pd_pb50 {
    padding-bottom: 50px !important;
  }

  /* padding-right */
  .pd_pr0 {
    padding-right: 0 !important;
  }

  .pd_pr5 {
    padding-right: 5px !important;
  }

  .pd_pr10 {
    padding-right: 10px !important;
  }

  .pd_pr15 {
    padding-right: 15px !important;
  }

  .pd_pr20 {
    padding-right: 20px !important;
  }

  .pd_pr30 {
    padding-right: 30px !important;
  }

  .pd_pr40 {
    padding-right: 40px !important;
  }

  .pd_pr50 {
    padding-right: 50px !important;
  }

  /* padding-left */
  .pd_pl0 {
    padding-left: 0 !important;
  }

  .pd_pl5 {
    padding-left: 5px !important;
  }

  .pd_pl10 {
    padding-left: 10px !important;
  }

  .pd_pl15 {
    padding-left: 15px !important;
  }

  .pd_pl20 {
    padding-left: 20px !important;
  }

  .pd_pl30 {
    padding-left: 30px !important;
  }

  .pd_pl40 {
    padding-left: 40px !important;
  }

  .pd_pl50 {
    padding-left: 50px !important;
  }

  /* width-size px */
  .pd_w100 {
    width: 100px !important;
  }

  /* width-size % */
  .pd_w100p {
    width: 100% !important;
  }

  .pd_w75p {
    width: 75% !important;
  }

  .pd_w50p {
    width: 50% !important;
  }

  .pd_w25p {
    width: 25% !important;
  }

  .pd_w10p {
    width: 10% !important;
  }

  .pd_w_auto {
    width: auto !important;
  }

  /* flex-order */
  .pd_order1_pc {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .pd_order2_pc {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .pd_order3_pc {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .pd_order4_pc {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .pd_order5_pc {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  /* show with PC */
  .pd_mediaPC {
    display: block !important;
  }

  .pd_mediaSP {
    display: none !important;
  }
}

/* SP
----------------------------------------------- */
@media screen and (max-width: 640px) {

  /* view */
  .pd_sp-view {
    display: block !important;
  }

  .pd_pc-view {
    display: none !important;
  }

  /**/
  .pd_align-r_sp {
    text-align: right !important;
  }

  .pd_align-c_sp {
    text-align: center !important;
  }

  .pd_align-l_sp {
    text-align: left !important;
  }

  /* font-size */
  .pd_fs8_sp {
    font-size: 8px !important;
  }

  .pd_fs9_sp {
    font-size: 9px !important;
  }

  .pd_fs10_sp {
    font-size: 10px !important;
  }

  .pd_fs11_sp {
    font-size: 11px !important;
  }

  .pd_fs12_sp {
    font-size: 12px !important;
  }

  .pd_fs13_sp {
    font-size: 13px !important;
  }

  .pd_fs14_sp {
    font-size: 14px !important;
  }

  .pd_fs15_sp {
    font-size: 15px !important;
  }

  .pd_fs16_sp {
    font-size: 16px !important;
  }

  .pd_fs17_sp {
    font-size: 17px !important;
  }

  .pd_fs18_sp {
    font-size: 18px !important;
  }

  .pd_fs19_sp {
    font-size: 19px !important;
  }

  .pd_fs20_sp {
    font-size: 20px !important;
  }

  .pd_fs21_sp {
    font-size: 21px !important;
  }

  .pd_fs22_sp {
    font-size: 22px !important;
  }

  .pd_fs23_sp {
    font-size: 23px !important;
  }

  .pd_fs24_sp {
    font-size: 24px !important;
  }

  /* margin-top */
  .pd_mt0_sp {
    margin-top: 0 !important;
  }

  .pd_mt5_sp {
    margin-top: 5px !important;
  }

  .pd_mt10_sp {
    margin-top: 10px !important;
  }

  .pd_mt15_sp {
    margin-top: 15px !important;
  }

  .pd_mt20_sp {
    margin-top: 20px !important;
  }

  .pd_mt25_sp {
    margin-top: 25px !important;
  }

  .pd_mt30_sp {
    margin-top: 30px !important;
  }

  .pd_mt35_sp {
    margin-top: 35px !important;
  }

  .pd_mt40_sp {
    margin-top: 40px !important;
  }

  .pd_mt45_sp {
    margin-top: 45px !important;
  }

  .pd_mt50_sp {
    margin-top: 50px !important;
  }

  /* margin-bottom */
  .pd_mb0_sp {
    margin-bottom: 0 !important;
  }

  .pd_mb5_sp {
    margin-bottom: 5px !important;
  }

  .pd_mb10_sp {
    margin-bottom: 10px !important;
  }

  .pd_mb15_sp {
    margin-bottom: 15px !important;
  }

  .pd_mb20_sp {
    margin-bottom: 20px !important;
  }

  .pd_mb25_sp {
    margin-bottom: 25px !important;
  }

  .pd_mb30_sp {
    margin-bottom: 30px !important;
  }

  .pd_mb35_sp {
    margin-bottom: 35px !important;
  }

  .pd_mb40_sp {
    margin-bottom: 40px !important;
  }

  .pd_mb50_sp {
    margin-bottom: 50px !important;
  }

  .pd_mb60_sp {
    margin-bottom: 60px !important;
  }

  .pd_mb70_sp {
    margin-bottom: 70px !important;
  }

  .pd_mb80_sp {
    margin-bottom: 80px !important;
  }

  .pd_mb100_sp {
    margin-bottom: 100px !important;
  }

  .pd_mlr10_sp_minus {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  /* margin-right */
  .pd_mr10_sp_minus {
    margin-right: -10px !important;
  }

  .pd_mr0_sp {
    margin-right: 0 !important;
  }

  .pd_mr5_sp {
    margin-right: 5px !important;
  }

  .pd_mr10_sp {
    margin-right: 10px !important;
  }

  .pd_mr15_sp {
    margin-right: 15px !important;
  }

  .pd_mr20_sp {
    margin-right: 20px !important;
  }

  .pd_mr30_sp {
    margin-right: 30px !important;
  }

  .pd_mr40_sp {
    margin-right: 40px !important;
  }

  .pd_mr50_sp {
    margin-right: 50px !important;
  }

  /* margin-left */
  .pd_ml0_sp {
    margin-left: 0 !important;
  }

  .pd_ml5_sp {
    margin-left: 5px !important;
  }

  .pd_ml7_sp {
    margin-left: 7px !important;
  }

  .pd_ml10_sp {
    margin-left: 10px !important;
  }

  .pd_ml15_sp {
    margin-left: 15px !important;
  }

  .pd_ml20_sp {
    margin-left: 20px !important;
  }

  .pd_ml30_sp {
    margin-left: 30px !important;
  }

  .pd_ml40_sp {
    margin-left: 40px !important;
  }

  .pd_ml50_sp {
    margin-left: 50px !important;
  }

  /* padding-top */
  .pd_pt0_sp {
    padding-top: 0 !important;
  }

  .pd_pt5_sp {
    padding-top: 5px !important;
  }

  .pd_pt10_sp {
    padding-top: 10px !important;
  }

  .pd_pt15_sp {
    padding-top: 15px !important;
  }

  .pd_pt20_sp {
    padding-top: 20px !important;
  }

  .pd_pt25_sp {
    padding-top: 25px !important;
  }

  .pd_pt30_sp {
    padding-top: 30px !important;
  }

  .pd_pt35_sp {
    padding-top: 35px !important;
  }

  .pd_pt40_sp {
    padding-top: 40px !important;
  }

  .pd_pt45_sp {
    padding-top: 45px !important;
  }

  .pd_pt50_sp {
    padding-top: 50px !important;
  }

  /* padding-bottom */
  .pd_pb0_sp {
    padding-bottom: 0 !important;
  }

  .pd_pb5_sp {
    padding-bottom: 5px !important;
  }

  .pd_pb10_sp {
    padding-bottom: 10px !important;
  }

  .pd_pb15_sp {
    padding-bottom: 15px !important;
  }

  .pd_pb20_sp {
    padding-bottom: 20px !important;
  }

  .pd_pb30_sp {
    padding-bottom: 30px !important;
  }

  .pd_pb40_sp {
    padding-bottom: 40px !important;
  }

  .pd_pb50_sp {
    padding-bottom: 50px !important;
  }

  /* padding-right */
  .pd_pr0_sp {
    padding-right: 0 !important;
  }

  .pd_pr5_sp {
    padding-right: 5px !important;
  }

  .pd_pr10_sp {
    padding-right: 10px !important;
  }

  .pd_pr15_sp {
    padding-right: 15px !important;
  }

  .pd_pr20_sp {
    padding-right: 20px !important;
  }

  .pd_pr30_sp {
    padding-right: 30px !important;
  }

  .pd_pr40_sp {
    padding-right: 40px !important;
  }

  .pd_pr50_sp {
    padding-right: 50px !important;
  }

  /* padding-left */
  .pd_pl0_sp {
    padding-left: 0 !important;
  }

  .pd_pl5_sp {
    padding-left: 5px !important;
  }

  .pd_pl10_sp {
    padding-left: 10px !important;
  }

  .pd_pl15_sp {
    padding-left: 15px !important;
  }

  .pd_pl20_sp {
    padding-left: 20px !important;
  }

  .pd_pl30_sp {
    padding-left: 30px !important;
  }

  .pd_pl40_sp {
    padding-left: 40px !important;
  }

  .pd_pl50_sp {
    padding-left: 50px !important;
  }

  /* width-size % */
  .pd_w100_sp {
    width: 100px !important;
  }

  .pd_w100p_sp {
    width: 100% !important;
  }

  .pd_w75p_sp {
    width: 75% !important;
  }

  .pd_w50p_sp {
    width: 50% !important;
  }

  .pd_w25p_sp {
    width: 25% !important;
  }

  .pd_w10p_sp {
    width: 10% !important;
  }

  /* flex-order */
  .pd_order1_sp {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .pd_order2_sp {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .pd_order3_sp {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .pd_order4_sp {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .pd_order5_sp {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  /* show with SP */
  .pd_mediaPC {
    display: none !important;
  }

  .pd_mediaSP {
    display: block !important;
  }
}

.pd_cartbox_payment__heading_box {
  margin-bottom: 20px;
}

.pd_product_history_item__img {
  width: 224px;
  height: 168px;
}

.pd_product_history_item__img>img {
  width: 224px;
  height: 168px;
  -o-object-fit: contain;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

@media screen and (max-width: 640px) {
  .pd_product_history_item__img {
    width: 100%;
    height: auto;
    position: relative;
  }

  .pd_product_history_item__img::before {
    content: '';
    display: block;
    padding-top: 75%;
  }

  .pd_product_history_item__img>img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

/*# sourceMappingURL=categoryEtc.css.map */


@media screen and (min-width: 641px) {
  .pd_js-fixed-target-content {
    position: -webkit-sticky;
    position: sticky;
    top: 32px;
  }
}
